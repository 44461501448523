// @flow

import React, { PureComponent } from 'react';
import cx from 'classnames';

import { age } from '../../../utils/age';

import Row from '../../../components/layout/Row';
import Col from '../../../components/layout/Col';
import CircleImage from '../../../components/elements/CircleImage';

import { type Appointment } from '../../../types/appointment';

type Props = {
  isActive?: boolean,
  appointment: Appointment,
  onClick: () => void,
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
class AppointmentItem extends PureComponent<Props> {
  props: Props;

  handleCancel = () => {};

  render() {
    const { appointment, isActive, onClick } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div className={cx('c-box__clickable', { 'is-active': isActive })} onClick={onClick}>
        <div className="u-pad-sides-small u-pad-ends-small">
          <Row className="u-flex-align-middle">
            <Col md={12}>
              <div className="u-flex u-flex-align-middle">
                <CircleImage src={appointment.user.imageUrl} />
                <div className="u-gap-left">
                  <h5 className="u-clear-gap u-gap-bottom-xsmall u-font-weight-bold u-color-dark">
                    {appointment.user.firstName} {appointment.user.lastName}{' '}
                    {appointment.user.birthDate && `(${age(appointment.user.birthDate.toDate())})`}{' '}
                  </h5>
                  <div className="c-datetime c-datetime--primary">
                    <div className="c-datetime__time">
                      {appointment.appointmentAt.format('HH:mm')}
                    </div>
                    <div className="c-datetime__date">
                      <div>{appointment.appointmentAt.format('L')}</div>
                      <div className="u-font-size-sm">
                        {appointment.appointmentAt.format('dddd')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <hr className="u-hr-line" />
      </div>
    );
  }
}

export default AppointmentItem;
