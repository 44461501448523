// @flow

import moment from 'moment-timezone';

import { WorkerMapper } from './user';
import { ApplicationStatusDefault } from '../enums/applicationStatus';
import { type Application, type OtherApplication } from '../types/application';

import { AppointmentMapper } from './appointment';

export const OtherApplicationMapper = {
  fromAPIResponse(response: Object = {}) {
    return {
      applicationId: response.applicationId || 0,
      jobId: response.jobId || 0,
      jobTitle: response.jobTitle || '',
      companyName: response.companyName || '',
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<OtherApplication> {
    if (!response) {
      return [];
    }

    return response.map(OtherApplicationMapper.fromAPIResponse);
  },
};

export const ApplicationMapper = {
  fromAPIResponse(response: Object): Application {
    return {
      id: response.id || 0,
      user: WorkerMapper.fromNewAPIResponse(response.user),
      otherApplications: OtherApplicationMapper.fromAPIResponseMultiple(
        response.other_applications || [],
      ),
      createdAt: moment(response.createdAt),
      isShortListed: Boolean(response.shortList),
      shortListedAt: moment(response.shortListAdded),
      expiresAt: moment(response.shortListExpire),
      status: response.status || ApplicationStatusDefault,
      isSeen: !!response.isSeenByJobOwner,
      isShortListedByAnother: !!response.hasAnotherApplicationInSameRoot,
      matchScore: response.matchScore || undefined,
      appointment: response.appointment
        ? AppointmentMapper.fromAPIResponse(response.appointment)
        : undefined,
      isApplicantCameToAppointment: response.is_applicant_came_to_appointment, //TODO: appointment ile ilgili field
      isAppointmentPositive: response.is_appointment_positive, //TODO: appointment ile ilgili field
      isOfferSentToApplicant: response.is_offer_sent_to_applicant, //TODO: appointment ile ilgili field
      isApplicantAcceptTheOffer: response.is_applicant_accept_the_offer, //TODO: appointment ile ilgili field
      unreadWorkerMessageCount: response.worker_unread_messages_count, //TODO: suanlik yok
      job: response.listing
        ? { id: response.jobId, title: response.listing.title, status: response.listing.status }
        : undefined,
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<Application> {
    if (!response) {
      return [];
    }

    return response.map(ApplicationMapper.fromAPIResponse);
  },
};
