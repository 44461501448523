// @flow

import { type GeoLocation, type GeoLocationPoint } from '../types/location';

// eslint-disable-next-line import/prefer-default-export
export const stringify = (geoLocation: GeoLocation): string => {
  if (!Array.isArray(geoLocation) || !geoLocation.length) {
    return '';
  }

  return geoLocation
    .filter(
      (point: GeoLocationPoint): boolean =>
        ['administrative_area_level_1', 'administrative_area_level_2'].includes(point.type),
    )
    .sort(
      (point1: GeoLocationPoint, point2: GeoLocationPoint): number => {
        if (point1.type === 'administrative_area_level_1') {
          return -1;
        }

        if (point2.type === 'administrative_area_level_2') {
          return 1;
        }

        return 0;
      },
    )
    .map((point: GeoLocationPoint): string => point.name)
    .join(', ');
};
