// @flow

import { list as listRequest, create as createRequest, edit as editRequest, jobDataList as jobDataListRequest } from '../../api/job';
import { Status, type StatusType } from '../../utils/apiState';
import { uniqueUnion } from '../../utils/job';

import { JobMapper } from '../../mappers/job';
import { type ReduxDispatch } from '../../types/redux';
import { type Job, type JobShort } from '../../types/job';

// Actions
const REQUEST = 'jobs/REQUEST';
const SUCCESS = 'jobs/SUCCESS';
const SUCCESS_JOB_DATA_LIST = 'jobs/SUCCESS_JOB_DATA_LIST';
const FAILED = 'jobs/FAILED';

// Action Creator Types
type RequestAction = {
  type: typeof REQUEST,
  branchId?: number,
  orderBy?: string,
  orderByDir?: string,
};

type SuccessAction = {
  type: typeof SUCCESS,
  jobs: Array<Job>,
  branchId?: number,
  orderBy?: string,
  orderByDir?: string,
};

type FailedAction = {
  type: typeof FAILED,
  error: any,
};

type SuccessJobDataListAction = {
  type: typeof SUCCESS_JOB_DATA_LIST,
  jobDataList: Array<JobShort>
};

export type JobsActions = RequestAction | SuccessAction | FailedAction | SuccessJobDataListAction;

// Action Creators
export const request = (
  branchId?: number,
  orderBy?: string,
  orderByDir?: string,
): RequestAction => ({
  type: REQUEST,
  branchId,
  orderBy,
  orderByDir,
});

export const success = (
  jobs: Array<Job>,
  branchId?: number,
  orderBy?: string,
  orderByDir?: string,
): SuccessAction => ({
  type: SUCCESS,
  jobs,
  branchId,
  orderBy,
  orderByDir,
});

export const failed = (error: any): FailedAction => ({
  type: FAILED,
  error,
});

export const successJobDataList = (jobDataList: Array<JobShort>): FailedAction => ({
  type: SUCCESS_JOB_DATA_LIST,
  jobDataList,
});

// Thunks
export const loadThunk = (
  page: number,
  branchId?: number,
  orderBy?: string,
  orderByDir?: string,
): Function => async (dispatch: ReduxDispatch): Promise<*> => {
  if (page === 1) {
    dispatch(request(branchId, orderBy, orderByDir));
  }
  try {
    const { jobs, pagination } = await listRequest(page, branchId, orderBy, orderByDir);
    dispatch(success(jobs, branchId, orderBy, orderByDir));
    return pagination;
  } catch (error) {
    dispatch(failed(error));
  }
};

export const createThunk = (data: Object): Function => async (): Promise<*> => {
  const formData = JobMapper.toAPIRequest(data);
  return createRequest(formData);
};

export const editThunk = (jobId: number, data: Object): Function => async (): Promise<*> => {
  const formData = JobMapper.toAPIRequest(data);
  return editRequest(jobId, formData);
};

export const loadJobDataListThunk = (): Function => async (dispatch: ReduxDispatch): Promise<*> => {
  dispatch(request());
  try {
    const jobDataList = await jobDataListRequest();
    dispatch(successJobDataList(jobDataList));
  } catch (error) {
    dispatch(failed(error));
  }
};

// Reducer
export type JobsState = {
  +status: StatusType,
  +data: Array<Job>,
  +jobDataList: Array<JobShort>,
  +branchId: number | null,
  +error: any,
};

const initialState = {
  status: Status.INIT,
  data: [],
  jobDataList: [],
  branchId: null,
  error: null,
};

export default function reducer(state: JobsState = initialState, action: JobsActions): JobsState {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        status: Status.LOADING,
        data: [],
        branchId: action.branchId || null,
      };
    case SUCCESS:
      return {
        ...state,
        status: Status.LOADED,
        data: uniqueUnion(state.data, action.jobs, 'id'),
        branchId: action.branchId || null,
      };
    case FAILED:
      return { ...state, status: Status.FAILED, data: [], error: action.error };
    case SUCCESS_JOB_DATA_LIST:
      return {
        ...state,
        jobDataList: action.jobDataList,
        status: Status.LOADED,
      }
    default:
      return state;
  }
}
