// @flow

import moment from 'moment-timezone';

import swal from 'sweetalert';
import {
  conversationDetail as conversationDetailRequest,
  sendMessage as sendMessageRequest,
  findConversationDetailByApplicationId as findConversationDetailByApplicationIdRequest,
} from '../../api/chat';
import { Status, type StatusType } from '../../utils/apiState';
import {
  type Conversation,
  type Message,
  type LocationMessagePayload,
  type TextMessagePayload,
} from '../../types/chat';
import { ConversationMapper } from '../../mappers/chat';

import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from './index';
import { newMessageThunk, markAsReadThunk as markAsReadInboxThunk } from './inbox';
import { ErrorTypeMessageStatusMap } from '../../enums/messageStatus';

// Actions
const REQUEST = 'conversationDetail/REQUEST';
const SUCCESS = 'conversationDetail/SUCCESS';
const SENT = 'conversationDetail/SENT';
const FAILED = 'conversationDetail/FAILED';
const REMOVE = 'conversationDetail/REMOVE';

// Action Creator Types
type RequestAction = {
  type: typeof REQUEST,
  threadId: number,
};

type SuccessAction = {
  type: typeof SUCCESS,
  threadId: number,
  data: Conversation,
};

type SentAction = {
  type: typeof SENT,
  threadId: number,
  message: Message,
};

type FailedAction = {
  type: typeof FAILED,
  threadId: number,
  error: any,
};

type RemoveAction = {
  type: typeof REMOVE,
  threadId: number,
};

export type ConversationDetailActions =
  | RequestAction
  | SuccessAction
  | SentAction
  | FailedAction
  | RemoveAction;

// Action Creators
export const request = (threadId: number): RequestAction => ({
  type: REQUEST,
  threadId,
});

export const success = (data: Conversation, threadId: number): SuccessAction => ({
  type: SUCCESS,
  data,
  threadId,
});

export const sent = (message: Message, threadId: number): SentAction => ({
  type: SENT,
  message,
  threadId,
});

export const failed = (error: any, threadId: number): FailedAction => ({
  type: FAILED,
  error,
  threadId,
});

export const remove = (threadId: number): RemoveAction => ({ type: REMOVE, threadId });

// Thunks
export const loadThunk = (threadId: number, branchId: number): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  dispatch(request(threadId));

  try {
    const data = await conversationDetailRequest(threadId, branchId);

    dispatch(success(data, threadId));
    dispatch(markAsReadInboxThunk(branchId, threadId));
  } catch (error) {
    dispatch(failed(error, threadId));
  }
};

export const loadHiddenThunk = (threadId: number, branchId: number): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  try {
    const data = await conversationDetailRequest(threadId, branchId);

    dispatch(success(data, threadId));
    dispatch(markAsReadInboxThunk(branchId, threadId));
  } catch (error) {
    // ...
  }
};

export const loadByApplicationIdThunk = (workerId: number, companyId: number): Function => async (
  dispatch: ReduxDispatch,
): Promise<Conversation> => {
  const data = await findConversationDetailByApplicationIdRequest(workerId, companyId);

  dispatch(success(data, data.thread.id));
  return data;
};

export const markAsReadThunk = (threadId: number, branchId: number): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  dispatch(markAsReadInboxThunk(branchId, threadId));

  try {
    await conversationDetailRequest(threadId, branchId);
  } catch (error) {
    // ...
  }
};

export const sentThunk = (
  threadId: number,
  message: Message,
  isReaded: boolean = true,
): Function => (dispatch: ReduxDispatch) => {
  dispatch(sent(message, threadId));
  dispatch(newMessageThunk(threadId, message, isReaded));
};

export const sendMessageThunk = (
  threadId: number,
  branchId: number,
  recipientId: number,
  message: TextMessagePayload | LocationMessagePayload,
): Function => async (dispatch: ReduxDispatch, getState: () => ReduxState): Promise<*> => {
  dispatch(
    sentThunk(
      threadId,
      ({
        id: new Date().getTime(),
        sender: getState().currentUser.user,
        date: moment(),
        ...message,
      }: any),
    ),
  );

  try {
    await sendMessageRequest(branchId, recipientId, message);
  } catch (error) {
    // TODO: mark message as failed?
    if (error.code === 400) {
      swal(error.payload.error_text, {
        buttons: {
          cancel: 'Vazgeç',
          send: {
            text: 'Yine de gönder',
            value: 'send',
          },
        },
      }).then(value => {
        if (value === 'send') {
          sendMessageRequest(
            branchId,
            recipientId,
            message,
            ErrorTypeMessageStatusMap[error.payload.error_type],
          );
        }
      });
    }
  }
};

// Reducer
export type ConversationDetailItemState = {
  +status: StatusType,
  +data: Conversation,
  +error: any,
};
export type ConversationDetailState = {
  [threadId: number]: ConversationDetailItemState,
};

const emptyItem = ConversationMapper.fromAPIResponse({});
const initialState = {};

export default function reducer(
  state: ConversationDetailState = initialState,
  action: ConversationDetailActions,
): ConversationDetailState {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        [action.threadId]: {
          status: Status.LOADING,
          data: emptyItem,
          error: null,
        },
      };
    case SUCCESS:
      return {
        ...state,
        [action.threadId]: {
          status: Status.LOADED,
          data: action.data,
          error: null,
        },
      };
    case SENT: {
      const oldState = state[action.threadId] || {
        status: Status.INIT,
        data: emptyItem,
        error: null,
      };

      const newMessage = {
        ...action.message,
        body: { text: action.message.body },
      };

      return {
        ...state,
        [action.threadId]: {
          ...oldState,
          data: {
            ...oldState.data,
            messages: [...oldState.data.messages, newMessage],
          },
        },
      };
    }
    case FAILED:
      return {
        ...state,
        [action.threadId]: {
          status: Status.FAILED,
          data: emptyItem,
          error: action.error,
        },
      };
    case REMOVE:
      return {
        ...state,
        [action.threadId]: {
          status: Status.LOADED,
          data: emptyItem,
          error: null,
        },
      };
    default:
      return state;
  }
}

// Selectors
export const getDetailByThreadId = (
  state: ReduxState,
  { threadId }: { threadId: number },
): ConversationDetailItemState => {
  const itemState = state.conversationDetail[threadId];

  if (!itemState) {
    return {
      status: Status.INIT,
      data: emptyItem,
      error: null,
    };
  }

  return itemState;
};
