// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { withRouter, type Match, type RouterHistory } from 'react-router-dom';
import queryString from 'query-string';

import { withTranslation } from 'react-i18next';
import FileSaver from "file-saver"
import {
  loadThunk,
  fetchAllApplicationsThunk,
  fetchShortListedApplicationsThunk,
} from '../../redux/modules/jobDetail';
import { loadJobDataListThunk } from '../../redux/modules/jobs';
import {
  loadAllApplicationsThunk,
  loadShortListedApplicationsThunk,
  type ApplicationsState,
  loadAppointmentsThunk } from '../../redux/modules/applications';
import { downlaodApplicationsAsExcel, exportAsExcel, getApplicationListForExcelDownload } from '../../api/job';
import { isLoading, isFailed } from '../../utils/apiState';

import { swalOkButtonTranslateKey } from '../../constants';

import { type ReduxState } from '../../redux/modules';
import { type ReduxDispatch } from '../../types/redux';
import { type AuthenticatedUser } from '../../types/user';

import Header from '../../components/shared/Header';
import Alert from '../../components/elements/Alert';
import Navbar from './Navbar';
import Detail from './Detail';
import Loading from './Loading';
import ExcelModal from './ExcelModal';
import ExcelDownloadModal from './ExcelDownloadModal';

type Props = {
  match: Match,
  history: RouterHistory,
  currentUser: AuthenticatedUser,
  push: Function,
  t: Function,
  loadJobDataListThunk: () => void,
  loadAllApplicationsThunk: (page: number, jobId?: number) => void,
  loadShortListedApplicationsThunk: (page: number, jobId?: number) => void,
  loadAppointmentsThunk: (page: number, jobId?: number) => void,
  applications: ApplicationsState,
};

type State = {
  allApplicationLoading: boolean,
  shortListedApplicationsLoading: boolean,
  showExcelAlert: boolean,
  showExcelModal: boolean,
  showExcelDownloadModal: boolean,
  loadingMore: boolean,
  selectedJob: string,
  activeTab: 'all' | 'shortListed' | 'appointment',
  initialLoading: boolean,
};

class Applications extends PureComponent<Props, State> {
  props: Props;

  state: State;

  handleJobChange: string => void;

  handleTabChange: string => void;

  handleExcelClick: () => void;

  handleExcelAlertClose: () => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      allApplicationLoading: true,
      shortListedApplicationsLoading: true,
      showExcelAlert: false,
      showExcelModal: false,
      showExcelDownloadModal: false,
      loadingMore: false,
      selectedJob: "all",
      activeTab: "all",
      initialLoading: false,
    };

    this.handleJobChange = this.handleJobChange.bind(this);
    this.handleExcelClick = this.handleExcelClick.bind(this);
    this.handleExcelAlertClose = this.handleExcelAlertClose.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentWillMount() {
    this.loadApplicationsData();
  }

  loadApplicationsData() {
    this.setState({ initialLoading: true });
    this.props.loadJobDataListThunk();
    const queryParameters = queryString.parse(this.props.history.location.search);
    const page = queryParameters.page ? queryParameters.page : 1;
    const activeTab = queryParameters.activeTab ? queryParameters.activeTab : 'all';

    let loadThunk;
    switch (activeTab) {
      case 'shortListed':
        loadThunk = this.props.loadShortListedApplicationsThunk;
        break;
      case 'appointment':
        loadThunk = this.props.loadAppointmentsThunk;
        break;
      case 'all':
      default:
        loadThunk = this.props.loadAllApplicationsThunk;
        break;
    }

    if (queryParameters.jobId) {
      loadThunk(page, +queryParameters.jobId);
      this.setState({ selectedJob: queryParameters.jobId, activeTab });
    } else {
      loadThunk(page);
      this.setState({ selectedJob: 'all', activeTab });
    }

    this.setState({ initialLoading: false });
  }

  handleJobChange(jobId: string) {
    this.setState({ initialLoading: true });
    if (jobId === 'all') {
      this.props.history.push({pathname: `/applications`, search: '?page=1&activeTab=all'});
      this.props.loadAllApplicationsThunk(1);
      this.setState({ selectedJob: 'all', activeTab: 'all', initialLoading: false })
      return;
    }
    this.props.history.push({pathname: `/applications`, search: `?jobId=${jobId}&page=1&activeTab=all`});
    this.props.loadAllApplicationsThunk(1, +jobId);
    this.setState({ selectedJob: jobId, activeTab: 'all', initialLoading: false })
  }

  handleTabChange(activeTab: 'all' | 'shortListed' | 'appointment') {
    this.setState({ initialLoading: true });

    let queryParameters = queryString.parse(this.props.history.location.search);
    const jobId = queryParameters.jobId && queryParameters.jobId !== "all" ? +queryParameters.jobId : undefined;

    if (this.state.activeTab !== activeTab) {
      if (activeTab === 'all') {
        this.props.loadAllApplicationsThunk(1, jobId);
      }
      if(activeTab === 'shortListed') {
        this.props.loadShortListedApplicationsThunk(1, jobId);
      }
      if(activeTab === 'appointment') {
        this.props.loadAppointmentsThunk(1, jobId);
      }
      queryParameters.page = 1;
      queryParameters.activeTab = activeTab;
      const updatedSearch = queryString.stringify(queryParameters);
      this.props.history.push({pathname: "/applications", search: updatedSearch});

      this.setState({ activeTab, initialLoading: false });
    }
  }

  loadApplications = (page) => {
    const { allApplications, shortListedApplications } = this.props.applications.pagination;
    const { activeTab } = this.state;

    const paginationData =
      activeTab === 'all'
        ? allApplications
        : shortListedApplications;

    if (!this.state.loadingMore) {
      this.setState({
        loadingMore: true,
      });

      let queryParameters = queryString.parse(this.props.history.location.search);
      const jobId = queryParameters.jobId && queryParameters.jobId !== "all" ? +queryParameters.jobId : undefined;

      if (activeTab === 'all') {
        this.props.loadAllApplicationsThunk(page, jobId);
      } else if (activeTab === 'shortListed') {
        this.props.loadShortListedApplicationsThunk(page, jobId);
      } else if (activeTab === 'appointment') {
        this.props.loadAppointmentsThunk(page, jobId);
      }

      queryParameters.page = page;
      const updatedSearch = queryString.stringify(queryParameters);
      this.props.history.push({pathname: '/applications', search: updatedSearch});

      this.setState({
        loadingMore: false,
        [`${activeTab}ApplicationsLoading`]: false,
      });
    }
  };

  async handleExcelClick() {
    this.setState({ showExcelModal: true });
  }

  handleExcelModalSubmit = (values: Object) => {
    this.sendExcel(values.email);
  };

  sendExcel = (email: string) => {
    const jobId = this.props.jobDetail.data.job.id;

    try {
      exportAsExcel(jobId, email);
      this.setState({ showExcelAlert: true, showExcelModal: false });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert('Bir hata meydana geldi.');
    }
  };

  handleExcelModalClose = () => {
    this.setState({ showExcelModal: false });
  };

  handleExcelAlertClose() {
    this.setState({ showExcelAlert: false });
  }

  handleExcelDownloadClick = () => {
    this.setState({ showExcelDownloadModal: true });
  }

  handleExcelDownloadModalSubmit = (values: Object) => {

    const DATE = (values.date && values.date.length) ? `${values.year}-${values.month}-${values.day}` : null
    const SHORTLIST_ADDED = values.onlyShortlisted ? 1 : 0
    this.downloadExcel(SHORTLIST_ADDED, DATE);
  };

  downloadExcel = async (shortlistadded, date) => {
    const jobId = this.props.jobDetail.data.job.id;
    try {
      const { applications } = await getApplicationListForExcelDownload(shortlistadded, date, jobId)
      const { result, fileName } = await downlaodApplicationsAsExcel({ applications, language: this.props.currentUser.user.language.languageCode })

      const blob = new Blob([result], { type: "application/xlsx" })

      FileSaver.saveAs(blob, `${fileName}.xlsx`)

      this.setState({ showExcelDownloadModal: false });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert('Bir hata meydana geldi.');
    }
  };

  handleExcelDownloadModalClose = () => {
    this.setState({ showExcelDownloadModal: false });
  };


  render() {
    const { currentUser, allJobs, t, applications, match, history } = this.props;

    const { selectedJob, activeTab } = this.state;

    return (
      <div>
        <Header currentUser={currentUser} onApplicationsClick={this.loadApplicationsData} />

        <Navbar
          currentUser={currentUser}
          allJobs={allJobs}
          jobId={selectedJob}
          onJobChange={this.handleJobChange}
          onExcelClick={this.handleExcelClick}
          onExcelDownloadClick={this.handleExcelDownloadClick}
        />

        <Alert
          isVisible={this.state.showExcelAlert}
          onConfirmClick={this.handleExcelAlertClose}
          message={t('jobs.detail.sendExcelInfoText')}
          confirmLabel={t(swalOkButtonTranslateKey)}
          contentLabel={t('common.glossary.warning')}
        />

        <ExcelModal
          isOpen={this.state.showExcelModal}
          onSubmit={this.handleExcelModalSubmit}
          onClose={this.handleExcelModalClose}
        />

        <ExcelDownloadModal
          isOpen={this.state.showExcelDownloadModal}
          onSubmit={this.handleExcelDownloadModalSubmit}
          onClose={this.handleExcelDownloadModalClose}
        />

        <Detail
          applications={applications}
          match={match}
          history={history}
          loadingMore={this.state.loadingMore}
          onReachEnd={this.loadApplications}
          handleTabChange={this.handleTabChange}
          activeTab={activeTab}
          initialLoading={this.state.initialLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, { match }: { match: Object }): Object => ({
  currentUser: state.currentUser,
  allJobs: state.jobs,
  applications: state.applications
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators(
    {
      loadThunk,
      fetchAllApplicationsThunk,
      fetchShortListedApplicationsThunk,
      loadJobDataListThunk,
      loadAllApplicationsThunk,
      loadShortListedApplicationsThunk,
      loadAppointmentsThunk,
      push,
    },
    dispatch,
  );

// $FlowFixMe
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Applications)),
);
