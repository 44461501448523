// @flow

export const JobStatusMap = {
  ACTIVE: 'active',
  CLOSED: 'closed',
  DRAFT: 'draft',
  DELETED: 'deleted'
};

export const JobStatusList = [
  { key: JobStatusMap.ACTIVE, value: 'Aktif' },
  { key: JobStatusMap.CLOSED, value: 'Pasif' },
  { key: JobStatusMap.DRAFT, value: 'Taslak' },
  { key: JobStatusMap.DELETED, value: 'Silindi' },
];

export const JobStatusDefault = JobStatusMap.ACTIVE;
