// @flow

import api from '../utils/api';

import { GenderMap } from '../enums/gender';
import { ExperienceDurationMap } from '../enums/experienceDuration';
import { MilitaryStatusMap } from '../enums/militaryStatus';
import { JobStatusMap } from '../enums/jobStatus';
import { EnglishLevelMap } from '../enums/englishLevel';
import { EducationMap } from '../enums/educationStatus';
import { JobMapper, JobPositionMapper } from '../mappers/job';
import { ApplicationMapper } from '../mappers/application';
import { type Job, type JobPosition, type JobShort } from '../types/job';
import { type JobDetail } from '../types/jobDetail';
import { type AllApplications, type ShortListedApplications } from '../types/applications';
import { type Pagination } from '../types/pagination';
import { PaginationMapper } from '../mappers/pagination';
import apiNew from '../utils/apiNew';
import moment from 'moment';
import { CreateEditJobRequest, JobDto } from '../types/job';
import { CompanyLocation, CreateCompanyLocationRequest } from '../types/companyLocation';
import { Language } from '../types/language';
import { Tag } from '../types/tag';
import { v4 as uuidv4 } from 'uuid';
import { create as createApiSauce } from 'apisauce';
import { JobLocation, CreateJobLocationRequest } from '../types/job';

export type CVUser = {
  profilePictureUrl: string,
  fullname: string,
  geoLocation: string,
  email: string,
  phone: string,
  experienceMonth: number,
  gender: "male" | "female",
  localizedBirthDate: string,
  disabledStatus: string | "-",
  worktimePreferences: Array<string>,
  educationStatus: "elementarySchool" | "middleSchool" | "highSchool" | "university",
  university: {
    name: string,
    status: "graduated" | "student"
  } | null,
  about: string,
  expectedSalary: number,
  militaryStatus: "done" | "delayed" | "waiting",
  englishLevel: "zero" | "low" | "normal" | "high",
  experiences: [
    {
      duration: number,
      companyName: string,
      position: string
    }
  ]
}

export type JobData = {
  category: number,
  user: number,
  title: string,
  position: JobPosition,
  description: string,
  status: $Values<typeof JobStatusMap>,
  location: string,
  minExperienceDuration?: $Values<typeof ExperienceDurationMap>,
  expectedSalaryMin?: number,
  expectedSalaryMax?: number,
  gender?: $Values<typeof GenderMap>,
  minEducationStatus?: $Values<typeof EducationMap>,
  minEnglishLevel?: $Values<typeof EnglishLevelMap>,
  militaryRequirements: Array<$Values<typeof MilitaryStatusMap>>,
  birthDateMin?: number,
  birthDateMax?: number,
  imageFileBase64?: string,
  workTimePreferenceFullTime: boolean,
  workTimePreferencePartTime: boolean,
  fare: boolean,
  freeMeal: boolean,
  healthInsurance: boolean,
  peopleBoxProjectId?: number,
};

export const positions = async (query: string): Promise<Array<JobPosition>> => {
  const response = await apiNew.get(`/job/job-positions/datalist?q=${query}`);

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload.map(JobPositionMapper.fromAPIResponse);
};

export const list = async (
  page: number,
  branchId?: number,
  orderBy?: string,
  orderByDir?: string,
): Promise<{ jobs: Array<Job>, pagination: Pagination }> => {
  let url = '/search/job';

  if (page) {
    url = `${url}?page=${page}`;
  }

  if (branchId) {
    url = `${url}&branchId=${branchId.toString()}`;
  }

  if (orderBy) {
    url = `${url}&order_by=${orderBy}`;
  }

  if (orderByDir) {
    url = `${url}&order_by_dir=${orderByDir}`;
  }

  const response = await apiNew.get(url);

  if (!response.ok) {
    throw response.data;
  }

  return {
    jobs: JobMapper.fromAPIResponseMultiple(response.data.payload),
    pagination: PaginationMapper.fromAPIResponse(response.data),
  };
};

export const jobDataList = async (): Promise<Array<JobShort>> => {
  //Limit entered default 500
  let url = '/search/job?selection=id,title&page=1&limit=500';

  const response = await apiNew.get(url);

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};

export const create = async (data: JobData): Promise<Job> => {
  const response = await apiNew.post('/job', data);

  if (!response.ok) {
    throw response.data;
  }

  return JobMapper.fromAPIResponse(response.data.payload);
};

export const edit = async (jobId: number, data: JobData): Promise<Job> => {
  const response = await apiNew.put(`/job/${jobId}`, data);

  if (!response.ok) {
    throw response.data;
  }

  return JobMapper.fromAPIResponse(response.data.payload);
};

export const detail = async (jobId: number): Promise<JobDetail> => {
  const jobResponse = await apiNew.get(`/job/${jobId}`);
  // const applicationResponse = await apiNew.get(
  //   `/search/application/all?status=in_progress%2Caccepted%2Cdeleted%2Crejected&limit=5&page=1&jobId=${100027}`
  // );

  if (!jobResponse.ok) {
    throw jobResponse.data;
  }

  return {
    job: jobResponse.data.payload,
    // allApplications: ApplicationMapper.fromAPIResponseMultiple(
    //   response.data.payload.all_applications,
    // ),
    // rejectedApplicationCount: response.data.payload.rejected_application_count || 0,
    // shortListedApplications: ApplicationMapper.fromAPIResponseMultiple(
    //   response.data.payload.short_list_applications,
    // ),
  };
};

export const allApplications = async (page: number, jobId?: number): Promise<any> => {
  const statuses = ['in_progress', 'accepted', 'deleted', 'rejected'];
  const statusQuery = statuses.join('%2C');
  const limit = 10;

  let url = `/search/application/all?status=${statusQuery}&limit=${limit}&page=${page}`;

  if (jobId) {
    url += `&jobId=${jobId}`;
  }

  const response = await apiNew.get(url);

  if (!response.ok) {
    throw response.data;
  }

  return {
    ...response.data,
    payload: ApplicationMapper.fromAPIResponseMultiple(response.data.payload),
  }
};

export const shortListedApplications = async (
  page: number,
  jobId?: number,
): Promise<any> => {
  const statuses = ['in_progress', 'accepted', 'deleted', 'rejected'];
  const statusQuery = statuses.join('%2C');
  const limit = 10;

  let url = `/search/application/all?status=${statusQuery}&limit=${limit}&page=${page}&isShortlisted=true`;

  if (jobId) {
    url += `&jobId=${jobId}`;
  }

  const response = await apiNew.get(url);

  if (!response.ok) {
    throw response.data;
  }

  return {
    ...response.data,
    payload: ApplicationMapper.fromAPIResponseMultiple(response.data.payload),
  };
};

export const shortListToggle = async (applicationId: number, isShortlisted: boolean): Promise<boolean> => {
  const response = await apiNew.put(`/job/application/${applicationId}/shortlist`,{
    isShortlisted: !isShortlisted,
  });

  if (!response.ok) {
    throw response.data;
  }

  return true;
};

export const exportAsExcel = async (jobId: number, email: string): Promise<boolean> => {
  //TODO: bonded a gecirilecek
  const response = await api.post(`/job/detail/${jobId}/export`, {
    email,
  });

  if (!response.ok) {
    throw response.data;
  }

  return true;
};

export const getApplicationListForExcelDownload = async (shortlistAdded: 0 | 1, date: string | null, jobId: number | null) => {
  //TODO: bonded a gecirilecek
  const SHORTLIST_ADDED = shortlistAdded === 1 ? "&shortlistAdded=1" : ""
  const JOB_ID = jobId ? `&jobId=${jobId}` : ""
  const CREATED_AT = date ? `&createdAt=${date}` : ""
  const response = await api.get(`/job/company/all-applications?excel=1${SHORTLIST_ADDED}${CREATED_AT}${JOB_ID}`)

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload
}

export const downlaodApplicationsAsExcel = async (data: { applications: Array<any>, language: string }): Promise<{ result: any, fileName: string }> => {
  //TODO: getApplicationListForExcelDownload bonded'a gecirilirse bu calisir gibi
  const response = await apiNew.post(`/job/applications/export`, data, { responseType: "arraybuffer" })

  if (!response.ok) {

    throw response.data;

  }

  const fileName = `Applications_${moment().format("DD_MM_YYYY")}`

  return { result: response.data, fileName };
}

export const exportApplicationAsPdf = async (
  applicationId: number,
  email: string,
): Promise<boolean> => {
  //TODO: bonded a gecirilecek
  const response = await api.post(`/job/detail/${applicationId}/export-pdf`, {
    email,
  });

  if (!response.ok) {
    throw response.data;
  }

  return true;
};

export const downloadApplicationAsPDF = async (data: { userInformation: CVUser, languageCode: string }): Promise<{ result: any, fileName: string }> => {
  //TODO: duzgun calisiyor extra bir seye gerek yok
  const response = await apiNew.post(`/export/pdf/cv`, data, { responseType: "arraybuffer" })

  if (!response.ok) {
    throw response.data;
  }

  const fileName = `${data.userInformation.fullname}_CV_${moment().format("DD_MM_YYYY")}`

  return { result: response.data, fileName };
};

export const createJob = async (data: CreateEditJobRequest): Promise<JobDto> => {
  const response = await apiNew.post('/job', data);

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};

export const editJob = async (jobId: number, data: CreateEditJobRequest): Promise<JobDto> => {
  const response = await apiNew.put(`/job/${jobId}`, data);

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};

export const getJobListings = async (params: JobSearchParams): Promise<{ total: number, page: number, limit: number, jobs: Array<JobListing> }> => {

  const response = await apiNew.get('/search/job', { params });

  if (!response.ok) {
    throw response.data;
  }

  return {
    total: response.data.total,
    page: response.data.page,
    limit: response.data.limit,
    jobs: response.data.payload,
  };
};

export const createCompanyLocation = async (data: CreateCompanyLocationRequest): Promise<CompanyLocation> => {
  const response = await apiNew.post('/job/company-location', data);

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};

export const deleteCompanyLocation = async (id: number): Promise<void> => {
  const response = await apiNew.delete(`/job/company-location/${id}`);

  if (!response.ok) {
    throw response.data;
  }

};

export const getCompanyLocations = async (): Promise<Array<CompanyLocation>> => {
  const response = await apiNew.get('/job/company-location');

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};

export const getLanguages = async (): Promise<Array<Language>> => {
  const response = await apiNew.get('/job/language');

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};

export const getTagsByUser = async (): Promise<Array<Tag>> => {
  const response = await apiNew.get('/job/tag');

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};

export const createTag = async (name: string): Promise<Tag> => {
  const response = await apiNew.post('/job/tag', { name });

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};

const generateFileWithUuidName = (file: File): File => {
  const fileExtension = file.name.split('.').pop();
  const newFileName = `${uuidv4()}.${fileExtension}`;
  return new File([file], newFileName, {
    type: file.type,
    lastModified: file.lastModified,
  });
};

export const uploadImage = async (image: File): Promise<string> => {
  const endpoint = '/job/image';
  const imageWithUUID = generateFileWithUuidName(image);

  try {
    const result = await apiNew.post<{ uploadUrl: string }>(
      endpoint,
      { fileName: imageWithUUID.name }
    );

    if (!result.ok || !result.data || !result.data.payload || !result.data.payload.uploadUrl) {
      throw new Error('Failed to get upload URL');
    }

    const fileUploadApi = createApiSauce({
      baseURL: '',
      headers: { 'Content-Type': imageWithUUID.type },
    });

    const finalResult = await fileUploadApi.put(result.data.payload.uploadUrl, imageWithUUID);

    if (finalResult.ok) {
      return imageWithUUID.name;
    }
  } catch (error) {
    throw new Error('Could not upload image!');
  }
};

export const getJobById = async (jobId: number): Promise<Job> => {
  const response = await apiNew.get(`/job/${jobId}`);

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};

export const createJobLocation = async (
  data: CreateJobLocationRequest
): Promise<Array<JobLocation>> => {
  const response = await apiNew.post('/job/location', data);

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};
