// @flow
export default {
  jobDetail: {
    allApplications: 'ALL APPLICATIONS',
    shortList: 'SHORTLIST',
    appointment: 'INTERVIEW',
    searchPlaceholder: 'Search By Name',
    applyFilter: 'Filter',
    removeFromShortlist: 'Remove From Shortlist',
    addToShortList: 'Add To Shortlist',
    remainingTime: 'Remaining Time',
    experience: 'Work experience',
    shortListExitQuestion: 'Are you sure you want to remove this candidate from the shortlist?',
    jobClosedSubject: 'REASON FOR CLOSING',
    found_via_app: 'I found the employee from Bonded',
    found_via_other: 'I found the employee through another channel',
    not_need_anymore: 'I dont need an employee anymore',
    other: 'Other',
    address: 'Address',
    new: 'NEW',
    inShortList: 'SHORTLISTED',
    noAppointmentData: 'No shortlisted candidates.',
    rejectedApplicationCount:
      '<0>{{rejectedApplicationCount}}</0> <1>applications</1>, were deleted because they were not shortlisted.',
    applicationDate: 'Application Date',
    profile: {
      profile: 'PROFILE',
      message: 'MESSAGE',
      appointment: 'INTERVIEW',
      feedback: 'FEEDBACK',
      sendAsPDF: 'Send as PDF',
      personalInformation: 'Personal Information',
      gender: 'Gender',
      birthDate: 'Date of birth',
      unknown: 'Unknown',
      militaryStatus: 'Military Status',
      identityNumber: 'National ID number',
      disabledStatus: 'Disability',
      certificate: 'Certificates',
      drivingLicenses: 'Driver License',
      about: 'About',
      noAboutArticle: 'No information.',
      educationInformation: 'EDUCATION',
      educationStatus: 'Education status',
      university: 'University',
      englishStatus: 'English level',
      experience: 'WORK EXPERIENCE',
      noExperience: 'No work experience.',
      workPreferences: 'Work time preference',
      workCategoryPreferences: 'Category preferences',
      workTimePreferences: 'Work time preference',
      expectedSalary: 'Salary expectation',
      otherApplications: 'OTHER APPLICATIONS',
      noOtherApplications: 'No other applications to your company.',
      noApplicationFound: 'No application',
      noShortlistedWorker: 'No shortlisted candidates.',
      comments: 'COMMENTS',
      comment: {
        label: 'Comments about this candidate',
      },
      commentUpdated: 'Comment successfully edited',
      saveComment: 'Save Comment',
      shareApplicantCV: 'Share applicant CV',
      email: {
        label: 'E-mail',
        required: 'Please enter your email.',
      },
      send: 'Send',
      isSureToDeleteConversation: 'Are you sure you want to delete this conversation?',
      blockUserQuestion: 'Are you sure you want to block this user?',
      deleteConversation: 'Delete Message',
      blockUser: 'Block User',
      appointmentDateError: 'You cant form an interview for a past date.',
      isSureToRejectAppointment: 'Are you sure to cancel this interview?',
      date: {
        label: 'INTERVIEW TIME',
        required: 'Please select time of interview.',
        placeholder: 'Interview time',
      },
      time: {
        required: 'Please select time.',
        placeholder: 'Interview time',
      },
      address: {
        label: 'DETAILED ADDRESS FOR INTERVIEW',
        placeholder: 'Detailed interview',
      },
      location: {
        label: 'PLACE OF INTERVIEW',
        placeholder: 'Detailed address',
        required: 'Please select a location.',
      },
      createAppointment: 'Send an interview request',
      cancelAppointment: 'Cancel interview',
      isApplicantCameToAppointment: {
        label: 'DID THE CANDIDATE SHOW UP?',
      },
      isAppointmentPositive: {
        label: 'WAS IT A POSITIVE MEETING?',
      },
      isOfferSentToApplicant: {
        label: 'OFFER MADE?',
      },
      isApplicantAcceptTheOffer: {
        label: 'OFFER ACCEPTED?',
      },
      feedbackCommentLabel: 'COMMENTS',
      clearSelections: 'Clear selection',
      saveReview: 'Save feedback',
      appointmentShortlistErrorMessage: 'To create an appointment you have to preselect the candidate first.',
    },

    filter: {
      gender: 'GENDER',
      militaryStatus: 'MILITARY STATUS',
      notDone: 'Not done',
      delayed: 'Delayed',
      done: 'Completed',
      age: 'AGE',
      experience: 'WORK EXPERIENCE',
      educationStatus: 'EDUCATION',
      englishLevel: 'ENGLISH LEVEL',
      distance: 'DISTANCE',
      byApplicantCameToAppointment: 'BY SHOW UP RATE',
      came: 'Showed up',
      didNotCome: 'Did Not Show Up',
      accordingToInterview: 'BY INTERVIEW OUTCOME',
      positive: 'Positive',
      negative: 'Negative',
      accordingToBid: 'BY OFFER MADE',
      offerSubmitted: 'Offer made',
      noOffer: 'No offer',
      accordingToAcceptTheOffer: 'BY ACCEPTANCE',
      accepted: 'Accepted offer',
      didNotAccept: 'Did not accept offer',
      clearFilter: 'Clear filter',
      applyFilter: 'FILTER',
    },
    feedbackForm: {
      noAppointment: "There isn't any created appointment for this user!",
    },
  },
};
