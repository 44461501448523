// @flow

import api from '../utils/api';
import apiNew from '../utils/apiNew';
import { AppointmentMapper, AppointmentNewMapper } from '../mappers/appointment';
import { AppointmentStatusName } from '../enums/appointmentStatus'
import moment from 'moment';

import { type Location } from '../types/location';
import { type Appointment } from '../types/appointment';

export const getAppointmentList = async (
  jobId: string | number,
  startedDate: string,
): Promise<Array<Appointment>> => {
  const response = await apiNew.get(
    `/appointment/job/${jobId}?startFrom=${startedDate}&withFeedback=true&withStatusLog=true`,
  );

  if (!response.ok) {
    throw response.data;
  }

  return AppointmentNewMapper.fromAPIResponseMultiple(response.data.payload);
};

export const getAllAppointmentByApplicationIds = async (
  applicationIds: number[],
  startedFrom?: string,
): Promise<Array<Appointment>> => {
  const response = await apiNew.post(`/appointment/application`, {
    applicationIds,
    startedFrom,
    withFeedback: true,
    withStatusLog: true,
    statusGroup: ["waiting", "active"]
  });

  if (!response.ok) {
    throw response.data;
  }

  return AppointmentNewMapper.fromAPIResponseMultiple(response.data.payload);
};

export const getAllAppointments = async (
  page: number,
  jobId?: number,
): Promise<Array<Appointment>> => {
  const limit = 10;
  let url = `/appointment/me?limit=${limit}&page=${page}&withFeedback=true&withStatusLog=true&statusGroup=waiting%2Cactive`;

  if (jobId) {
    url += `&jobId=${jobId}`;
  }

  const response = await apiNew.get(url);

  if (!response.ok) {
    throw response.data;
  }

  return {
    ...response.data,
    payload: AppointmentNewMapper.fromAPIResponseMultiple(response.data.payload),
  }
};

export const getAppointmentById = async (id: number): Promise<Appointment> => {
  const response = await apiNew.get(`/appointment/get/${id}`);

  if (!response.ok) {
    throw response.data;
  }

  return AppointmentNewMapper.fromAPIResponse(response.data.payload);
};

export const getAllAppointmentByApplicationId = async (
  applicationId: number,
  startedFrom?: string,
): Promise<Array<Appointment>> => {
  const response = await apiNew.post(`/appointment/application/${applicationId}`, {
    startedFrom,
    withFeedback: true,
    withStatusLog: true,
    statusGroup: ["waiting", "active"]
  });

  if (!response.ok) {
    throw response.data;
  }

  return AppointmentNewMapper.fromAPIResponseMultiple(response.data.payload);
};

export const appointmentDetail = async (appointmentId: number): Promise<Appointment> => {
  const response = await api.get(`/appointments/${appointmentId}`);

  if (!response.ok) {
    throw response.data;
  }

  return AppointmentMapper.fromAPIResponse(response.data.payload.appointment);
};

export const cancelAppointment = async (appointmentId: number) => {
  const response = await apiNew.put(`/appointment/${appointmentId}/status`, {
    status: AppointmentStatusName.APPOINTMENT_CANCELLED
  });

  if (!response.ok) {
    throw response.data;
  }

  return true;
};

type CreateAppointmentData = {
  startAt: moment,
  location: Location,
  workerId: number,
  companyId: number,
  address?: string,
};

export const createAppointmentForApplication = async (
  applicationId: number,
  data: CreateAppointmentData,
): Promise<Appointment> => {
  const response = await apiNew.post(`/appointment`, {
    startAt: data.startAt,
    endAt: moment(data.startAt).add(1, "hours"),
    latitude: data.location.latitude,
    longitude: data.location.longitude,
    address: data.address,
    workerId: data.workerId,
    companyId: data.companyId,
    applicationId,
  });

  if (!response.ok) {
    throw response.data;
  }

  return AppointmentMapper.fromAPIResponse(response.data.payload);
};

export const appointmentDetailByApplication = async (
  applicationId: number,
): Promise<Appointment> => {
  const response = await api.get(`/appointments/find-by-application/${applicationId}`);

  if (!response.ok) {
    throw response.data;
  }

  return AppointmentMapper.fromAPIResponse(response.data.payload.appointment);
};

type CreateFeedbackRequest = {
  appointmentId: number,
  feedback?: string,
  text?: string,
};

export const createFeedback = async (
  data: CreateFeedbackRequest,
): Promise<any> => {
  const response = await apiNew.post(
    `/appointment/feedback`,
    data,
  );

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};

export const changeAppointmentStatus = async (
  appointmentId: number,
  status: string,
): Promise<any> => {
  const response = await apiNew.put(
    `/appointment/${appointmentId}/status`,
    { status },
  );

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};
