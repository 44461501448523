// @flow

import React, { PureComponent } from 'react';
import { Switch, Route, NavLink, type Match } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { seen as seenRequest } from '../../../api/application';

import {
  loadThunk,
  getDetailById,
  seen as applicationSeen,
  type ApplicationDetailItemState,
} from '../../../redux/modules/applicationDetail';
import {
  seen as seenAction,
} from '../../../redux/modules/jobDetail';
import { isLoading, isLoaded } from '../../../utils/apiState';
import { calculateWorkerRating } from '../../../utils/workerRating';
import { type ReduxState } from '../../../redux/modules';
import { type ReduxDispatch } from '../../../types/redux';
import { type Appointment } from '../../../types/appointment';

import Loading from '../../../components/elements/Loading';
import Box from '../../../components/elements/Box';
import Icon from '../../../components/elements/Icon';

import PersonalInformation from './PersonalInformation';
import ConversationDetail from './conversationDetail';
import AppointmentPage from './Appointment';
import Feedback from './Feedback';

import { type AuthenticatedUser } from '../../../types/user';
import { themePalette } from '../../../theme';

type Props = {
  jobId: number,
  branchId: number,
  match: Match,
  applicationDetail: ApplicationDetailItemState,
  loadThunk: (number, number) => void,
  currentUser: AuthenticatedUser,
  seenAction: (number, number) => void,
  applicationSeen: number => void,
  t: Function,
  jobMatch: Match,
};

class Profile extends PureComponent<Props> {
  props: Props;

  componentWillMount() {
    this.props.loadThunk(this.props.jobId, +this.props.match.params.applicationId);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (+nextProps.match.params.applicationId !== +this.props.match.params.applicationId) {
      this.props.loadThunk(nextProps.jobId, +nextProps.match.params.applicationId);
    }
  }

  markSeen = async () => {
    const { jobId, match, applicationDetail } = this.props;
    const applicationId = +match.params.applicationId;

    if (!isLoaded(applicationDetail)) {
      return;
    }

    if (applicationDetail.data.isSeen) {
      return;
    }

    try {
      await seenRequest(applicationId);
      this.props.applicationSeen(applicationId);
      this.props.seenAction(jobId, applicationId);
    } catch (e) {
      // No need to handle
    }
  };

  render() {
    const { applicationDetail, match, jobId, t } = this.props;

    const workerRatingScore =
      applicationDetail.data.user && applicationDetail.data.user.ratingScore
        ? calculateWorkerRating(applicationDetail.data.user.ratingScore.score)
        : 0;

    if (isLoading(applicationDetail)) {
      return <Loading />;
    }

    if (isLoaded(applicationDetail)) {
      if (!applicationDetail.data.isSeen) {
        this.markSeen();
      }
    }

    return (
      <Box>
        <ul className="c-tab-list">
          <li className="c-tab-list__item">
            <NavLink
              exact
              to={match.url}
              className="c-tab-list__link"
              activeClassName="is-active"
              style={{
                color: match.url === window.location.pathname ? themePalette.primary_color : null,
                borderColor:
                  match.url === window.location.pathname ? themePalette.primary_color : null,
              }}
            >
              <Icon name="profile" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.profile.profile')}</span>
            </NavLink>
          </li>

          <li className="c-tab-list__item">
            <NavLink
              to={`${match.url}/messages`}
              className="c-tab-list__link"
              activeClassName="is-active"
              style={{
                color:
                  `${match.url}/messages` === window.location.pathname
                    ? themePalette.primary_color
                    : null,
                borderColor:
                  `${match.url}/messages` === window.location.pathname
                    ? themePalette.primary_color
                    : null,
              }}
            >
              <Icon name="conversation" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.profile.message')}</span>
            </NavLink>
          </li>

          <li className="c-tab-list__item">
            <NavLink
              to={`${match.url}/appointment`}
              className="c-tab-list__link"
              activeClassName="is-active"
              style={{
                color:
                  `${match.url}/appointment` === window.location.pathname
                    ? themePalette.primary_color
                    : null,
                borderColor:
                  `${match.url}/appointment` === window.location.pathname
                    ? themePalette.primary_color
                    : null,
              }}
            >
              <Icon name="appointment" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.profile.appointment')}</span>
            </NavLink>
          </li>

          <li className="c-tab-list__item">
            <NavLink
              to={`${match.url}/feedback`}
              className="c-tab-list__link"
              activeClassName="is-active"
              style={{
                color:
                  `${match.url}/feedback` === window.location.pathname
                    ? themePalette.primary_color
                    : null,
                borderColor:
                  `${match.url}/feedback` === window.location.pathname
                    ? themePalette.primary_color
                    : null,
              }}
            >
              <Icon name="rating" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.profile.feedback')}</span>
            </NavLink>
          </li>
        </ul>

        <Switch>
          <Route
            exact
            path={match.url}
            render={() => (
              <PersonalInformation
                match={match}
                application={applicationDetail.data}
                otherApplications={applicationDetail.data.otherApplications}
                currentUser={this.props.currentUser}
                workerRating={workerRatingScore}
              />
            )}
          />

          <Route
            exact
            path={`${match.url}/messages`}
            render={() => (
              <ConversationDetail
                branchId={this.props.branchId}
                application={applicationDetail.data}
                jobId={this.props.jobId}
                workerRating={workerRatingScore}
              />
            )}
          />

          <Route
            exact
            path={`${match.url}/appointment`}
            render={() => (
              <AppointmentPage
                jobId={jobId}
                application={applicationDetail.data}
                currentUser={this.props.currentUser}
                onCreate={this.handleAppointmentCreate}
                onCancel={this.handleAppointmentCancel}
                workerRating={workerRatingScore}
              />
            )}
          />

          <Route
            exact
            path={`${match.url}/feedback`}
            render={() => (
              <Feedback
                jobId={jobId}
                application={applicationDetail.data}
                workerRating={workerRatingScore}
                jobMatch={this.props.jobMatch}
              />
            )}
          />
        </Switch>
      </Box>
    );
  }
}

const mapStateToProps = (state: ReduxState, { match }: { match: Match }): Object => ({
  applicationDetail: getDetailById(state, {
    applicationId: +match.params.applicationId,
  }),
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators(
    {
      loadThunk,
      seenAction,
      applicationSeen,
    },
    dispatch,
  );

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Profile));
