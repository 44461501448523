// @flow

import React, { PureComponent } from 'react';
import { Switch, Route, NavLink, type Match, type RouterHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { seen as seenRequest } from '../../../api/application';

import {
  loadThunk,
  seen as applicationSeen,
} from '../../../redux/modules/applicationDetail';
import {
  seen as seenAction,
} from '../../../redux/modules/jobDetail';
import { getDetailById, loadApplicationThunk } from '../../../redux/modules/applications';
import { isLoading, isLoaded } from '../../../utils/apiState';
import { calculateWorkerRating } from '../../../utils/workerRating';
import { type ReduxState } from '../../../redux/modules';
import { type ReduxDispatch } from '../../../types/redux';
import { Application } from '../../../types/application';
import Loading from '../../../components/elements/Loading';
import Box from '../../../components/elements/Box';
import Icon from '../../../components/elements/Icon';

import PersonalInformation from './PersonalInformation';
import ConversationDetail from './conversationDetail';
import AppointmentPage from './Appointment';
import Feedback from './Feedback';

import { type AuthenticatedUser } from '../../../types/user';
import { themePalette } from '../../../theme';

type Props = {
  match: Match,
  application: Application,
  loadThunk: (number, number) => void,
  currentUser: AuthenticatedUser,
  seenAction: (number, number) => void,
  applicationSeen: number => void,
  t: Function,
  jobMatch: Match,
  history: RouterHistory,
  loadApplicationThunk: (id: number) => void,
  activeTab: 'all' | 'shortListed' | 'appointment',
  isLoading: boolean,
  isLoaded: boolean,
};

class Profile extends PureComponent<Props> {
  props: Props;

  componentDidMount() {
    const { application, match, loadApplicationThunk, activeTab } = this.props;
    const applicationId = +match.params.applicationId;

    if (!application) {
      loadApplicationThunk(applicationId);
    }
  }


  markSeen = async () => {
    const { match, application, history } = this.props;
    const applicationId = +match.params.applicationId;

    if (!isLoaded) {
      return;
    }

    if (application.isSeen) {
      return;
    }

    try {
      await seenRequest(applicationId);
      this.props.applicationSeen(applicationId);
      this.props.seenAction(application.job.id, applicationId);
    } catch (e) {
      // No need to handle
    }
  };

  render() {
    const { application, match, t, isLoading, isLoaded } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    if (!application && isLoaded) {
      console.log("APPLICATION BULUNAMADI", application);
      return <h1>APPLICATION BULUNAMADI</h1>;
    }

    const workerRatingScore =
      application.user && application.user.ratingScore
        ? calculateWorkerRating(application.user.ratingScore.score)
        : 0;

    if (isLoaded) {
      if (!application.isSeen) {
        this.markSeen();
      }
    }

    console.log("applicationPROFILE", application);
    return (
      <Box>
        <ul className="c-tab-list">
          <li className="c-tab-list__item">
            <NavLink
              exact
              to={`${match.url}${this.props.history.location.search}`}
              className="c-tab-list__link"
              activeClassName="is-active"
              style={{
                color: match.url === window.location.pathname ? themePalette.primary_color : null,
                borderColor:
                  match.url === window.location.pathname ? themePalette.primary_color : null,
              }}
            >
              <Icon name="profile" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.profile.profile')}</span>
            </NavLink>
          </li>

          <li className="c-tab-list__item">
            <NavLink
              to={`${match.url}/messages${this.props.history.location.search}`}
              className="c-tab-list__link"
              activeClassName="is-active"
              style={{
                color:
                  `${match.url}/messages` === window.location.pathname
                    ? themePalette.primary_color
                    : null,
                borderColor:
                  `${match.url}/messages` === window.location.pathname
                    ? themePalette.primary_color
                    : null,
              }}
            >
              <Icon name="conversation" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.profile.message')}</span>
            </NavLink>
          </li>

          <li className="c-tab-list__item">
            <NavLink
              to={`${match.url}/appointment${this.props.history.location.search}`}
              className="c-tab-list__link"
              activeClassName="is-active"
              style={{
                color:
                  `${match.url}/appointment` === window.location.pathname
                    ? themePalette.primary_color
                    : null,
                borderColor:
                  `${match.url}/appointment` === window.location.pathname
                    ? themePalette.primary_color
                    : null,
              }}
            >
              <Icon name="appointment" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.profile.appointment')}</span>
            </NavLink>
          </li>

          <li className="c-tab-list__item">
            <NavLink
              to={`${match.url}/feedback${this.props.history.location.search}`}
              className="c-tab-list__link"
              activeClassName="is-active"
              style={{
                color:
                  `${match.url}/feedback` === window.location.pathname
                    ? themePalette.primary_color
                    : null,
                borderColor:
                  `${match.url}/feedback` === window.location.pathname
                    ? themePalette.primary_color
                    : null,
              }}
            >
              <Icon name="rating" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.profile.feedback')}</span>
            </NavLink>
          </li>
        </ul>

        <Switch>
          <Route
            exact
            path={match.url}
            render={() => (
              <PersonalInformation
                match={match}
                application={application}
                otherApplications={application.otherApplications}
                currentUser={this.props.currentUser}
                workerRating={workerRatingScore}
              />
            )}
          />

          <Route
            exact
            path={`${match.url}/messages`}
            render={() => (
              <ConversationDetail
                application={application}
                workerRating={workerRatingScore}
              />
            )}
          />

          <Route
            exact
            path={`${match.url}/appointment`}
            render={() => (
              <AppointmentPage
                application={application}
                currentUser={this.props.currentUser}
                onCancel={this.handleAppointmentCancel}
                workerRating={workerRatingScore}
              />
            )}
          />

          <Route
            exact
            path={`${match.url}/feedback`}
            render={() => (
              <Feedback
                jobId={application.jobId}
                application={application}
                workerRating={workerRatingScore}
                jobMatch={this.props.jobMatch}
              />
            )}
          />
        </Switch>
      </Box>
    );
  }
}

const mapStateToProps = (state: ReduxState, { match, activeTab }: { match: Match, activeTab: string }): Object => {
  const applicationId = +match.params.applicationId;
  return {
    application: getDetailById(state, { applicationId, activeTab }),
    currentUser: state.currentUser,
    isLoading: isLoading(state.applications),
    isLoaded: isLoaded(state.applications),
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators(
    {
      loadThunk,
      seenAction,
      applicationSeen,
      loadApplicationThunk,
    },
    dispatch,
  );

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Profile));
