// @flow

const salaryExpectation = (message: ?string) => (value?: number) => {
  if (value && (isNaN(value) || value <= 0)) {
    return message;
  }
  return undefined;
};

export default salaryExpectation;
