import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { type AuthenticatedUser } from '../../../../types/user';
import { type Application } from '../../../../types/application';
import { type Appointment } from '../../../../types/appointment';
import Loading from '../../../../components/elements/Loading';

import ProfileHeader from '../ProfileHeader';
import AppointmentDetail from './Detail';
import AppointmentCreate from './Create';
import { getAllAppointmentByApplicationId } from '../../../../api/appointment';

type Props = {
  application: Application,
  currentUser: AuthenticatedUser,
  workerRating: number,
  onCancel?: () => void,
};

type State = {
  appointment: ?Appointment,
  isLoading: boolean,
};

class AppointmentPage extends PureComponent<Props, State> {
  state: State = {
    appointment: undefined,
    isLoading: false,
  };

  componentDidMount() {
    this.fetchAppointmentByApplication(this.props.application.id);
  }

  fetchAppointmentByApplication = async (applicationId: number) => {
    this.setState({ isLoading: true });
    const appointments = await getAllAppointmentByApplicationId(applicationId);
    this.setState({
      appointment: Array.isArray(appointments) && appointments.length > 0 ? appointments[0] : null,
      isLoading: false,
    });
  };

  onCreate = (appointment: Appointment) => {
    this.setState({ appointment });
  };

  onCancel = () => {
    this.fetchAppointmentByApplication(this.props.application.id);
  };

  render() {
    const { application, currentUser, workerRating } = this.props;
    const { appointment, isLoading } = this.state;

    return (
      <div>
        <ProfileHeader profile={application.user} workerRating={workerRating} />

        <hr className="u-hr-line" />

        {isLoading ? (
          <Loading />
        ) : appointment ? (
          <AppointmentDetail appointment={appointment} onCancel={this.onCancel} />
        ) : (
          <AppointmentCreate
            currentUser={currentUser}
            application={application}
            onCreate={this.onCreate}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): Object => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(AppointmentPage);
