// @flow

import moment from 'moment-timezone';

import { JobStatusDefault } from '../enums/jobStatus';
import { LocationMapper, NamedLocationMapper, GeoLocationMapper } from './location';
import { CompanyMapper } from './user';
import { CategoryMapper } from './category';
import { stringify as geoLocationStringifier } from '../utils/location';
// eslint-disable-next-line import/no-cycle
import { type JobData } from '../api/job';

import { type Job, type JobPosition } from '../types/job';
import { WorkTimeList } from '../enums/workTime';
import { pushWorkTimePreferences } from '../utils/job';
import { JobDto } from '../types/job';

// eslint-disable-next-line import/prefer-default-export
export const JobMapper = {
  fromAPIResponse(response: Object): JobDto {
    const workTimePreferences = [];
    const jobLocation = Array.isArray(response.jobLocations) && response.jobLocations.length > 0 ? response.jobLocations[0] : null;
    const geoLocation = jobLocation && jobLocation.companyLocation ? GeoLocationMapper.fromAPIResponse(jobLocation.companyLocation.geoLocation) : null;

    pushWorkTimePreferences(response, workTimePreferences);

    return {
      id: response.id || 0,
      createdAt: moment(response.createdAt),
      openedAt: moment(response.openedAt),
      title: response.title || '',
      position: response.position,
      description: response.description || '',
      user: CompanyMapper.fromNewAPIResponse(response.user || {}),
      category: CategoryMapper.fromAPIResponse(response.category || {}),
      status: response.status || JobStatusDefault,
      minExperienceDuration: response.minExperienceDuration || 0,
      gender: response.gender || '',
      birthDateMin: response.birthDateMin || 0,
      birthDateMax: response.birthDateMax || 0,
      imageUrl: response.image || '',
      counts: {
        applications: response.applicationCount || 0,
        unreviewed: response.unseenApplicationCount || 0,
        shortLists: response.nbShortLists || 0,
        jobViews: response.nbViewCount || 0,
      },
      expectedSalaryMax: response.expectedSalaryMax,
      expectedSalaryMin: response.expectedSalaryMin,
      militaryRequirements: response.militaryRequirements || [],
      minEducationStatus: response.minEducationStatus,
      minEnglishLevel: response.minEnglishLevel,
      contactOption: response.contactType,
      contactPhone: response.contactTypePhoneNumber,
      workTimePreferences,
      autoShortlistThreshold: response.autoShortlistThreshold,
      //peopleBoxProjectId: response.people_box_project && response.people_box_project.project_id,
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<Job> {
    if (!response) {
      return [];
    }

    return response.map(JobMapper.fromAPIResponse);
  },

  toAPIRequest(request: Job): JobData {
    return {
      targetUser: {
        accessToken: request.userAccessToken
      },
      title: request.title,
      status: request.status,
      shifts: {
        workStartsAt: request.workStartsAt,
        dailyTotalWorkHour: request.dailyTotalWorkHour,
        weeklyOffDayCount: request.weeklyOffDayCount,
        offTime: request.offTime
      },
      tagIds: request.selectedTags,
      languageLevels: request.languageLevels,
      numberOfHire: 1,
      description: request.description,
      image: request.cover,
      birthDateMin: request.birthDateMin || undefined,
      birthDateMax: request.birthDateMax || undefined,
      categoryId: +request.category,
      minExperienceDuration: request.minExperienceDuration || undefined,
      gender: request.gender || undefined,
      autoShortlistThreshold: request.autoShortlistThreshold,
      minEducationStatus: request.minEducationStatus || undefined,
      militaryRequirements:
        request.militaryRequirements
        ? request.militaryRequirements.join(',')
        : "",
      workTimePreferenceFullTime:
        request.workTimePreferences.indexOf(WorkTimeList.FULL_TIME) !== -1,
      workTimePreferencePartTime:
        request.workTimePreferences.indexOf(WorkTimeList.PART_TIME) !== -1,
      workTimePreferenceSeasonal: request.workTimePreferences.indexOf(WorkTimeList.SEASONAL) !== -1,
      contactType: request.contactOption,
      contactTypePhoneNumber: request.contactPhone,
      expectedSalaryMax: request.expectedSalaryMax,
      expectedSalaryMin: request.expectedSalaryMin,
      positionId: request.position.id,
      peopleBoxProject: +request.peopleBoxProjectId,
    };
  },
};

export const JobPositionMapper = {
  fromAPIResponse: (response: Object): JobPosition => ({
    id: response.id,
    name: response.name,
  }),
};
