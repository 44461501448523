// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form';

import { withTranslation } from 'react-i18next';
import { UserGrantMap } from '../../enums/userGrant';
import { JobStatusMap } from '../../enums/jobStatus';
import {
  loadThunk as loadCategoriesThunk,
  type CategoriesState,
} from '../../redux/modules/categories';
import { loadThunk as loadBranchesThunk, type BranchesState } from '../../redux/modules/branches';
import { editThunk } from '../../redux/modules/jobs';
import { loadThunk, getDetailById, type JobDetailItemState } from '../../redux/modules/jobDetail';
import { updateUserAfterJobCreditChange } from '../../redux/modules/currentUser';
import formErrorMapper from '../../utils/formErrorMapper';
import { isInit, isFailed, isLoading } from '../../utils/apiState';
import AuthService from '../../services/auth';

import { type JobPosition } from '../../types/job';
import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from '../../redux/modules';
import { type AuthenticatedUser } from '../../types/user';

import Container from '../../components/layout/Container';
import Box from '../../components/elements/Box';
import Header from '../../components/shared/Header';

import JobForm from '../../forms/JobForm';

import Loading from './Loading';
import CloseReasonModal from './CloseReasonModal';
import Alert from '../../components/elements/Alert';
import formInputList from '../../api/formInputList';
import { createJobLocation, uploadImage } from '../../api/job';

type Props = {
  currentUser: AuthenticatedUser,
  jobId: number,
  jobDetail: JobDetailItemState,
  categories: CategoriesState,
  branches: BranchesState,
  loadThunk: number => void,
  loadCategoriesThunk: () => void,
  loadBranchesThunk: () => void,
  editThunk: (jobId: number, data: Object) => void,
  push: Function,
  t: Function,
  updateUserAfterJobCreditChange: AuthenticatedUser => Promise<*>,
};

type State = {
  isCloseReasonModalOpen: boolean,
  formData: Object,
  formViewData: any,
  alertMessage: string,
  showAlert: boolean,
  alertData: any,
  loading: boolean,
};

const dataURLtoFile = (dataUrl: string, fileName: string) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const blob = new Blob([u8arr], { type: mime });

  return new File([blob], fileName, { type: mime });
}

class JobEdit extends PureComponent<Props, State> {
  props: Props;

  state: State;

  initialValues: Object;

  handleSubmit: Object => void;

  openCloseReasonModal: () => void;

  closeCloseReasonModal: () => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      isCloseReasonModalOpen: false,
      formData: {},
      formViewData: null,
      alertMessage: '',
      showAlert: false,
      alertData: null,
      loading: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.openCloseReasonModal = this.openCloseReasonModal.bind(this);
    this.closeCloseReasonModal = this.closeCloseReasonModal.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
  }

  async componentWillMount() {
    await this.props.loadThunk(this.props.jobId);

    if (isInit(this.props.categories)) {
      this.props.loadCategoriesThunk();
    }

    if (AuthService.isGranted(UserGrantMap.HR_GRANT) && isInit(this.props.branches)) {
      this.props.loadBranchesThunk();
    }

    formInputList('JobForm')
      .then(res => {
        this.setState({
          formViewData: res,
        });
      })
      .catch(e => {
        this.setState({
          alertMessage: 'common.errors.anErrorOccurred',
          alertData: { errorCode: 1014 },
          showAlert: true,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentWillReceiveProps(nextProps: Props) {
    if (isFailed(nextProps.jobDetail)) {
      if (typeof nextProps.jobDetail.error === 'object' && nextProps.jobDetail.error.code === 404) {
        this.props.push('/unauthorized');
        return;
      }

      this.props.push('/error');
    }
  }

  handleAlertClose = () => {
    this.setState({
      showAlert: false,
    });
  };

  async handleSubmit(values: Object) {
    const data = Object.assign(
      {},
      values,
      !AuthService.isGranted(UserGrantMap.HR_GRANT) ? { user: this.props.currentUser.user.id } : {},
      !values.cover.startsWith('data:image') ? { cover: undefined } : {},
      values.gender === 'all' ? { gender: undefined } : {},
      values.minExperienceDuration === 0 ? { minExperienceDuration: undefined } : {},
      values.userAccessToken = this.props.currentUser.accessToken
    );

    if (
      data.birthDateMin &&
      data.birthDateMax &&
      parseInt(data.birthDateMin, 10) >= parseInt(data.birthDateMax, 10)
    ) {
      throw new SubmissionError({
        birthDateMax: 'Maksimum yaş minimum yaştan küçük olamaz.',
      });
    }

    if (values.position) {
      const position: JobPosition = {
        id: values.position.value,
        name: values.position.label,
      };
      data.position = position;
    }

    data.autoShortlistThreshold = values.autoShortlistEnabled
      ? parseFloat(values.autoShortlistThreshold) / 100
      : null;

    if (
        !data.selectedCompanyLocations
        || (data.selectedCompanyLocations && data.selectedCompanyLocations.length === 0)
      ) {
        throw new SubmissionError({
          selectedCompanyLocations: 'Konum Secmek Zorunlu',
        });
    }

    if (data.languageLevels) {
      data.languageLevels.forEach((languageLevel) => {
        if (languageLevel.languageId === 0 || languageLevel.minLanguageLevel === 0) {
          throw new SubmissionError({
            languageLevels: 'Lutfen gecerli dil bilgisi giriniz',
          });
        }
      })
    }

    if (data.status === JobStatusMap.CLOSED) {
      this.setState({ formData: data }, () => {
        this.openCloseReasonModal();
      });

      return;
    }

    try {
      if (data.cover) {
        const file = dataURLtoFile(data.cover, 'image.jpg');
        const imageUrl = await uploadImage(file);
        data.cover = imageUrl;
      }

      const editJob = await this.props.editThunk(this.props.jobId, data);

      await createJobLocation({
        jobId: editJob.id,
        companyLocationIds: data.selectedCompanyLocations
      })

      if (
        data.status === JobStatusMap.ACTIVE &&
        this.props.jobDetail.data.job.status !== JobStatusMap.ACTIVE
      ) {
        this.props.updateUserAfterJobCreditChange();
      }

      window.location.replace(`/applications?jobId=${this.props.jobId}&page=1&activeTab=all`);
    } catch (err) {
      if (err.code && err.code === 400) {
        throw new SubmissionError(formErrorMapper(err.errors.children));
      }

      this.props.push('/error');
    }
  }

  async handleCloseReasonSubmit(reason?: string) {
    this.closeCloseReasonModal();

    const data = Object.assign({}, this.state.formData, reason ? { reason } : {});

    try {
      if (data.cover) {
        const file = dataURLtoFile(data.cover, 'image.jpg');
        const imageUrl = await uploadImage(file);
        data.cover = imageUrl;
      }

      const editJob = await this.props.editThunk(this.props.jobId, data);

      await createJobLocation({
        jobId: editJob.id,
        companyLocationIds: data.selectedCompanyLocations
      })
    } catch (e) {
      if (e.code && e.code === 400) {
        throw new SubmissionError(formErrorMapper(e.errors.children));
      }

      this.props.push('/error');
    }

    this.props.push(`/applications?jobId=${this.props.jobId}&page=1&activeTab=all`);
  }

  openCloseReasonModal() {
    this.setState({ isCloseReasonModalOpen: true });
  }

  closeCloseReasonModal() {
    this.setState({ isCloseReasonModalOpen: false });
  }

  render() {
    const { categories, branches, currentUser, jobDetail, t } = this.props;

    if (isLoading(jobDetail) || this.state.loading) {
      return (
        <div>
          <Header currentUser={currentUser} />
          <Loading />
        </div>
      );
    }

    const { position, autoShortlistThreshold } = jobDetail.data.job;

    const workTimePreferences = [];

    if (jobDetail.data.job.workTimePreferenceFullTime) {
      workTimePreferences.push("full_time");
    }

    if (jobDetail.data.job.workTimePreferencePartTime) {
      workTimePreferences.push("part_time");
    }

    if (jobDetail.data.job.workTimePreferenceSeasonal) {
      workTimePreferences.push("seasonal");
    }

    const values = {
      category: jobDetail.data.job.categoryId,
      cover: jobDetail.data.job.image,
      user: jobDetail.data.job.userId,
      title: jobDetail.data.job.title,
      position: position ? { value: position.id, label: position.name } : undefined,
      description: jobDetail.data.job.description,
      status: jobDetail.data.job.status,
      minExperienceDuration: jobDetail.data.job.minExperienceDuration || 0,
      militaryRequirements: jobDetail.data.job.militaryRequirements || [],
      expectedSalaryMin: jobDetail.data.job.expectedSalaryMin,
      expectedSalaryMax: jobDetail.data.job.expectedSalaryMax,
      gender: jobDetail.data.job.gender || 'all',
      birthDateMin: jobDetail.data.job.birthDateMin || '',
      birthDateMax: jobDetail.data.job.birthDateMax || '',
      minEducationStatus: jobDetail.data.job.minEducationStatus,
      minEnglishLevel: jobDetail.data.job.minEnglishLevel,
      contactOption: jobDetail.data.job.contactType || '',
      contactPhone: jobDetail.data.job.contactTypePhoneNumber || '',
      workTimePreferences: workTimePreferences,
      autoShortlistThreshold: autoShortlistThreshold ? autoShortlistThreshold * 100 : null,
      autoShortlistEnabled: !!autoShortlistThreshold,
      peopleBoxProjectId: jobDetail.data.job.peopleBoxProjectId || undefined,
      workStartsAt: jobDetail.data.job.options.shifts.workStartsAt,
      dailyTotalWorkHour: jobDetail.data.job.options.shifts.dailyTotalWorkHour,
      weeklyOffDayCount: jobDetail.data.job.options.shifts.weeklyOffDayCount,
      offTime: jobDetail.data.job.options.shifts.offTime,
      languageLevels: jobDetail.data.job.jobLanguages.map((jobLanguage) => {
        return { languageId: jobLanguage.language.id, minLanguageLevel: jobLanguage.minLanguageLevel };
      }),
      selectedTags: jobDetail.data.job.jobTags.map((jobTag) => jobTag.tag.id),
      selectedCompanyLocations: jobDetail.data.job.jobLocations.map((jobLocation) => jobLocation.companyLocation.id)
    };

    return (
      <div>
        <Header currentUser={currentUser} />

        <div className="u-pad-ends@md-up u-pad-ends-small@sm-down">
          <Container>
            <Box className="u-pad-ends u-pad-sides@md-up u-pad-sides-small@sm-down">
              <h1 className="u-clear-gap-top u-text-center u-font-weight-bold">
                {t('jobs.edit.title')}
              </h1>
              <JobForm
                formViewData={this.state.formViewData}
                initialValues={values}
                onSubmit={this.handleSubmit}
                categories={categories}
                branches={branches}
                currentUser={currentUser}
                isEdit
              />
            </Box>
          </Container>
        </div>

        <CloseReasonModal
          isOpen={this.state.isCloseReasonModalOpen}
          onModalCloseRequested={this.closeCloseReasonModal}
          onSubmit={reason => {
            this.handleCloseReasonSubmit(reason);
          }}
          t={t}
        />

        <Alert
          isVisible={this.state.showAlert}
          onConfirmClick={this.handleAlertClose}
          message={t(this.state.alertMessage, this.state.alertData)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, { match }: { match: Object }): Object => ({
  currentUser: state.currentUser,
  jobDetail: getDetailById(state, { jobId: match.params.jobId }),
  jobId: match.params.jobId,
  categories: state.categories,
  branches: state.branches,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators(
    {
      loadThunk,
      loadCategoriesThunk,
      loadBranchesThunk,
      editThunk,
      push,
      updateUserAfterJobCreditChange,
    },
    dispatch,
  );

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobEdit));
