// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { UserTypeMap } from '../../enums/userType';
import { UserGrantMap } from '../../enums/userGrant';
import {
  loadThunk as loadCategoriesThunk,
  type CategoriesState,
} from '../../redux/modules/categories';
import { loadThunk as loadBranchesThunk, type BranchesState } from '../../redux/modules/branches';
import { updateUserAfterJobCreditChange } from '../../redux/modules/currentUser';
import { createThunk } from '../../redux/modules/jobs';
import formErrorMapper from '../../utils/formErrorMapper';
import { isInit } from '../../utils/apiState';
import { JobStatusMap } from '../../enums/jobStatus';
import AuthService from '../../services/auth';

import { type JobPosition } from '../../types/job';
import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from '../../redux/modules';
import { type AuthenticatedUser } from '../../types/user';

import Container from '../../components/layout/Container';
import Box from '../../components/elements/Box';
import Header from '../../components/shared/Header';

import JobForm from '../../forms/JobForm';
import formInputList from '../../api/formInputList';
import Loading from '../../components/elements/Loading';
import Alert from '../../components/elements/Alert';
import Analytics from '../../services/analytics';
import { createJobLocation, uploadImage } from '../../api/job';

type Props = {
  currentUser: AuthenticatedUser,
  categories: CategoriesState,
  branches: BranchesState,
  loadCategoriesThunk: () => void,
  loadBranchesThunk: () => void,
  createThunk: Object => void,
  push: Function,
  t: Function,
  updateUserAfterJobCreditChange: AuthenticatedUser => Promise<*>,
};

const dataURLtoFile = (dataUrl: string, fileName: string) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]); // Decode base64 string
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const blob = new Blob([u8arr], { type: mime });

    return new File([blob], fileName, { type: mime });
  }

class JobCreate extends PureComponent<Props> {
  props: Props;

  initialValues: Object;

  constructor(props: Props) {
    super(props);

    const { user } = this.props.currentUser;

    this.initialValues = {
      category: 0,
      user: 0,
      title: '',
      description: '',
      status: JobStatusMap.ACTIVE,
      minExperienceDuration: '',
      gender: 'all',
      militaryRequirements: [],
      birthDateMin: '',
      birthDateMax: '',
      locationSearch: '',
      locationPosition: 0,
      latitude: Number(user.country.latitude),
      longitude: Number(user.country.longitude),
      centerLatitude: Number(user.country.latitude),
      centerLongitude: Number(user.country.longitude),
      zoom: user.userType === UserTypeMap.BRANCH ? 12 : 5,
      workTimePreferences: [],
      contactOption: '',
      autoShortlistThreshold: null,
      workStartsAt: null,
    };

    this.state = {
      formViewData: null,
      alertMessage: '',
      showAlert: false,
      alertData: null,
      loading: true,
    };

    this.handleAlertClose = this.handleAlertClose.bind(this);
  }

  componentWillMount() {
    if (isInit(this.props.categories)) {
      this.props.loadCategoriesThunk();
    }

    if (AuthService.isGranted(UserGrantMap.HR_GRANT) && isInit(this.props.branches)) {
      this.props.loadBranchesThunk();
    }

    formInputList('JobForm')
      .then(res => {
        this.setState({
          formViewData: res,
        });
      })
      .catch(e => {
        this.setState({
          alertMessage: 'common.errors.anErrorOccurred',
          alertData: { errorCode: 1014 },
          showAlert: true,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleAlertClose = () => {
    this.setState({
      showAlert: false,
    });
  };

  // eslint-disable-next-line consistent-return
  handleSubmit = async (values: Object): Promise<void> => {
    const data = Object.assign(
      {},
      values,
      !AuthService.isGranted(UserGrantMap.HR_GRANT) ? { user: this.props.currentUser.user.id } : {},
      values.gender === 'all' ? { gender: undefined } : {},
      values.userAccessToken = this.props.currentUser.accessToken
    );

    if (
      data.birthDateMin &&
      data.birthDateMax &&
      parseInt(data.birthDateMin, 10) >= parseInt(data.birthDateMax, 10)
    ) {
      throw new SubmissionError({
        birthDateMax: 'Maksimum yaş minimum yaştan küçük olamaz.',
      });
    }

    data.autoShortlistThreshold = values.autoShortlistEnabled
      ? parseFloat(values.autoShortlistThreshold) / 100
      : null;

    if (values.position) {
      const position: JobPosition = {
        id: values.position.value,
        name: values.position.label,
      };
      data.position = position;
    }

    if (
        !data.selectedCompanyLocations
        || (data.selectedCompanyLocations && data.selectedCompanyLocations.length === 0)
      ) {
        throw new SubmissionError({
          selectedCompanyLocations: 'Konum Secmek Zorunlu',
        });
    }

    if (data.languageLevels) {
      data.languageLevels.forEach((languageLevel) => {
        if (languageLevel.languageId === 0 || languageLevel.minLanguageLevel === 0) {
          throw new SubmissionError({
            languageLevels: 'Lutfen gecerli dil bilgisi giriniz',
          });
        }
      })
    }



    try {
      if (data.cover) {
        const file = dataURLtoFile(data.cover, 'image.jpg');
        const imageUrl = await uploadImage(file);
        data.cover = imageUrl;
      }

      const createdJob = await this.props.createThunk(data);

      await createJobLocation({
        jobId: createdJob.id,
        companyLocationIds: data.selectedCompanyLocations
      })

      this.props.updateUserAfterJobCreditChange();

      Analytics.companyCreatedJob(
        this.props.currentUser.user.country.id,
        createdJob.id,
        createdJob.title,
      );

      this.props.push('/jobs');
    } catch (err) {
      if (err.code && err.code === 400) {
        if (err.message === 'insufficient_balance') {
          return alert('Yetersiz bakiye');
        }

        throw new SubmissionError(formErrorMapper(err.errors.children));
      }

      this.props.push('/error');
    }
  };

  render() {
    const { categories, branches, currentUser, t } = this.props;
    if (this.state.loading) return <Loading />;

    return (
      <div>
        <Header currentUser={currentUser} />

        <div className="u-pad-ends@md-up u-pad-ends-small@sm-down">
          <Container>
            <Box className="u-pad-ends u-pad-sides@md-up u-pad-sides-small@sm-down">
              <h1 className="u-clear-gap-top u-text-center u-font-weight-bold">
                {t('jobs.create.title')}
              </h1>
              <JobForm
                formViewData={this.state.formViewData}
                initialValues={this.initialValues}
                onSubmit={this.handleSubmit}
                categories={categories}
                branches={branches}
                isEdit={false}
                currentUser={this.props.currentUser}
              />
            </Box>
          </Container>
        </div>

        <Alert
          isVisible={this.state.showAlert}
          onConfirmClick={this.handleAlertClose}
          message={t(this.state.alertMessage, this.state.alertData)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): Object => ({
  currentUser: state.currentUser,
  categories: state.categories,
  branches: state.branches,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators(
    {
      loadCategoriesThunk,
      loadBranchesThunk,
      createThunk,
      push,
      updateUserAfterJobCreditChange,
    },
    dispatch,
  );

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobCreate));
