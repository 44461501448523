// @flow
import React, { PureComponent } from 'react';
import cx from 'classnames';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';

import { withTranslation } from 'react-i18next';
import { createAppointmentForApplication } from '../../../../api/appointment';

import required from '../../../../validators/required';
import formErrorMapper from '../../../../utils/formErrorMapper';

import { type Appointment } from '../../../../types/appointment';
import { type Location } from '../../../../types/location';
import { type AuthenticatedUser } from '../../../../types/user';
import { type Application } from '../../../../types/application';

import Row from '../../../../components/layout/Row';
import Col from '../../../../components/layout/Col';
import Icon from '../../../../components/elements/Icon';
import Button from '../../../../components/ui/Button';
import Map from '../../../../components/elements/Map';
import GeoSuggest from '../../../../vendor/react-geosuggest/src/Geosuggest';
import { themePalette } from '../../../../theme/index';

type Props = {
  currentUser: AuthenticatedUser,
  application: Application,
  onCreate?: (appointment: Appointment) => void,
  handleSubmit: Function,
  change: (field: string, value: any) => void,
  submitting: boolean,
  t: Function,
};

type State = {
  isDatePickerFocused: boolean,
  googleMapsInitialized: boolean,
  submitError: string,
};

type FormValues = {
  date: moment,
  time: moment,
  location: Location,
  address?: string,
};

class CreateAppointment extends PureComponent<Props, State> {
  props: Props;

  state: State = {
    isDatePickerFocused: false,
    googleMapsInitialized: false,
    submitError: "",
  };

  componentWillMount() {
    this.props.change('location', this.props.currentUser.user.location);
  }

  handleSave = (values: FormValues) => {
    if (!this.props.application.isShortListed) {
      this.setState({ submitError: "jobDetail.profile.appointmentShortlistErrorMessage" });
      return;
    } else {
      this.setState({ submitError: "" });
    }

    const hour = moment(values.time).get('hour');
    const minute = moment(values.time).get('minute');
    const dateTime = moment(values.date)
      .set('hour', hour)
      .set('minute', minute)
      .toDate();

    return this.createAppointment(dateTime, values.location, values.address);
  };

  createAppointment = async (dateTime: Date, location: Location, address?: string) => {
    const { application, onCreate, t, currentUser } = this.props;

    try {
      const today = new Date();
      if (today >= dateTime) {
        throw new Error(t('jobDetail.profile.appointmentDateError'));
      }

      const appointment = await createAppointmentForApplication(application.id, {
        appointmentAt: dateTime,
        location,
        address,
        workerId: application.user.id,
        companyId: currentUser.user.id,
        jobId: application.job ? application.job.id : undefined,
      });

      if (onCreate) {
        onCreate(appointment);
      }
    } catch (error) {
      if (error.code && error.code === 400) {
        const errors = formErrorMapper(error.errors.children);

        throw new SubmissionError({
          date: errors.appointmentAt,
          time: errors.appointmentAt,
          location: errors.lat || errors.lng,
          address: errors.address,
        });
      } else if (error.message) {
        alert(error.message);
      } else {
        alert(t('common.errors.errorOccured'));
      }
    }
  };

  render() {
    const { currentUser, t } = this.props;
    const { language } = currentUser.user;
    const { googleMapsInitialized, submitError } = this.state;
    return (
      <form className="u-pad-ends-small u-pad-sides-small">
        <h6 className="u-gap-top u-color-primary" style={{ color: themePalette.color }}>
          {t('jobDetail.profile.date.label')}
        </h6>

        <Row className="u-gap-bottom">
          <Col md={6}>
            <Field
              name="date"
              format={null}
              validate={[required(t('jobDetail.profile.date.required'))]}
              component={field => (
                <div
                  className={cx('c-form-group c-form-group--with-icon', {
                    'has-error': field.meta.touched && field.meta.error,
                  })}
                >
                  <Icon size="medium" name="appointment" className="c-form-group__icon" />
                  <SingleDatePicker
                    id="appointment-date"
                    placeholder={t('jobDetail.profile.date.placeholder')}
                    block
                    noBorder
                    date={field.input.value}
                    focused={this.state.isDatePickerFocused}
                    onDateChange={field.input.onChange}
                    onFocusChange={({ focused }) => this.setState({ isDatePickerFocused: focused })}
                  />
                  {field.meta.touched && field.meta.error && (
                    <span className="c-form-group__alert">{field.meta.error}</span>
                  )}
                </div>
              )}
            />
          </Col>
          <Col md={6}>
            <Field
              name="time"
              format={null}
              validate={[required(t('jobDetail.profile.time.required'))]}
              component={field => (
                <div
                  className={cx('c-form-group c-form-group--with-icon', {
                    'has-error': field.meta.touched && field.meta.error,
                  })}
                >
                  <Icon size="medium" name="clock" className="c-form-group__icon" />
                  <TimePicker
                    value={field.input.value}
                    onChange={field.input.onChange}
                    placeholder={t('jobDetail.profile.time.placeholder')}
                    showSecond={false}
                    minuteStep={5}
                  />
                  {field.meta.touched && field.meta.error && (
                    <span className="c-form-group__alert">{field.meta.error}</span>
                  )}
                </div>
              )}
            />
          </Col>
        </Row>

        <hr className="u-hr-line" />

        <h6 className="u-gap-top u-color-primary" style={{ color: themePalette.color }}>
          {t('jobDetail.profile.address.label')}
        </h6>
        <Field
          name="address"
          format={null}
          component={field => (
            <div
              className={cx('c-form-group', {
                'has-error': field.meta.touched && field.meta.error,
              })}
            >
              <textarea
                className="c-form-control"
                placeholder={t('jobDetail.profile.address.placeholder')}
                style={{ minHeight: 100 }}
                value={field.input.value || ''}
                onChange={field.input.onChange}
              />
              {field.meta.touched && field.meta.error && (
                <span className="c-form-group__alert">{field.meta.error}</span>
              )}
            </div>
          )}
        />

        <h6 className="u-gap-top u-color-primary" style={{ color: themePalette.color }}>
          {t('jobDetail.profile.location.label')}
        </h6>

        <Field
          name="location"
          format={null}
          validate={[required(t('jobDetail.profile.location.required'))]}
          value={currentUser.user.location}
          component={field => (
            <div
              className={cx('c-form-group', {
                'has-error': field.meta.touched && field.meta.error,
              })}
            >
              {googleMapsInitialized ? (
                <GeoSuggest
                  id={field.input.name}
                  inputClassName="c-form-control u-gap-bottom-small"
                  onSuggestSelect={suggest => {
                    const { lat, lng } = suggest.location;
                    const location = { latitude: lat, longitude: lng };
                    field.input.onChange(location);
                  }}
                />
              ) : (
                <div style={{ height: '30px', width: '100%', marginBottom: '11px' }}></div>
              )}
              <Map
                containerElement={<div style={{ height: '300px' }} />}
                mapElement={<div style={{ height: '100%' }} />}
                zoom={10}
                center={field.input.value || currentUser.user.location}
                marker={field.input.value}
                onClick={(e: any) => {
                  const latitude = e.latLng.lat();
                  const longitude = e.latLng.lng();
                  field.input.onChange({ latitude, longitude });
                }}
                language={language.languageCode}
                onIdle={() =>
                  !googleMapsInitialized && this.setState({ googleMapsInitialized: true })
                }
              />
              {field.meta.touched && field.meta.error && (
                <span className="c-form-group__alert">{field.meta.error}</span>
              )}
            </div>
          )}
        />

        <div className="u-gap-top">
          {submitError && (
            <span style={{
              color: themePalette.color,
            }}>{t(submitError)}</span>
          )}
          <Button
            block
            outline
            primary
            loading={this.props.submitting}
            onClick={this.props.handleSubmit(this.handleSave)}
            style={{
              boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
              color: themePalette.color,
            }}
          >
            {t('jobDetail.profile.createAppointment')}
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm({ form: 'appointment-form' })(withTranslation()(CreateAppointment));
