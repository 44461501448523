// @flow
export type Language = {
  id: number,
  languageName: string,
  languageCode: string,
  isActive: boolean,
};

export type LanguageLevel =  {
  languageId: number;
  minLanguageLevel: LanguageLevelValue;
};

export const LanguageLevelValue = {
  None: 1,
  Beginner: 2,
  Intermediate: 3,
  Advanced: 4,
};
