// @flow
import { Pagination } from '../types/pagination';

const hasNextPage = (response) => {
  const { total, page, limit } = response;
  const totalPages = Math.ceil(total / limit);
  return page < totalPages;
}

export const PaginationMapper = {
  fromAPIResponse(response: Object): Pagination {
    return {
      totalPage: Math.ceil(response.total / response.limit),
      currentPage: response.page,
      nextPage: hasNextPage(response),
    };
  },
};
