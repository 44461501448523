// @flow

import React, { PureComponent } from 'react';
import { withRouter, type Match, type RouterHistory } from 'react-router-dom';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Box from '../../../components/elements/Box';
import Loading from '../../../components/elements/Loading';
import queryString from 'query-string';
import Pagination from '../../../components/ui/Pagination';

import { loadAllApplicationsThunk, loadShortListedApplicationsThunk } from '../../../redux/modules/applications';

import { type Application } from '../../../types/application';
import { type Appointment } from '../../../types/appointment';
import Icon from '../../../components/elements/Icon';
import { themePalette } from '../../../theme';
import { getAllAppointmentByApplicationIds, getAppointmentList } from '../../../api/appointment';
import { AppointmentStatusName } from '../../../enums/appointmentStatus';
import { ApplicationState } from '../../../redux/modules/applications';
import ApplicationList from './applicationList';
import AppointmentList from './appointmentList';

type Props = {
  applications: ApplicationState,
  onApplicationClick: Application => void,
  onAppointmentClick: Appointment => void,
  activeApplicationId: number | null,
  t: Function,
  onReachEnd: (page: number) => void,
  loadAllApplicationsThunk: (page: number, jobId?: number) => void,
  loadShortListedApplicationsThunk: (page: number, jobId?: number) => void,
  loadingMore: boolean,
  match: Match,
  history: RouterHistory,
  handleTabChange: (activeTab: 'all' | 'shortListed' | 'appointment') => void,
  activeTab: 'all' | 'shortListed' | 'appointment',
};

class List extends PureComponent<Props> {
  props: Props;

  state: State;


  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps: Props) {
  }


  render() {
    const {
      applications,
      onApplicationClick,
      onAppointmentClick,
      activeApplicationId,
      t,
      onReachEnd,
      loadingMore,
      history,
      handleTabChange,
      activeTab
    } = this.props;

    const tabMapping = {
      all: applications.pagination.allApplications,
      shortListed: applications.pagination.shortListedApplications,
      appointment: applications.pagination.appointments,
    };

    return (
      <Box>
        <ul className="c-tab-list">
          <li
            className={cx('c-tab-list__item', {
              'is-active': activeTab === 'all',
            })}
          >
            <a
              href=""
              className="c-tab-list__link"
              onClick={(event: Event) => {
                event.preventDefault();
                handleTabChange('all');
              }}
              style={{
                color: activeTab === 'all' ? themePalette.primary_color : null,
                borderColor: activeTab === 'all' ? themePalette.primary_color : null,
              }}
            >
              <Icon name="appointment" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.allApplications')}</span>
            </a>
          </li>

          <li
            className={cx('c-tab-list__item', {
              'is-active': activeTab === 'shortListed',
            })}
          >
            <a
              href=""
              className="c-tab-list__link"
              onClick={(event: Event) => {
                event.preventDefault();
                handleTabChange('shortListed');
              }}
              style={{
                color: activeTab === 'shortListed' ? themePalette.primary_color : null,
                borderColor: activeTab === 'shortListed' ? themePalette.primary_color : null,
              }}
            >
              <Icon name="recruitment" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.shortList')}</span>
            </a>
          </li>

          <li
            className={cx('c-tab-list__item', {
              'is-active': activeTab === 'appointment',
            })}
          >
            <a
              href=""
              className="c-tab-list__link"
              onClick={(event: Event) => {
                event.preventDefault();
                handleTabChange('appointment');
              }}
              style={{
                color: activeTab === 'appointment' ? themePalette.primary_color : null,
                borderColor: activeTab === 'appointment' ? themePalette.primary_color : null,
              }}
            >
              <Icon name="appointment" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.appointment')}</span>
            </a>
          </li>
        </ul>

         {activeTab !== 'appointment' && !loadingMore && (
          <div
            className="c-box__scroll"
          >
            <ApplicationList
              applications={
                activeTab === 'all'
                ? applications.allApplications
                : applications.shortListedApplications
              }
              onApplicationClick={onApplicationClick}
              activeApplicationId={activeApplicationId}
              activeTab={activeTab}
            />
          </div>
        )}

        {activeTab === 'appointment' && !loadingMore && (
          <div
            className="c-box__scroll"
          >
            <AppointmentList
              appointments={applications.appointments}
              activeApplicationId={activeApplicationId}
              onAppointmentClick={onAppointmentClick}
            />
          </div>
        )}

        {loadingMore ? <Loading /> : null}

        <div className="c-box" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '1rem',
            paddingBottom: '1rem'
          }}>
          <Pagination
            activePage={tabMapping[activeTab].page}
            totalItemsCount={tabMapping[activeTab].total}
            limit={tabMapping[activeTab].limit}
            pageRangeDisplayed={5}
            onChange={(page) => onReachEnd(page)}
          />
        </div>
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators(
    {
      loadAllApplicationsThunk,
      loadShortListedApplicationsThunk,
    },
    dispatch,
  );

// $FlowFixMe
export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(List)),
);
