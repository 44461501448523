// @flow

import { list as listRequest, create as createRequest, edit as editRequest, shortListToggle } from '../../api/job';
import {
    detail as detailRequest,
    allApplications as allApplicationsRequest,
    shortListedApplications as shortListedApplicationsRequest,
    shortListToggle as shortListToggleRequest,
} from '../../api/job';
import { getAllAppointments } from '../../api/appointment';
import { getApplication } from '../../api/application';
import { Status } from '../../utils/apiState';
import { Application } from '../../types/application';

// Actions
const REQUEST = 'applications/REQUEST';
const SUCCESS = 'applications/SUCCESS';
const SUCCESS_ALL_APPLICATIONS = 'applications/SUCCESS_ALL_APPLICATIONS';
const SUCCESS_SHORTLISTED_APPLICATIONS = 'applications/SUCCESS_SHORTLISTED_APPLICATIONS';
const SUCCESS_SHORTLIST_TOGGLE = 'applications/SUCCESS_SHORTLIST_TOGGLE';
const SUCCESS_APPOINTMENTS = 'applications/SUCCESS_APPOINTMENTS';
const SUCCESS_APPLICATION = 'applications/SUCCESS_APPLICATION';
const FAILED = 'applications/FAILED';

// Action Creator Types
type RequestAction = {
    type: typeof REQUEST,
    branchId?: number,
    orderBy?: string,
    orderByDir?: string,
};

type SuccessAction = {
    type: typeof SUCCESS,
    jobs: Array<Job>,
    branchId?: number,
    orderBy?: string,
    orderByDir?: string,
};

type FailedAction = {
    type: typeof FAILED,
    error: any,
};

export type ApplicationsActions = RequestAction | SuccessAction | FailedAction ;

// Action Creators
export const request = (): RequestAction => ({
    type: REQUEST
});

export const success = (): SuccessAction => ({
    type: SUCCESS
});

export const failed = (error: any): FailedAction => ({
    type: FAILED,
    error,
});

export const successAllApplications = (data: AllApplications, pagination: any): SuccessAction => ({
    type: SUCCESS_ALL_APPLICATIONS,
    data,
    pagination,
});

export const successShortListedApplications = (data: ShortListedApplications, pagination: any): SuccessAction => ({
    type: SUCCESS_SHORTLISTED_APPLICATIONS,
    data,
    pagination
});

export const successShortListToggle = (applicationId: number, isShortListed: boolean): SuccessAction => ({
    type: SUCCESS_SHORTLIST_TOGGLE,
    applicationId,
    isShortListed,
});

export const successAppointments = (
    data: Array<Appointment>,
    pagination: any,
): SuccessAction => ({
    type: SUCCESS_APPOINTMENTS,
    data,
    pagination
});

export const successApplication = (
    data: Application,
): SuccessAction => ({
    type: SUCCESS_APPLICATION,
    data
});

// Thunks
export const loadAllApplicationsThunk = (page: number, jobId?: number): Function => async (
    dispatch: ReduxDispatch,
  ): Promise<*> => {
    dispatch(request());

    try {
      const data = await allApplicationsRequest(page, jobId);

      await dispatch(successAllApplications(data.payload, {
        total: data.total,
        page: data.page,
        limit: data.limit,
      }));
    } catch (error) {
      dispatch(failed(error));
    }
};

export const loadShortListedApplicationsThunk = (page: number, jobId?: number): Function => async (
    dispatch: ReduxDispatch,
  ): Promise<*> => {
    dispatch(request());

    try {
      const data = await shortListedApplicationsRequest(page, jobId);

      await dispatch(successShortListedApplications(data.payload, {
        total: data.total,
        page: data.page,
        limit: data.limit,
      }));
    } catch (error) {
      dispatch(failed(error));
    }
};

export const loadAppointmentsThunk = (
    page: number,
    jobId?: number,
): Function => async (dispatch: ReduxDispatch): Promise<*> => {
    dispatch(request());

    try {
        const data = await getAllAppointments(page, jobId);
        dispatch(successAppointments(data.payload, {
            total: data.total,
            page: data.page,
            limit: data.limit,
          }));
    } catch (error) {
        dispatch(failed(error));
    }
};

export const loadApplicationThunk = (id: number): Function => async (dispatch: ReduxDispatch): Promise<*> => {
    dispatch(request());

    try {
        const data = await getApplication(id);
        dispatch(successApplication(data));
    } catch (error) {
        dispatch(failed(error));
    }
};

export const shortListToggleThunk = (applicationId: number, isShortListed: boolean): Function => async (
    dispatch: ReduxDispatch,
): Promise<*> => {
    dispatch(request());

    try {
        dispatch(request());
        await shortListToggle(applicationId, isShortListed);
        dispatch(successShortListToggle(applicationId, !isShortListed));
    } catch (error) {
        dispatch(failed(error));
    }
};

// Reducer
export type ApplicationsState = {
    +status: StatusType,
    +data: any,
    +error: any,
};

const emptyItem = {
    status: Status.INIT,
    allApplications: [],
    shortListedApplications: [],
    appointments: [],
    pagination: {
        allApplications: {
            total: 1,
            page: 1,
            limit: 10,
        },
        shortListedApplications: {
            total: 1,
            page: 1,
            limit: 10,
        },
        appointments: {
            total: 1,
            page: 1,
            limit: 10,
        }
    },
};

const initialState = {
    status: Status.INIT,
    allApplications: [],
    shortListedApplications: [],
    appointments: [],
    pagination: {
        allApplications: {
            total: 1,
            page: 1,
            limit: 10,
        },
        shortListedApplications: {
            total: 1,
            page: 1,
            limit: 10,
        },
        appointments: {
            total: 1,
            page: 1,
            limit: 10,
        }
    },
};

export default function reducer(
    state: ApplicationsState = initialState,
    action: ApplicationsActions,
): ApplicationsState {
    switch(action.type) {
        case REQUEST:
            return {
                ...state,
                status: Status.LOADING,
            };
        case FAILED:
            return {
                ...state,
                error: action.error,
                status: Status.FAILED,
            };
        case SUCCESS_ALL_APPLICATIONS:
            return {
                ...state,
                allApplications: action.data,
                pagination: {
                    ...state.pagination,
                    allApplications: action.pagination,
                },
                status: Status.LOADED,
            };
        case SUCCESS_SHORTLISTED_APPLICATIONS:
            return {
                ...state,
                shortListedApplications: action.data,
                pagination: {
                    ...state.pagination,
                    shortListedApplications: action.pagination,
                },
                status: Status.LOADED,
            };
        case SUCCESS_APPOINTMENTS:
            return {
                ...state,
                appointments: action.data,
                pagination: {
                    ...state.pagination,
                    appointments: action.pagination,
                },
                status: Status.LOADED,
            };
        case SUCCESS_APPLICATION:
                return {
                    ...state,
                    allApplications: [...state.allApplications, action.data],
                    status: Status.LOADED,
                };
        case SUCCESS_SHORTLIST_TOGGLE:
                return {
                    ...state,
                    allApplications: state.allApplications.map(app =>
                        app.id === action.applicationId ? { ...app, isShortListed: action.isShortListed } : app
                    ),
                    shortListedApplications: state.shortListedApplications.map(app =>
                        app.id === action.applicationId ? { ...app, isShortListed: action.isShortListed } : app
                    ),
                    status: Status.LOADED,
                };
        default:
            return state;
    }
}

// Selectors
export const getDetailById = (
    state: ReduxState,
    { applicationId, activeTab }: { applicationId: number, activeTab: string }
  ): Application | null => {
    return state.applications[`${activeTab !== "appointment" ? activeTab : "all"}Applications`].find(app => app.id === applicationId) || null;
};
