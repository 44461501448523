// @flow
import { WorkTimeList } from '../enums/workTime';

// eslint-disable-next-line import/prefer-default-export
export function pushWorkTimePreferences(response, workTimePreferences) {
  if (response.workTimePreferenceFullTime) {
    workTimePreferences.push(WorkTimeList.FULL_TIME);
  }

  if (response.workTimePreferencePartTime) {
    workTimePreferences.push(WorkTimeList.PART_TIME);
  }

  if (response.workTimePreferenceSeasonal) {
    workTimePreferences.push(WorkTimeList.SEASONAL);
  }
}

export function uniqueUnion(arr1, arr2, property) {
  const unique = new Set(arr1.map(item => item[property]));
  return [...arr1, ...arr2.filter(item => !unique.has(item[property]))];
}
