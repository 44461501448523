// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import { UserGrantMap } from '../../../enums/userGrant';
import Auth from '../../../services/auth';

import Container from '../../layout/Container';
import Logo from '../Logo';
import CircleImage from '../../elements/CircleImage';
import Icon from '../../elements/Icon';

import { type AuthenticatedUser } from '../../../types/user';
import useTranslator from '../../../hooks/useTranslator';

type Props = {
  /** extra className */
  className?: string,

  currentUser: AuthenticatedUser,
  onApplicationsClick: () => void,
};

/**
 * Header Element
 */
const Header = ({ className = '', currentUser, onApplicationsClick, ...otherProps }: Props) => {
  const { t } = useTranslator();

  const classNames = ['c-navbar', 'c-navbar--primary'];

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    <div
      className={classNames.join(' ')}
      {...otherProps}
      style={{
        ...otherProps.style,
        backgroundColor: '#FF1937',
      }}
    >
      <Container>
        <div className="c-navbar__body">
          <Link to="/" className="c-navbar__logo">
            <Logo
              alt={t('layout.header.title')}
              width={80}
              height={80}
              isHeader={true}
              style={{ objectFit: 'scale-down', margin: 5 }}
            />
          </Link>
          <ul className="c-navbar__nav">
            <li>
              <Link to="/jobs" className="c-navbar__nav-link">
                <Icon name="th-large" className="u-gap-right-small" />
                {t('layout.header.jobs')}
              </Link>
            </li>
            {Auth.isGranted(UserGrantMap.HR_GRANT) && (
              <li>
                <Link to="/branches" className="c-navbar__nav-link">
                  <Icon name="store" className="u-gap-right-small" />
                  {t('layout.header.branches')}
                </Link>
              </li>
            )}

            {Auth.isGranted(UserGrantMap.BRANCH_GRANT) && (
              <li>
                <Link
                  to="/applications?page=1&activeTab=all"
                  className="c-navbar__nav-link"
                  onClick={(e) => {
                    if (onApplicationsClick) {
                      onApplicationsClick();
                    }
                  }}
                >
                  <Icon name="appointment" className="u-gap-right-small" />
                  {t('layout.header.applications')}
                </Link>
              </li>
            )}

            {Auth.isGranted(UserGrantMap.HR_GRANT) ? (
              <li>
                <Link to="/branches-inbox" className="c-navbar__nav-link">
                  <Icon name="message" className="u-gap-right-small" />
                  {t('layout.header.messages')}
                </Link>
              </li>
            ) : (
              <li>
                <Link to="/inbox" className="c-navbar__nav-link">
                  <Icon name="message" className="u-gap-right-small" />
                  {t('layout.header.messages')}
                </Link>
              </li>
            )}
            <li className="c-navbar__seperator" />
            <li>
              <Link to="/profile" className="c-navbar__nav-link">
                <CircleImage small src={currentUser.user.imageUrl} />
                <span className="u-gap-left u-hidden@sm-down"> {t('layout.header.myProfile')}</span>
              </Link>
            </li>
            <li>
              <Link to="/logout" className="c-navbar__nav-link">
                {t('layout.header.logout')}
              </Link>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default Header;
