// @flow
export default {
  jobs: {
    noData: 'Hiç ilan bulunmamaktadır.',
    addJob: 'İLAN OLUŞTUR',
    jobListTitle: 'İlan Başlığı',
    jobListCount: 'Başvuru',
    unreviewed: 'İncelenmemiş',
    shortList: 'Kısa Liste',
    branchName: 'Şube Adı',
    active: 'AKTİF',
    passive: 'PASİF',
    deleted: 'SİLİNDİ',
    draft: 'TASLAK',
    applicationCount: 'Başvuru Sayısı',
    noBranchFound: 'Şube bulunmuyor',
    view: 'İnceleme',
    jobListDate: 'İlan Tarihi',
    status: 'Durum',
    create: {
      title: 'İLAN OLUŞTUR',
    },

    edit: {
      title: 'İLAN DÜZENLE',
    },

    detail: {
      noApplicationData: 'İlanınıza hiç başvuru yapılmadı.',
      sendExcelInfoText: 'Excel çıktısı hazır olduğunda email adresine gönderilecektir',
    },

    excelModal: {
      title: 'Excel çıktısı servisi',
    },
    excelForm: {
      email: {
        label: 'E-posta',
        required: 'Lütfen e-postanızı giriniz.',
      },
      send: 'Gönder',
    },

    excelDownloadModal: {
      title: 'Excel indirme servisi',
    },
    excelDownloadForm: {
      date: {
        label: 'Belirli tarihten sonraki başvurular',
      },
      day: {
        label: 'Gün',
        required: 'Lütfen gün seçin.',
      },
      month: {
        label: 'Ay',
        required: 'Lütfen ay seçin.',
      },
      year: {
        label: 'Yıl',
        required: 'Lütfen yıl seçin.',
      },
      onlyShortlisted: {
        label: 'Sadece kısa listeye eklenen başvurular',
      },
      download: 'İndir',
    },

    jobForm: {
      uploadImage: 'İlan Fotoğrafı Yükleyin',
      infoText: 'Bu bilgiler adaylara gösterilmeyecektir.',
      save: 'KAYDET',
      createJob: 'İLAN OLUŞTUR',
      jobImage: 'İlan Görseli',
      workingShift: 'Çalışma Vardiyası',
      status: {
        label: 'İlan Durumu',
        required: 'Lütfen ilan durumunu seçiniz.',
      },
      user: {
        label: 'İlan Şubesi',
        required: 'Lütfen şube seçiniz.',
      },
      title: {
        label: 'İlan Başlığı',
        required: 'Lütfen pozisyon giriniz.',
      },
      position: {
        label: 'Pozisyon',
        required: 'Lütfen pozisyon seçiniz.',
        placeholder: 'Lütfen Seçiniz',
      },
      category: {
        label: 'Kategori',
        required: 'Lütfen kategori seçiniz.',
      },
      description: {
        label: 'İlan Açıklaması',
        required: 'Lütfen ilan açıklaması giriniz.',
      },
      contactOption: {
        label: 'Kısa listeye aldığınız adaylar doğrudan sizinle irtibata geçsin mi?',
        required: 'Lütfen irtibat bilgisi seçiniz.',
      },
      autoShortlistEnabled: {
        label: 'Otomatik eşleşme',
      },
      autoShortlistThreshold: {
        label: 'Minimum eşleşme skoru',
        required: 'Lütfen eşleşme skoru giriniz.',
        minNumber: 'Minimum değeri {{count}} olmalı.',
        maxNumber: 'Maksimum değeri {{count}} olmalı',
      },
      contactPhone: {
        label: 'Telefon Numarası',
        required: 'Lütfen telefon numarası giriniz.',
      },
      workTimePreferences: {
        label: 'Çalışma Şekli',
        minLength: 'Bu alan zorunludur.',
        required: 'Lütfen calisma zamanını seçiniz.',
      },
      minExperienceDuration: {
        label: 'Minimum Tecrübe',
      },
      expectedSalaryMin: {
        label: 'Minimum Maaş Beklentisi',
      },
      expectedSalaryValidate: 'Bu deger sifirdan buyuk olmak zorundadir.',
      expectedSalaryMax: {
        label: 'Maksimum Maaş Beklentisi',
      },
      gender: {
        label: 'Cinsiyet',
        required: 'Lütfen cinsiyet filtresi seçiniz.',
      },
      militaryRequirements: {
        label: 'Askerlik Gereksinimi',
      },
      birthDateMin: {
        label: 'Minimum Yaş',
        minNumber: 'Minimum yaş en az {{age}} olmalıdır.',
      },
      birthDateMax: {
        label: 'Maksimum Yaş',
        minNumber: 'Minimum yaş en az {{age}} olmalıdır.',
      },
      minEducationStatus: {
        label: 'Minimum Eğitim Seviyesi',
      },
      minEnglishLevel: {
        label: 'Minimum İngilizce Seviyesi',
      },
      locationSearch: {
        label: 'Lokasyon Ara',
      },
      locationPosition: {
        label: 'Lokasyon Seçiniz',
        required: 'Lütfen lokasyon seçiniz.',
      },
      peopleBoxProjectId: {
        label: 'People Box Id Giriniz',
        required: 'Lütfen people box ID giriniz.',
      },
      workStartsAt: {
        label: 'İş Başlama Saati',
        required: 'Lütfen iş başlama saatini belirtin.'
      },
      dailyTotalWorkHour: {
        label: 'Günlük Toplam Çalışma Saati',
        required: 'Lütfen günlük toplam çalışma saatini belirtin.'
      },
      weeklyOffDayCount: {
        label: 'Haftalık İzin Günü Sayısı',
        required: 'Lütfen haftalık izin günü sayısını belirtin.'
      },
      offTime: {
        label: 'İzin Zamanı',
        required: 'Lütfen izin zamanını belirtin.'
      },
      week: {
        weekday: 'Hafta içi',
        weekend: 'Hafta sonu'
      }
    },
  },
};
