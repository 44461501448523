// @flow

import api from '../utils/api';

import { ApplicationMapper } from '../mappers/application';
import { type Application } from '../types/application';
import apiNew from '../utils/apiNew';

export const detail = async (jobId: number, applicationId: number): Promise<Application> => {
  const response = await apiNew.get(`/search/application/${applicationId}`);

   if (!response.ok) {
     throw response.data;
   }

  return ApplicationMapper.fromAPIResponse(response.data.payload);
};

export const workerComment = async (
  workerId: number,
  data: { comment: string },
): Promise<{ status: true }> => {
  const response = await api.post(`/user/update-comment/${workerId}`, data);

  if (!response.ok) {
    throw response.data;
  }

  return { status: true };
};

export const seen = async (applicationId: number): Promise<{ status: true }> => {
  const response = await apiNew.put(`/job/application/${applicationId}/mark-as-seen`);

  if (!response.ok) {
    throw response.data;
  }

  return { status: true };
};

type FeedbackRequest = {
  isApplicantCameToAppointment: boolean | null,
  isAppointmentPositive: boolean | null,
  isOfferSentToApplicant: boolean | null,
  isApplicantAcceptTheOffer: boolean | null,
};

export const feedback = async (
  jobId: number,
  applicationId: number,
  data: FeedbackRequest,
): Promise<Application> => {
  const response = await api.put(
    `/job/detail/${jobId}/application/${applicationId}/feedback`,
    data,
  );

  if (!response.ok) {
    throw response.data;
  }

  return ApplicationMapper.fromAPIResponse(response.data.payload.application);
};

export const getApplication = async (id: number): Promise<Application> => {
  const response = await apiNew.get(`/job/application/detail/${id}`);

  if (!response.ok) {
    throw response.data;
  }

  return ApplicationMapper.fromAPIResponse(response.data.payload);
};
