// @flow

import api from '../utils/api';
import apiNew from '../utils/apiNew';

import { CompanyMapper, WorkerMapper } from '../mappers/user';
import { type Company, type Worker } from '../types/user';

export type BranchData = {
  companyName: string,
  firstName: string,
  lastName: string,
  email: string,
  location: string,
};

export const list = async (): Promise<Array<Company>> => {
  const response = await api.get('/branch-accounts/list');

  if (!response.ok) {
    throw response.data;
  }

  return CompanyMapper.fromAPIResponseMultiple(response.data.payload.branch_accounts);
};

export const create = async (data: BranchData): Promise<Company> => {
  const response = await api.put('/branch-accounts/create', data);

  if (!response.ok) {
    throw response.data;
  }

  return CompanyMapper.fromAPIResponse(response.data.payload.branch_account);
};

export const edit = async (id: number, data: BranchData): Promise<Company> => {
  const response = await api.post(`/branch-accounts/edit/${id}`, data);

  if (!response.ok) {
    throw response.data;
  }

  return CompanyMapper.fromAPIResponse(response.data.payload.branch_account);
};

export const remove = async (id: number): Promise<boolean> => {
  const response = await api.delete(`/branch-accounts/delete/${id}`);

  if (!response.ok) {
    throw response.data;
  }

  return true;
};

export const nearby = async (branchId: number): Promise<Worker[]> => {
  const response = await api.get(`/branches/${branchId}/near-workers`);

  if (!response.ok) {
    throw response.data;
  }

  return WorkerMapper.fromAPIResponseMultiple(response.data.payload.workers);
};

export const getCompaniesHasActiveJob = async (): Promise<any> => {
  const response = await apiNew.get('search/job/counts');

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};
