// @flow
export default {
  jobDetail: {
    allApplications: 'TODAS LAS SOLICITUDES',
    shortList: 'PRESELECIONES',
    appointment: 'ENTERVISTA',
    searchPlaceholder: 'Buscar por nombre',
    applyFilter: 'FILTRO',
    removeFromShortlist: 'Quitar de preseleccionados',
    addToShortList: 'Agregar a preseleccionados',
    remainingTime: 'Tiempo restante',
    experience: 'Experiencia laboral',
    address: 'Dirección',
    new: 'NUEVO',
    inShortList: 'PRESELECIONADO',
    shortListExitQuestion:
      'Esta seguro que quiere remover a este candidato de la lista de seleccionados?',
    jobClosedSubject: 'Razón por la que cierra la oferta',
    found_via_app: 'Encontré mi empleado por medio de Bonded',
    found_via_other: 'Encontré mi empleado por otro medio',
    not_need_anymore: 'Ya no necesito ningún un empleado',
    other: 'Otro',
    noAppointmentData: 'No hay candidatos con entrevistas.',
    rejectedApplicationCount:
      '<0>{{rejectedApplicationCount}}</0> <1>solicitudes</1> fueron borradas porque no fueron preseleccionadas.',
    applicationDate: 'Fecha de aplicacion',
    profile: {
      profile: 'PERFIL',
      message: 'MENSAJE',
      appointment: 'ENTERVISTA',
      feedback: 'RETROALIMENTACION',
      sendAsPDF: 'Enviar en PDF',
      personalInformation: 'INFORMACION PERSONAL',
      gender: 'Genero',
      birthDate: 'Fecha de nacimiento',
      unknown: 'Desconocido',
      militaryStatus: 'Estatus militar',
      identityNumber: 'CURP',
      disabledStatus: '¿Con capacidades differentes?',
      certificate: 'Certificados',
      drivingLicenses: 'Tipo de licencia de manejo',
      about: 'SOBRE MI',
      noAboutArticle: 'Sin información.',
      educationInformation: 'EDUCACION',
      educationStatus: 'Título',
      university: 'Univercidad',
      englishStatus: 'Nivel de inglés',
      experience: 'EXPERIENCIA LABORAL',
      noExperience: 'Sin experiencia.',
      workPreferences: 'PREFERENCIAS',
      workCategoryPreferences: 'Sector',
      workTimePreferences: 'Tiempo',
      expectedSalary: 'Salario deseado',
      otherApplications: 'OTRAS SOLICITUDES',
      noOtherApplications: 'Sin otras solicitudes.',
      noApplicationFound: 'Sin solicitudes',
      noShortlistedWorker: 'No hay candidatos preselecionado.',
      comments: 'COMENTARIOS',
      comment: {
        label: 'Comentarios sobre candidato',
      },
      commentUpdated: 'Comentario editado con éxito',
      saveComment: 'Guardar comentarios',
      shareApplicantCV: 'Enviar perfil de candidato',
      email: {
        label: 'E-mmail',
        required: 'Escribe e-mail.',
      },
      send: 'Enviar',
      isSureToDeleteConversation: '¿Estás seguro de borrar este mensaje?',
      blockUserQuestion: '¿Estás seguro de bloquear a este usario?',
      deleteConversation: 'Borrar mensaje',
      blockUser: 'Bloquear usario',
      appointmentDateError: 'No puedes agendar una entrevista en una fecha pasada.',
      date: {
        label: 'FECHA DE ENTREVISTA',
        required: 'Seleccionar fecha.',
        placeholder: 'Fecha de entrevista',
      },
      time: {
        required: 'Seleccionar hora.',
        placeholder: 'Hora de entrevista',
      },
      address: {
        label: 'DIRECCION DE ENTREVISTA',
        placeholder: 'Dirección',
      },
      location: {
        label: 'LUGAR DE ENTREVISTA',
        placeholder: 'Dirección',
        required: 'Seleccionar dirección.',
      },
      createAppointment: 'Crear entrevista',
      isApplicantCameToAppointment: {
        label: '¿EL CANDIDATO SE PRESENTO?',
      },
      isAppointmentPositive: {
        label: '¿FUE UNA ENTREVISTA EXITOSA?',
      },
      isOfferSentToApplicant: {
        label: '¿EL CANDIDATO OBTUVO EL EMPLEO?',
      },
      isApplicantAcceptTheOffer: {
        label: '¿EL CANDIDATO ACEPTO?',
      },
      feedbackCommentLabel: 'COMMENTARIOS',
      clearSelections: 'Borrar todo',
      saveReview: 'Guardar retroalimentación',
      appointmentShortlistErrorMessage: 'Para crear una entrevista tienes que preseleccionar el candidato.',
    },

    filter: {
      gender: 'GENERO',
      militaryStatus: 'ESTATUS MILITAR',
      notDone: 'Sin cartilla',
      delayed: 'Después',
      done: 'Con cartilla',
      age: 'EDAD',
      experience: 'EXPERIENCIA LABORAL',
      educationStatus: 'EDUCACCION',
      englishLevel: 'NIVEL DE INGLES',
      distance: 'DISTANCIA',
      byApplicantCameToAppointment: 'FILTRAR POR PRESENTARSE',
      came: 'Se presentó',
      didNotCome: 'No se presentó',
      accordingToInterview: 'FILTRAR POR EXITO DE ENTREVISTA',
      positive: 'Exitosa',
      negative: 'No exitosa',
      accordingToBid: 'FILTRAR POR OBTUVO EMPLEO',
      offerSubmitted: 'Obtuvo',
      noOffer: 'No obtuvo',
      accordingToAcceptTheOffer: 'FILTRAR POR ACEPTO ',
      accepted: 'Aceptó',
      didNotAccept: 'No aceptó',
      clearFilter: 'Borrar todo',
      applyFilter: 'FILTRAR',
    },

    feedbackForm: {
      noAppointment: 'No hay cita creada con este candidato!',
    },
  },
};
