// @flow

import React, { PureComponent } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import Row from '../../../../components/layout/Row';
import Col from '../../../../components/layout/Col';
import FormFieldTextarea from '../../../../components/elements/FormFieldTextarea';
import Button from '../../../../components/ui/Button';

import SelectField from './SelectField';
import { themePalette } from '../../../../theme/index';

type Props = {
  handleSubmit: Function,
  submitting: boolean,
  submitSucceeded: boolean,
  reset: () => void,
  t: Function,
  initialValuesInComponent: {
    isApplicantCameToAppointment: boolean | undefined,
    isAppointmentPositive: boolean | undefined,
    isOfferSentToApplicant: boolean | undefined,
    isApplicantAcceptTheOffer: boolean | undefined,
    appointmentComment: string | undefined,
  },
  isAppointmentPositive: boolean | undefined,
  isOfferSentToApplicant: boolean | undefined,
  isApplicantCameToAppointment: boolean | undefined,
};

class FeedbackForm extends PureComponent<Props> {
  props: Props;

  render() {
    const {
      handleSubmit,
      submitting,
      submitSucceeded,
      reset,
      t,
      initialValuesInComponent,
      isAppointmentPositive,
      isOfferSentToApplicant,
      isApplicantCameToAppointment,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="u-pad-ends-small u-pad-sides-small">
          <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
            {t('jobDetail.profile.isApplicantCameToAppointment.label')}
          </h6>
          <Field
            name="isApplicantCameToAppointment"
            format={null}
            parse={value => value}
            component={SelectField}
            props={{
              disabled: initialValuesInComponent.isApplicantCameToAppointment !== undefined,
            }}
          />
        </div>
        <hr className="u-hr-line" />
        <div className="u-pad-ends-small u-pad-sides-small">
          <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
            {t('jobDetail.profile.isAppointmentPositive.label')}
          </h6>
          <Field
            name="isAppointmentPositive"
            format={null}
            parse={value => value}
            component={SelectField}
            props={{
              disabled:
                initialValuesInComponent.isAppointmentPositive !== undefined ||
                !isApplicantCameToAppointment,
            }}
          />
        </div>
        <hr className="u-hr-line" />
        <div className="u-pad-ends-small u-pad-sides-small">
          <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
            {t('jobDetail.profile.isOfferSentToApplicant.label')}
          </h6>
          <Field
            name="isOfferSentToApplicant"
            format={null}
            parse={value => value}
            component={SelectField}
            props={{
              disabled:
                initialValuesInComponent.isOfferSentToApplicant !== undefined ||
                !isAppointmentPositive,
            }}
          />
        </div>
        <hr className="u-hr-line" />
        <div className="u-pad-ends-small u-pad-sides-small">
          <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
            {t('jobDetail.profile.isApplicantAcceptTheOffer.label')}
          </h6>
          <Field
            name="isApplicantAcceptTheOffer"
            format={null}
            parse={value => value}
            component={SelectField}
            props={{
              disabled:
                initialValuesInComponent.isApplicantAcceptTheOffer !== undefined ||
                !isAppointmentPositive ||
                !isOfferSentToApplicant,
            }}
          />
        </div>
        <hr className="u-hr-line" />

        <div className="u-pad-ends-small u-pad-sides-small">
          <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
            {t('jobDetail.profile.feedbackCommentLabel')}
          </h6>
          {initialValuesInComponent.appointmentComment ? (
            <div className="u-pad-ends-small u-pad-sides-small">
              <span>{initialValuesInComponent.appointmentComment}</span>
            </div>
          ) : (
            <Field
              name="appointmentComment"
              type="appointmentComment"
              component={FormFieldTextarea}
              label={t('jobDetail.profile.comment.label')}
              props={{
                rows: 8,
              }}
            />
          )}
        </div>

        <div className="u-pad-ends-small u-pad-sides-small">
          <Row>
            <Col md={6}>
              <Button
                ghost
                block
                onClick={reset}
                disabled={!!initialValuesInComponent.isApplicantCameToAppointment || submitSucceeded}
              >
                {t('jobDetail.profile.clearSelections')}
              </Button>
            </Col>
            <Col md={6}>
              <Button
                type="submit"
                className={
                  initialValuesInComponent.isApplicantCameToAppointment ? 'ghost' : 'success'
                }
                block
                loading={submitting}
                disabled={!!initialValuesInComponent.isApplicantCameToAppointment || submitSucceeded}
              >
                {t('jobDetail.profile.saveReview')}
              </Button>
            </Col>
          </Row>
        </div>
      </form>
    );
  }
}

const ReduxFormFeedbackForm = reduxForm({
  form: 'feedback-form',
  pure: false,
  enableReinitialize: true,
})(withTranslation()(FeedbackForm));

const selector = formValueSelector('feedback-form'); // <-- same as form name

export default connect(state => {
  const {
    isApplicantCameToAppointment,
    isAppointmentPositive,
    isOfferSentToApplicant,
    isApplicantAcceptTheOffer,
  } = selector(
    state,
    'isApplicantCameToAppointment',
    'isAppointmentPositive',
    'isOfferSentToApplicant',
    'isApplicantAcceptTheOffer',
  );
  return {
    isApplicantCameToAppointment,
    isAppointmentPositive,
    isOfferSentToApplicant,
    isApplicantAcceptTheOffer,
  };
})(ReduxFormFeedbackForm);
