// @flow

import React, { PureComponent } from 'react';
import { type Match } from 'react-router-dom';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { createFeedback, changeAppointmentStatus } from '../../../../api/appointment';
import { success as successAction } from '../../../../redux/modules/applicationDetail';
import { AppointmentStatusName } from '../../../../enums/appointmentStatus';
import { AppointmentFeedbackName } from '../../../../enums/appointmentFeedback';


import { type Application } from '../../../../types/application';

import ProfileHeader from '../ProfileHeader';

import FeedbackForm from './Form';
import { getAllAppointmentByApplicationIds } from '../../../../api/appointment';

type Props = {
  application: Application,
  workerRating: number,
  dispatch: any,
  t: Function,
  jobMatch: Match,
};

type State = {
  appointment: Appointment,
  loading: boolean,
}

class Feedback extends PureComponent<Props, State> {
  props: Props;

  state: State = {
    appointment: undefined,
    loading: false,
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchAppointmentByApplication(this.props.application.id);
    this.setState({ loading: false });
  }

  fetchAppointmentByApplication = async (applicationId: number) => {
    const appointments = await getAllAppointmentByApplicationIds([applicationId]);
    this.setState({ appointment: appointments[0] });
  }

  handleSubmit = async (values: any) => {
    const {
      application,
      dispatch,
      t,
      jobMatch,
    } = this.props;

    const { appointment } = this.state;

    if (!appointment || !Object.keys(appointment).length) {
      alert(t('jobDetail.feedbackForm.noAppointment'));
      return;
    }


    const valuesToSubmit = {
      ...values,
      isApplicantCameToAppointment:
        appointment.isApplicantCameToAppointment !== undefined
          ? undefined
          : values.isApplicantCameToAppointment,
      isAppointmentPositive:
        appointment.isAppointmentPositive !== undefined ? undefined : values.isAppointmentPositive,
      isOfferSentToApplicant:
        appointment.isOfferSentToApplicant !== undefined
          ? undefined
          : values.isOfferSentToApplicant,
      isApplicantAcceptTheOffer:
        appointment.isApplicantAcceptTheOffer !== undefined
          ? undefined
          : values.isApplicantAcceptTheOffer,
    };

    try {
      const {
        isApplicantCameToAppointment,
        appointmentComment,
        isAppointmentPositive,
        isOfferSentToApplicant,
        isApplicantAcceptTheOffer,
      } = valuesToSubmit;

      // Handle Applicant Acceptance of Offer
      if (isApplicantAcceptTheOffer !== undefined) {
        await changeAppointmentStatus(
          appointment.id,
          isApplicantAcceptTheOffer ?
            AppointmentStatusName.OFFER_ACCEPTED
            : AppointmentStatusName.OFFER_REJECTED
        );
      }

      // Handle Offer Sent Status
      else if (isOfferSentToApplicant) {
        await changeAppointmentStatus(
          appointment.id,
          AppointmentStatusName.OFFER_SENT
        );
      }

      // Handle Appointment Positive/Negative Status
      else if (isAppointmentPositive !== undefined) {
        await changeAppointmentStatus(
          appointment.id,
          isAppointmentPositive ?
            AppointmentStatusName.APPOINTMENT_POSITIVE
            : AppointmentStatusName.APPOINTMENT_NEGATIVE
        );
      }

      // Handle Applicant Came to Appointment Feedback
      if (isApplicantCameToAppointment !== undefined) {
        await createFeedback({
          appointmentId: appointment.id,
          feedback: isApplicantCameToAppointment
          ? AppointmentFeedbackName.WORKER_CAME
          : AppointmentFeedbackName.WORKER_DID_NOT_COME,
          text: appointmentComment
        });
      }

      alert(t('constants.updateFeedback'));
    } catch (error) {
      alert(error.message || t('common.errors.errorOccured'));
    }
  };

  render() {
    const { application, workerRating } = this.props;
    const { appointment, loading } = this.state;

    if (loading) {
      return (<h1>LOADING</h1>)
    }

    let initialValues = {
      isApplicantAcceptTheOffer: undefined,
      isAppointmentPositive: undefined,
      isOfferSentToApplicant: undefined,
      isApplicantCameToAppointment: undefined,
      appointmentComment: undefined
    };

    if (appointment) {
      switch(appointment.status.name) {
        case AppointmentStatusName.OFFER_ACCEPTED:
          initialValues = {
            ...initialValues,
            isApplicantAcceptTheOffer: true,
            isAppointmentPositive: true,
            isOfferSentToApplicant: true,
          };
          break;
        case AppointmentStatusName.OFFER_REJECTED:
          initialValues = {
            ...initialValues,
            isApplicantAcceptTheOffer: false,
            isAppointmentPositive: true,
            isOfferSentToApplicant: true,
          };
          break;
        case AppointmentStatusName.OFFER_SENT:
          initialValues = {
            ...initialValues,
            isAppointmentPositive: true,
            isOfferSentToApplicant: true,
          };
          break;
        case AppointmentStatusName.APPOINTMENT_POSITIVE:
          initialValues = {
            ...initialValues,
            isAppointmentPositive: true,
          };
          break;
        case AppointmentStatusName.APPOINTMENT_NEGATIVE:
          initialValues = {
            ...initialValues,
            isAppointmentPositive: false,
          };
          break;

      }

      if (appointment.feedback && appointment.feedback.feedback !== undefined) {
        initialValues = {
          ...initialValues,
          appointmentComment: appointment.feedback.text,
          isApplicantCameToAppointment:
            appointment.feedback.feedback === AppointmentFeedbackName.WORKER_CAME,
        }
      }
    }

    return (
      <div>
        <ProfileHeader profile={application.user} workerRating={workerRating} />

        <hr className="u-hr-line" />
        <FeedbackForm
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          initialValuesInComponent={initialValues}
        />
      </div>
    );
  }
}

// $FlowFixMe
export default connect()(withTranslation()(Feedback));
