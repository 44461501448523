// @flow

import { type Match } from 'react-router-dom';
import {
  detail as detailRequest,
  allApplications as allApplicationsRequest,
  shortListedApplications as shortListedApplicationsRequest,
  shortListToggle as shortListToggleRequest,
} from '../../api/job';
import { Status } from '../../utils/apiState';
import { type JobDetail } from '../../types/jobDetail';
import { type AllApplications, type ShortListedApplications } from '../../types/applications';
import { type Appointment } from '../../types/appointment';
import { JobMapper } from '../../mappers/job';

import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from './index';

// Actions
const REQUEST = 'jobDetail/REQUEST';
const SUCCESS = 'jobDetail/SUCCESS';
const SUCCESS_ALL_APPLICANTIONS = 'jobDetail/SUCCESS_ALL_APPLICANTIONS';
const SUCCESS_SHORTLIST_APPLICANTIONS = 'jobDetail/SUCCESS_SHORTLIST_APPLICANTIONS';
const FAILED = 'jobDetail/FAILED';
const SHORTLIST_TOGGLE = 'jobDetail/SHORTLIST_TOGGLE';
const SEEN = 'jobDetail/SEEN';
const STORE_APPOINTMENTS = 'jobDetail/STORE_APPOINTMENTS';

// Action Creator Types
type RequestAction = {
  type: typeof REQUEST,
  jobId: number,
};

type SuccessAction = {
  type: typeof SUCCESS,
  data: JobDetail,
  jobId: number,
};

type FailedAction = {
  type: typeof FAILED,
  error: any,
  jobId: number,
};

type ShortListToggleAction = {
  type: typeof SHORTLIST_TOGGLE,
  jobId: number,
  workerId: number,
  applicationId:number,
  isShortListed: boolean,
};

type SeenAction = {
  type: typeof SEEN,
  jobId: number,
  applicationId: number,
};

type StoreAppointmentsAction = {
  type: typeof STORE_APPOINTMENTS,
  jobId: number,
  appointments: Array<Appointment>,
};

export type JobDetailActions =
  | RequestAction
  | SuccessAction
  | FailedAction
  | ShortListToggleAction
  | SeenAction
  | StoreAppointmentsAction;

// Action Creators
export const request = (jobId: number): RequestAction => ({
  type: REQUEST,
  jobId,
});

export const success = (data: JobDetail, jobId: number): SuccessAction => ({
  type: SUCCESS,
  data,
  jobId,
});

export const successAllApplicaitons = (data: AllApplications, jobId: number): SuccessAction => ({
  type: SUCCESS_ALL_APPLICANTIONS,
  data,
  jobId,
});

export const successShortListApplicaitons = (
  data: ShortListedApplications,
  jobId: number,
): SuccessAction => ({
  type: SUCCESS_SHORTLIST_APPLICANTIONS,
  data,
  jobId,
});

export const failed = (error: any, jobId: number): FailedAction => ({
  type: FAILED,
  error,
  jobId,
});

export const shortListToggle = (jobId: number, workerId: number, applicationId: number, isShortListed: boolean): ShortListToggleAction => ({
  type: SHORTLIST_TOGGLE,
  jobId,
  workerId,
  applicationId,
  isShortListed,
});

export const seen = (jobId: number, applicationId: number): SeenAction => ({
  type: SEEN,
  jobId,
  applicationId,
});

export const storeAppointments = (
  jobId: number,
  appointments: Array<Appointment>,
): StoreAppointmentsAction => ({
  type: STORE_APPOINTMENTS,
  jobId,
  appointments,
});

// Thunks
export const fetchThunk = (jobId: number): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  try {
    if (jobId === 0 || jobId === '0') {
      return;
    }
    const data = await detailRequest(jobId);
    dispatch(success(data, jobId));
  } catch (error) {
    dispatch(failed(error, jobId));
  }
};

export const loadThunk = (jobId: number): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  dispatch(request(jobId));

  await dispatch(fetchThunk(jobId));
};

export const fetchAllApplicationsThunk = (jobId: number, page: number): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  try {
    const data = await allApplicationsRequest(jobId, page);

    await dispatch(successAllApplicaitons(data, jobId));
  } catch (error) {
    dispatch(failed(error, jobId));
  }
};

export const fetchShortListedApplicationsThunk = (jobId: number, page: number): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  try {
    const data = await shortListedApplicationsRequest(jobId, page);

    await dispatch(successShortListApplicaitons(data, jobId));
  } catch (error) {
    dispatch(failed(error, jobId));
  }
};

export const shortListToggleThunk = (jobId: number, workerId: number,applicationId: number, isShortListed: boolean): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  try {
    dispatch(shortListToggle(jobId, workerId));
    await shortListToggleRequest(applicationId, isShortListed);
  } catch (err) {
    dispatch(shortListToggle(jobId, workerId));
  }
};

export const storeAppointmentsThunk = (
  jobId: number,
  appointments: Array<Appointment>,
): Function => async (dispatch: ReduxDispatch): Promise<*> => {
  try {
    dispatch(storeAppointments(jobId, appointments));
  } catch (err) {
    dispatch(storeAppointments(jobId, []));
  }
};

// Reducer
export type JobDetailItemState = {
  +status: StatusType,
  +data: JobDetail,
  +error: any,
};
export type JobDetailState = { [number]: JobDetailItemState, appointments?: Array<Appointment> };

const emptyItem = {
  job: JobMapper.fromAPIResponse({}),
  allApplications: [],
  rejectedApplicationCount: 0,
  shortListedApplications: [],
  allApplicationsPaginator: {
    current_page: 1,
    total_item_count: 20,
    page_count: 2,
  },
  shortListedApplicationsPaginator: {
    current_page: 1,
    total_item_count: 20,
    page_count: 2,
  },
};
const initialState = {};

export default function reducer(
  state: JobDetailState = initialState,
  action: JobDetailActions,
): JobDetailState {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        [action.jobId]: { status: Status.LOADING, data: emptyItem, error: null },
      };
    case SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const alreadyAllApplications = state[action.jobId].data.allApplications || [];
      // eslint-disable-next-line no-case-declarations
      const alreadyShortlistedApplications = state[action.jobId].data.shortListedApplications || [];
      return {
        ...state,
        [action.jobId]: {
          status: Status.LOADED,
          data: {
            ...action.data,
            allApplications: alreadyAllApplications.concat(action.data.allApplications),
            shortListedApplications: alreadyShortlistedApplications.concat(
              action.data.shortListedApplications,
            ),
          },
          error: null,
        },
      };
    case SUCCESS_ALL_APPLICANTIONS:
      // eslint-disable-next-line no-case-declarations
      const oldAllApplications = state[action.jobId].data.allApplications || [];
      console.log("oldALLAPPLICATIONSFROMACTION", oldAllApplications);
      console.log("oldALLAPPLICATIONSFROMACTION", action.jobId);
      return {
        ...state,
        [action.jobId]: {
          status: Status.LOADED,
          data: {
            ...state[action.jobId].data,
            allApplications: oldAllApplications,
            allApplicationsPaginator: action.data.paginator,
          },
          error: null,
        },
      };
    case SUCCESS_SHORTLIST_APPLICANTIONS:
      // eslint-disable-next-line no-case-declarations
      const oldShortlistedApplications = state[action.jobId].data.shortListedApplications || [];
      return {
        ...state,
        [action.jobId]: {
          status: Status.LOADED,
          data: {
            ...state[action.jobId].data,
            shortListedApplications: oldShortlistedApplications.concat(
              action.data.shortListedApplications,
            ),
            shortListedApplicationsPaginator: action.data.paginator,
          },
          error: null,
        },
      };
    case FAILED:
      return {
        ...state,
        [action.jobId]: {
          status: Status.FAILED,
          data: emptyItem,
          error: action.error,
        },
      };
    case SHORTLIST_TOGGLE: {
      const current = state[action.jobId] ? state[action.jobId] : state[0];
      const { workerId } = action;
      //TODO: applications larda undefined var neden oldugu bul undefined i kaldir
      // filterlamak yerine
      const allApplications = current.data.allApplications.filter((x) => x).map(application => {
        if (application.user.id === workerId) {
          return { ...application, isShortListed: !application.isShortListed };
        }

        return application;
      });
      const shortListedApplications = allApplications.filter(
        application => application.isShortListed === true,
      );

      return {
        ...state,
        [state[action.jobId] ? action.jobId : 0]: {
          ...current,
          data: { ...current.data, allApplications, shortListedApplications },
        },
      };
    }
    case SEEN: {
      const { applicationId } = action;
      const current = state[action.jobId];
      const allApplications = current.data.allApplications.filter(x => x).map(application => {
        if (application.id === applicationId) {
          return { ...application, isSeen: true };
        }

        return application;
      });

      return {
        ...state,
        [action.jobId]: {
          ...current,
          data: { ...current.data, allApplications },
        },
      };
    }
    case STORE_APPOINTMENTS: {
      const { appointments } = action;

      const current = state[action.jobId];

      return {
        ...state,
        [action.jobId]: {
          ...current,
          data: {
            ...current.data,
            appointments,
          },
        },
      };
    }
    default:
      return state;
  }
}

// Selectors
export const getDetailById = (
  state: ReduxState,
  { jobId }: { jobId: number },
): JobDetailItemState => {
  const itemState = state.jobDetail[jobId];

  if (!itemState) {
    return {
      status: Status.INIT,
      data: emptyItem,
      error: null,
    };
  }

  return itemState;
};
