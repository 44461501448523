// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withTranslation } from 'react-i18next';
import { shortListToggleThunk } from '../redux/modules/applications';
import Button from '../components/ui/Button';

import Confirm from '../components/elements/Confirm';

import { type Application } from '../types/application';
import { type ReduxDispatch } from '../types/redux';
import { themePalette } from '../theme/index';

type Props = {
  application: Application,
  shortListToggleThunk: (applicationId: number, isShortListed: boolean) => void,
  t: Function,
};

class ShortListToggle extends PureComponent<Props> {
  props: Props;

  confirmDialog: any;

  handleToggle = (event: Event) => {
    event.stopPropagation();
    if (this.props.application.isShortListed) {
      this.confirmDialog.show(this.props.t('jobDetail.shortListExitQuestion'), {
        onConfirm: () => {
          this.props.shortListToggleThunk(
            this.props.application.id,
            this.props.application.isShortListed
          );
        },
      });
    } else {
      this.props.shortListToggleThunk(
        this.props.application.id,
        this.props.application.isShortListed
      );
    }
  };

  render() {
    const { application, t } = this.props;

    return (
      <span>
        <Button
          small
          primary={application.isShortListed}
          outline={!application.isShortListed}
          className="u-full-width"
          onClick={this.handleToggle}
          style={{
            backgroundColor: application.isShortListed ? themePalette.accent : themePalette.success,
            color: '#fff',
            boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
          }}
        >
          {application.isShortListed
            ? t('jobDetail.removeFromShortlist')
            : t('jobDetail.addToShortList')}
        </Button>
        <Confirm
          ref={ref => {
            this.confirmDialog = ref;
          }}
          confirmLabel={t('common.glossary.continue')}
        />
      </span>
    );
  }
}

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ shortListToggleThunk }, dispatch);

// $FlowFixMe
export default connect(null, mapDispatchToProps)(withTranslation()(ShortListToggle));
