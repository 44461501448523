// @flow

import {
  list as listRequest,
  remove as deleteRequest,
  create as createRequest,
  edit as editRequest,
  getCompaniesHasActiveJob
} from '../../api/branch';
import { Status, type StatusType } from '../../utils/apiState';
import { CompanyMapper } from '../../mappers/user';

import { type ReduxDispatch } from '../../types/redux';
import { type Company } from '../../types/user';

// Actions
const REQUEST = 'branches/REQUEST';
const SUCCESS = 'branches/SUCCESS';
const FAILED = 'branches/FAILED';
const DELETE_ITEM = 'branches/DELETE_ITEM';
const CREATE_ITEM = 'branches/CREATE_ITEM';
const EDIT_ITEM = 'branches/EDIT_ITEM';
const SUCCESS_COMPANY = 'branches/SUCCESS_COMPANY';

// Action Creator Types
type RequestAction = {
  type: typeof REQUEST,
};

type SuccessAction = {
  type: typeof SUCCESS,
  branches: Array<Company>,
};

type FailedAction = {
  type: typeof FAILED,
  error: any,
};

type DeleteItemAction = {
  type: typeof DELETE_ITEM,
  id: number,
};

type CreateItemAction = {
  type: typeof CREATE_ITEM,
  item: Company,
};

type EditItemAction = {
  type: typeof EDIT_ITEM,
  item: Company,
};

export type BranchesActions =
  | RequestAction
  | SuccessAction
  | FailedAction
  | DeleteItemAction
  | CreateItemAction
  | EditItemAction;

// Action Creators
export const request = (): RequestAction => ({
  type: REQUEST,
});

export const success = (branches: Array<Company>): SuccessAction => ({
  type: SUCCESS,
  branches,
});

export const failed = (error: any): FailedAction => ({
  type: FAILED,
  error,
});

export const deleteItem = (id: number): DeleteItemAction => ({
  type: DELETE_ITEM,
  id,
});

export const createItem = (item: Company): CreateItemAction => ({
  type: CREATE_ITEM,
  item,
});

export const editItem = (item: Company): EditItemAction => ({
  type: EDIT_ITEM,
  item,
});

export const getCompanies = (companies: Array<any>): EditItemAction => ({
  type: SUCCESS_COMPANY,
  companies,
});

// Thunks
export const loadThunk = (): Function => async (dispatch: ReduxDispatch): Promise<*> => {
  dispatch(request());

  try {
    const branches = await listRequest();
    dispatch(success(branches));
  } catch (error) {
    dispatch(failed(error));
  }
};

export const deleteItemThunk = (id: number): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  try {
    dispatch(deleteItem(id));
    await deleteRequest(id);
  } catch (err) {
    loadThunk();
  }
};

export const createItemThunk = (data: Company): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  const formData = CompanyMapper.toAPIRequest(data);

  const item = await createRequest(formData);

  dispatch(createItem(item));
};

export const editItemThunk = (id: number, data: Company): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  const formData = CompanyMapper.toAPIRequest(data);

  const item = await editRequest(id, formData);

  dispatch(editItem(item));
};

export const getCompaniesThunk = (): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  const companies = await getCompaniesHasActiveJob();

  dispatch(getCompanies(companies));
};

// Reducer
export type BranchesState = {
  +status: StatusType,
  +data: Array<Company>,
  +error: any,
};

const initialState = {
  status: Status.INIT,
  data: [],
  companies: [],
  error: null,
};

export default function reducer(
  state: BranchesState = initialState,
  action: BranchesActions,
): BranchesState {
  switch (action.type) {
    case REQUEST:
      return { ...state, status: Status.LOADING };
    case SUCCESS:
      return { ...state, status: Status.LOADED, data: action.branches };
    case FAILED:
      return { ...state, status: Status.FAILED, data: [], error: action.error };
    case DELETE_ITEM: {
      const { id } = action;
      return { ...state, data: state.data.filter(item => item.id !== id) };
    }
    case CREATE_ITEM:
      return { ...state, data: [...state.data, action.item] };
    case EDIT_ITEM: {
      const actionItem = action.item;
      const newData = state.data.map((item: Company) => {
        if (item.id === actionItem.id) {
          return actionItem;
        }

        return item;
      });

      return { ...state, data: newData };
    }
    case SUCCESS_COMPANY :
    return {
      ...state,
      companies: action.companies,
    };
    default:
      return state;
  }
}
