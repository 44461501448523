// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, getFormSubmitErrors } from 'redux-form';
import debounce from 'lodash.debounce';

import { withTranslation } from 'react-i18next';
import { UserGrantMap } from '../enums/userGrant';
import AuthService from '../services/auth';

import { type AuthenticatedUser } from '../types/user';

import * as jobAPI from '../api/job';

import { ContactOptionList } from '../enums/contactOption';
import { UserTypeMap } from '../enums/userType';

import FormField from '../components/elements/FormField';
import FormFieldSelect from '../components/elements/FormFieldSelect';
import FormSearchableSelect from '../components/elements/FormSearchableSelect';
import FormFieldCheck from '../components/elements/FormFieldCheck';
import FormFieldTextarea from '../components/elements/FormFieldTextarea';

import required from '../validators/required';
import integer from '../validators/integer';
import minNumber from '../validators/minNumber';
import maxNumber from '../validators/maxNumber';
import phone from '../validators/phone';
import minLength from '../validators/minLength';

import UploadDialog from '../components/abstract/UploadDialog';
import ImageCropModal from '../components/elements/ImageCropModal';
import Row from '../components/layout/Row';
import Col from '../components/layout/Col';
import Button from '../components/ui/Button';
import CircleSlice8Icon from '../components/elements/Icon/circleSlice8Icon'
import CircleOutlineIcon from '../components/elements/Icon/circleOutlineIcon'

import { type CategoriesState } from '../redux/modules/categories';
import { type BranchesState } from '../redux/modules/branches';
import { type ReduxState } from '../redux/modules';
import { themePalette } from '../theme/index';
import salaryExpectation from '../validators/salaryExpectation';
import { CompanyLocation } from '../types/companyLocation';
import AddCompanyLocationModal from '../routes/jobEdit/AddCompanyLocationModal';
import Label from '../components/ui/Label';
import Icon from '../components/elements/Icon';
import { LanguageLevel, Language, LanguageLevelValue } from '../types/language';
import { Tag } from '../types/tag';
import apiNew from '../utils/apiNew';
import AddTagModal from '../routes/jobEdit/AddTagModal';

type Props = {
  handleSubmit: Function,
  change: (field: string, value: any) => void,
  submitting: boolean,
  categories: CategoriesState,
  branches: BranchesState,
  cover: string,
  latitude: number,
  longitude: number,
  centerLatitude: number,
  centerLongitude: number,
  zoom: number,
  isEdit: boolean,
  formViewData: any,
  contactOption: string,
  autoShortlistEnabled?: boolean,
  currentUser: AuthenticatedUser,
  t: Function,
};

type State = {
  isCoverModelOpened: boolean,
  cropModalImage: File | '',
  googleMapsInitialized: Boolean,
  companyLocations: CompanyLocation[],
  selectedCompanyLocations: number[],
  refetchCompanyLocations: number,
  addCompanyLocationModal: Boolean,
  addTagModal: Boolean,
  languageLevels: LanguageLevel[],
  languages: Language[],
  tags: Tag[];
  selectedTags: number[];
};

const TIMES = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
]

const HOURS = [
  "0.5",
  "1",
  "1.5",
  "2",
  "2.5",
  "3",
  "3.5",
  "4",
  "4.5",
  "5",
  "5.5",
  "6",
  "6.5",
  "7",
  "7.5",
  "8",
  "8.5",
  "9",
  "9.5",
  "10",
  "10.5",
  "11",
  "11.5",
  "12",
  "12.5",
  "13",
  "13.5",
  "14",
  "14.5",
  "15",
  "15.5",
  "16",
  "16.5",
  "17",
  "17.5",
  "18",
  "18.5",
  "19",
  "19.5",
  "20",
  "20.5",
  "21",
  "21.5",
  "22",
  "22.5",
  "23",
  "23.5",
  "24"
]

const DAYS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7"
]

const WEEK = [
  "weekend",
  "weekday"
]

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

class JobForm extends PureComponent<Props, State> {
  props: Props;

  state: State = {
    isCoverModelOpened: false,
    cropModalImage: '',
    googleMapsInitialized: false,
    companyLocations: [],
    selectedCompanyLocations: [],
    refetchCompanyLocations: 0,
    addCompanyLocationModal: false,
    addTagModal: false,
    languageLevels: [],
    languages: [],
    tags: [],
    selectedTags: [],
  };

  componentDidMount() {
    this.loadInitialData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.addCompanyLocationModal && !this.state.addCompanyLocationModal) ||
      (prevState.addTagModal && !this.state.addTagModal)
    ) {
      this.loadInitialData();
    }
  }

  loadInitialData = () => {
    Promise.all([
      jobAPI.getTagsByUser(),
      jobAPI.getLanguages(),
      jobAPI.getCompanyLocations(),
    ])
      .then(([tags, languages, companyLocations]) => {
        this.setState({ tags, languages, companyLocations });
        const { change, initialValues } = this.props;

        if (initialValues) {
          if (initialValues.selectedCompanyLocations) {
            change('selectedCompanyLocations', initialValues.selectedCompanyLocations);
            this.setState({ selectedCompanyLocations: initialValues.selectedCompanyLocations });
          }
          if (initialValues.selectedTags) {
            change('selectedTags', initialValues.selectedTags);
            this.setState({ selectedTags: initialValues.selectedTags });
          }
          if (initialValues.languageLevels) {
            initialValues.languageLevels.forEach((languageLevel, index) => {
              change(`language-${index}`, languageLevel.languageId);
              change(`languageLevel-${index}`, languageLevel.minLanguageLevel);
            });

            change('languageLevels', initialValues.languageLevels);
            this.setState({ languageLevels: initialValues.languageLevels });
          }
        }
      })
      .catch(e => {
        console.error('Error loading data:', e);
      })
      .finally(() => {
        console.log("loading false");
      });
  };

  handleCoverClick = (file: File) => {
    this.setState({ cropModalImage: file }, () => {
      this.openImageCropModal();
    });
  };

  openImageCropModal = () => {
    this.setState({ isCoverModelOpened: true });
  };

  closeImageCropModal = () => {
    this.setState({ isCoverModelOpened: false });
  };

  handleCroppedImage = (image: HTMLCanvasElement) => {
    this.props.change('cover', image.toDataURL('image/jpeg'));
    this.closeImageCropModal();
  };

  closeAddCompanyLocationModal = () => {
    this.setState({ addCompanyLocationModal: false });
  }

  openAddCompanyLocationModal = () => {
    this.setState({ addCompanyLocationModal: true });
  }

  closeAddTagModal = () => {
    this.setState({ addTagModal: false });
  }

  openAddTagModal = () => {
    this.setState({ addTagModal: true });
  }

  handleCompanyLocationIconClick = (companyLocationId: number) => {
    this.setState((prevState) => {
      const { selectedCompanyLocations } = prevState;
      const updatedSelectedCompanyLocations = selectedCompanyLocations.includes(companyLocationId)
        ? selectedCompanyLocations.filter(id => id !== companyLocationId)
        : [...selectedCompanyLocations, companyLocationId];

      this.props.change('selectedCompanyLocations', updatedSelectedCompanyLocations); // Update Redux form value

      return { selectedCompanyLocations: updatedSelectedCompanyLocations };
    });
  };

  languagesOnChange = (index: number, languageId: number) => {
    this.setState((prevState) => {
      const updatedLanguageLevels = [...prevState.languageLevels];
      updatedLanguageLevels[index] = {
        ...updatedLanguageLevels[index],
        languageId,
      };

      this.props.change('languageLevels', updatedLanguageLevels); // Update Redux form value

      return { languageLevels: updatedLanguageLevels };
    });
  };

  languagesLevelValueOnChange = (index: number, levelValue: LanguageLevelValue) => {
    this.setState((prevState) => {
      const updatedLanguageLevels = [...prevState.languageLevels];
      updatedLanguageLevels[index] = {
        ...updatedLanguageLevels[index],
        minLanguageLevel: levelValue,
      };

      this.props.change('languageLevels', updatedLanguageLevels); // Update Redux form value

      return { languageLevels: updatedLanguageLevels };
    });
  };

  languagesOnDelete = (index: number) => {
    this.setState((prevState) => {
      const updatedLanguageLevels = [...prevState.languageLevels];
      updatedLanguageLevels.splice(index, 1);

      this.props.change('languageLevels', updatedLanguageLevels); // Update Redux form value

      return { languageLevels: updatedLanguageLevels };
    });

    this.props.change(`language-${index}`, null);
    this.props.change(`languageLevel-${index}`, null);
  };

  addLanguageLevel = () => {
    this.setState((prevState) => {
      const newLanguageLevel: LanguageLevel = {
        languageId: 0,
        minLanguageLevel: 0,
      };

      const updatedLanguageLevels = [...prevState.languageLevels, newLanguageLevel];

      this.props.change('languageLevels', updatedLanguageLevels); // Update Redux form value

      return { languageLevels: updatedLanguageLevels };
    });
  };


  handleTagClick = (tagId: number) => {
    this.setState((prevState) => {
      const selectedTags = prevState.selectedTags.includes(tagId)
        ? prevState.selectedTags.filter(id => id !== tagId)
        : [...prevState.selectedTags, tagId];

      this.props.change('selectedTags', selectedTags); // Update the hidden field
      return { selectedTags };
    });
  };

  loadPositionOptions = debounce((value: string, cb: any) => {
    jobAPI.positions(value).then(positions => {
      const options = positions.map(position => ({ value: position.id, label: position.name }));
      cb(options);
    });
  }, 200);

  render() {
    const {
      handleSubmit,
      change,
      submitting,
      cover,
      categories,
      branches,
      contactOption,
      isEdit,
      formViewData,
      autoShortlistEnabled,
      currentUser,
      t,
      submitErrors
    } = this.props;

    const {
      companyLocations,
      selectedCompanyLocations,
      languages,
      languageLevels,
      tags,
      selectedTags
    } = this.state;

    const canAutoShortlist = currentUser.user.userType !== UserTypeMap.COMPANY;
    const { userLanguage } = currentUser.user;

    return (
    <div>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={8} className="col--offset-md-2 u-gap-bottom">
              <div className="u-gap-bottom">
                <UploadDialog accept=".jpg,.png" onFileReady={this.handleCoverClick}>
                  {!cover && (
                    <span className="c-image-upload__content">
                      <svg
                        width="58"
                        height="46"
                        viewBox="0 0 58 46"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="#7C90A5">
                          <path d="M57 0H1C.448 0 0 .447 0 1v44c0 .553.448 1 1 1h56c.552 0 1-.447 1-1V1c0-.553-.448-1-1-1zm-1 44H2V2h54v42z" />
                          <path d="M16 22.138c3.07 0 5.57-2.498 5.57-5.568 0-3.072-2.5-5.57-5.57-5.57-3.07 0-5.57 2.498-5.57 5.57 0 3.07 2.5 5.568 5.57 5.568zM16 13c1.968 0 3.57 1.602 3.57 3.57 0 1.966-1.602 3.568-3.57 3.568-1.968 0-3.57-1.6-3.57-3.568 0-1.967 1.602-3.57 3.57-3.57zM7 40c.234 0 .47-.082.66-.25l16.313-14.36 10.302 10.3c.39.39 1.023.39 1.414 0 .39-.39.39-1.023 0-1.414L30.88 29.47l9.18-10.055 11.262 10.323c.407.373 1.04.345 1.413-.062s.346-1.04-.062-1.413l-12-11c-.196-.18-.457-.268-.72-.262-.265.013-.515.13-.694.326l-9.793 10.727-4.743-4.743c-.374-.373-.972-.392-1.368-.044L6.34 38.25c-.416.364-.456.996-.09 1.41.197.226.473.34.75.34z" />
                        </g>
                      </svg>
                      <span className="u-gap-top">{t('jobs.jobForm.uploadImage')}</span>
                    </span>
                  )}
                  {cover && (
                    <img
                      className="c-image-upload__content-img"
                      src={cover}
                      alt={t('jobs.jobForm.jobImage')}
                    />
                  )}
                </UploadDialog>
              </div>
              {isEdit && (
                <Field
                  name="status"
                  component={FormFieldSelect}
                  label={t('jobs.jobForm.status.label')}
                  validate={[required(t('jobs.jobForm.status.required'))]}
                  props={{
                    options: t('constants.jobStatusList').map(status => ({
                      id: status.key,
                      name: status.value,
                    })),
                  }}
                />
              )}
              {AuthService.isGranted(UserGrantMap.HR_GRANT) && (
                <Field
                  name="user"
                  component={FormFieldSelect}
                  label={t('jobs.jobForm.user.label')}
                  validate={[required(t('jobs.jobForm.user.required'))]}
                  props={{
                    options: branches.data.map(branch => ({
                      id: branch.id,
                      name: branch.companyName,
                    })),
                  }}
                />
              )}
              <Field
                name="title"
                type="text"
                component={FormField}
                label={t('jobs.jobForm.title.label')}
                validate={[required(t('jobs.jobForm.title.required'))]}
              />
              <Field
                name="position"
                component={FormSearchableSelect}
                label={t('jobs.jobForm.position.label')}
                validate={[required(t('jobs.jobForm.position.required'))]}
                props={{
                  placeholder: t('jobs.jobForm.position.placeholder'),
                  loadOptions: this.loadPositionOptions,
                }}
              />
              <Field
                name="category"
                component={FormFieldSelect}
                label={t('jobs.jobForm.category.label')}
                validate={[required(t('jobs.jobForm.category.required'))]}
                props={{
                  options: categories.data,
                }}
              />
              <Field
                name="description"
                component={FormFieldTextarea}
                label={t('jobs.jobForm.description.label')}
                validate={[required(t('jobs.jobForm.description.required'))]}
                props={{
                  rows: 5,
                  verticalResize: true,
                }}
              />
              <Field
                name="contactOption"
                component={FormFieldSelect}
                label={t('jobs.jobForm.contactOption.label')}
                validate={[required(t('jobs.jobForm.contactOption.required'))]}
                props={{
                  options: t('constants.contact'),
                }}
              />

              {canAutoShortlist && (
                <Field
                  name="autoShortlistEnabled"
                  component={FormFieldSelect}
                  label={t('jobs.jobForm.autoShortlistEnabled.label')}
                  normalize={(value: string) => String(value) === 'true'}
                  props={{
                    options: t('constants.autoShortlistEnabled'),
                  }}
                />
              )}
              {canAutoShortlist && !!autoShortlistEnabled && (
                <Field
                  name="autoShortlistThreshold"
                  type="number"
                  component={FormField}
                  label={t('jobs.jobForm.autoShortlistThreshold.label')}
                  validate={[
                    integer(t('common.validations.integer')),
                    minNumber(
                      50,
                      t('jobs.jobForm.autoShortlistThreshold.minNumber', { count: 50 }),
                    ),
                    maxNumber(
                      100,
                      t('jobs.jobForm.autoShortlistThreshold.maxNumber', { count: 100 }),
                    ),
                  ]}
                />
              )}

              {contactOption === ContactOptionList.PHONE && (
                <Field
                  name="contactPhone"
                  type="text"
                  component={FormField}
                  label={t('jobs.jobForm.contactPhone.label')}
                  validate={[
                    required(t('jobs.jobForm.contactPhone.required')),
                    phone(t('common.validations.phone')),
                  ]}
                />
              )}
              <Field
                name="workTimePreferences"
                component={FormFieldCheck}
                label={t('jobs.jobForm.workTimePreferences.label')}
                props={{
                  options: Object.entries(t('constants.workTimeMap')).map(([key, value]) => ({
                    value: key,
                    text: value,
                  })),
                }}
              />
              <Field
                name="minExperienceDuration"
                component={FormFieldSelect}
                label={t('jobs.jobForm.minExperienceDuration.label')}
                props={{
                  options: t('constants.experienceDurationList').map(item => ({
                    id: item.key,
                    name: item.value,
                  })),
                }}
              />
              <Row>
                <Col md={6}>
                  <Field
                    className="u-clear-gap-bottom"
                    name="expectedSalaryMin"
                    type="number"
                    component={FormField}
                    label={t('jobs.jobForm.expectedSalaryMin.label')}
                    validate={[salaryExpectation(t('jobs.jobForm.expectedSalaryValidate'))]}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    className="u-clear-gap-bottom"
                    name="expectedSalaryMax"
                    type="number"
                    component={FormField}
                    label={t('jobs.jobForm.expectedSalaryMax.label')}
                    validate={[salaryExpectation(t('jobs.jobForm.expectedSalaryValidate'))]}
                  />
                </Col>
              </Row>
              <p className="u-gap-bottom">
                <small>{t('jobs.jobForm.infoText')}</small>
              </p>
              <Field
                name="gender"
                component={FormFieldSelect}
                label={t('jobs.jobForm.gender.label')}
                props={{
                  options: t('constants.gender'),
                }}
              />
              {formViewData &&
                formViewData.militaryRequirements &&
                formViewData.militaryRequirements.isActive ? (
                <Field
                  name="militaryRequirements"
                  component={FormFieldCheck}
                  label={t('jobs.jobForm.militaryRequirements.label')}
                  props={{
                    options: Object.entries(t('constants.militaryStatusMap')).map(
                      ([key, value]) => ({
                        value: key,
                        text: value,
                      }),
                    ),
                  }}
                />
              ) : null}
              <Row>
                <Col md={6}>
                  <Field
                    name="birthDateMin"
                    type="text"
                    component={FormField}
                    label={t('jobs.jobForm.birthDateMin.label')}
                    validate={[
                      integer(t('common.validations.integer')),
                      minNumber(18, t('jobs.jobForm.birthDateMin.minNumber', { age: 18 })),
                    ]}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    name="birthDateMax"
                    type="text"
                    component={FormField}
                    label={t('jobs.jobForm.birthDateMax.label')}
                    validate={[
                      integer(t('common.validations.integer')),
                      minNumber(18, t('jobs.jobForm.birthDateMax.minNumber', { age: 18 })),
                    ]}
                  />
                </Col>
              </Row>
              <Field
                name="minEducationStatus"
                component={FormFieldSelect}
                label={t('jobs.jobForm.minEducationStatus.label')}
                props={{
                  options: Object.entries(t('constants.educationMap')).map(([key, value]) => ({
                    id: key,
                    name: value,
                  })),
                }}
              />
              {currentUser.user.peopleBoxCredential &&
                currentUser.user.peopleBoxCredential.status && (
                  <Field
                    name="peopleBoxProjectId"
                    type="text"
                    component={FormField}
                    label={t('jobs.jobForm.peopleBoxProjectId.label')}
                    validate={[
                      integer(t('common.validations.integer')),
                    ]}
                  />
              )}
               <Row style={{ marginInline: "0" }}>
                <Col className="c-form-group c-form-group--floating">
                  <Label htmlFor={"workingShift-label"}>{t("jobs.jobForm.workingShift")}</Label>
                </Col>
              </Row>
              <Field
                name="workStartsAt"
                component={FormFieldSelect}
                label={t('jobs.jobForm.workStartsAt.label')}
                validate={[required(t('jobs.jobForm.workStartsAt.required'))]}
                props={{
                  options: TIMES.map((time) => ({
                    id: time,
                    name: time,
                  })),
                }}
              />
              <Field
                name="dailyTotalWorkHour"
                component={FormFieldSelect}
                label={t('jobs.jobForm.dailyTotalWorkHour.label')}
                validate={[required(t('jobs.jobForm.dailyTotalWorkHour.required'))]}
                props={{
                  options: HOURS.map((hour) => ({
                    id: hour,
                    name: hour,
                  })),
                }}
              />
              <Field
                name="weeklyOffDayCount"
                component={FormFieldSelect}
                label={t('jobs.jobForm.weeklyOffDayCount.label')}
                validate={[required(t('jobs.jobForm.weeklyOffDayCount.required'))]}
                props={{
                  options: DAYS.map((day) => ({
                    id: day,
                    name: day,
                  })),
                }}
              />
              <Field
                name="offTime"
                component={FormFieldSelect}
                label={t('jobs.jobForm.offTime.label')}
                validate={[required(t('jobs.jobForm.offTime.required'))]}
                props={{
                  options: WEEK.map((week) => ({
                    id: week,
                    name: t(`jobs.jobForm.week.${week}`),
                  })),
                }}
              />
              <Field name="selectedCompanyLocations" component="input" type="hidden" />
              <Field name="selectedTags" component="input" type="hidden" />
              <Field name="languageLevels" component="input" type="hidden" />
            </Col>
            <Col md={8} className="col--offset-md-2 u-gap-bottom">
              <Row style={{ marginInline: "0" }}>
                <Col sm={10} className="c-form-group c-form-group--floating">
                  <Label htmlFor={"locations-label"}>{t("Locations")}</Label>
                </Col>
                <Col sm={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    primary
                    small
                    onClick={this.openAddCompanyLocationModal}
                    style={{
                      backgroundColor: themePalette.primary_color,
                      boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                    }}
                  >
                    <Icon name="add" className="u-gap-right-small" />
                  </Button>
                </Col>
              </Row>
              {submitErrors && submitErrors.selectedCompanyLocations && (
                <span className="error-message" style={{ color: 'red' }}>
                  {submitErrors.selectedCompanyLocations}
                </span>
              )}
              <Row style={{ flexDirection: "column", marginInline: "0" }}>
                {companyLocations.map((companyLocation) => (
                  <Row key={`company-location-${companyLocation.id}`} style={{ marginBottom: "2em", marginInline: "0" }}>
                    <Col sm={1} style={{ display:"flex", justifyContent:"space-between", alignItems:"center" }}>
                      <a onClick={() => this.handleCompanyLocationIconClick(companyLocation.id)}>
                        {selectedCompanyLocations.includes(companyLocation.id)
                            ? (
                              <CircleSlice8Icon style={{
                                height: "30px",
                                width: "30px",
                              }} />
                            )
                            : (
                              <CircleOutlineIcon style={{
                                height: "30px",
                                width: "30px",
                              }} />
                            )}
                      </a>
                    </Col>
                    <Col sm={10}>
                      <div className="d-flex flex-column justify-content-center">
                        <h3>{companyLocation.name}</h3>
                        <a>{companyLocation.address}</a>
                      </div>
                    </Col>
                  </Row>
                ))}
              </Row>
              <Row style={{ marginInline: "0" }}>
                <Col sm={10} className="c-form-group c-form-group--floating" >
                  <Label htmlFor={"languages-label"}>{t("Languages")}</Label>
                </Col>
                <Col sm={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    primary
                    small
                    onClick={this.addLanguageLevel}
                    style={{
                      backgroundColor: themePalette.primary_color,
                      boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                    }}
                  >
                    <Icon name="add" className="u-gap-right-small" />
                  </Button>
                </Col>
              </Row>
              {submitErrors && submitErrors.languageLevels && (
                <span className="error-message" style={{ color: 'red' }}>
                  {submitErrors.languageLevels}
                </span>
              )}
              <Row style={{ flexDirection: "column", flexWrap: "nowrap", marginBottom: "2em", marginInline: "0" }}>
                {languageLevels.length > 0 ? (
                  languageLevels.map((languageLevel, index) => (
                    <Row key={`languageLevel-${index}`} style={{ marginTop: "1em", marginInline: "0" }}>
                      <Col sm={6}>
                        <Field
                          name={`language-${index}`}
                          component={FormFieldSelect}
                          label={t('Language')}
                          props={{
                            options: languages.map((language) => ({
                              id: language.id,
                              name: t(language.languageName),
                            })),
                          }}
                          value={languageLevel.languageId}
                          onChange={(e) => this.languagesOnChange(index, parseInt(e["0"]))}
                        />
                      </Col>
                      <Col sm={5}>
                        <Field
                          name={`languageLevel-${index}`}
                          component={FormFieldSelect}
                          label={t('Language Level')}
                          props={{
                            options: Object.keys(LanguageLevelValue)
                              .filter((value) => isNaN(Number(value)))
                              .map((key) => ({
                                id: LanguageLevelValue[key],
                                name: t(key),
                              }))
                          }}
                          value={languageLevel.minLanguageLevel}
                          onChange={(e) => this.languagesLevelValueOnChange(index, parseInt(e["0"]))}
                        />
                      </Col>
                      <Col sm={1}>
                        <Button onClick={() => this.languagesOnDelete(index)}>
                          <a>
                            X
                          </a>
                        </Button>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <Row>
                    <Col>
                      <p>{t("No Language Selected")}</p>
                    </Col>
                  </Row>
                )}
              </Row>
              <Row style={{ marginInline: "0" }}>
                <Col sm={10} className="c-form-group c-form-group--floating">
                  <Label htmlFor={"tags-label"}>{t("Additional Properties")}</Label>
                </Col>
                <Col sm={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    primary
                    small
                    onClick={this.openAddTagModal}
                    style={{
                      backgroundColor: themePalette.primary_color,
                      boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                    }}
                  >
                    <Icon name="add" className="u-gap-right-small" />
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginInline: "0" }}>
              {tags.map((tag) => (
                <Button
                  key={`tag-${tag.id}`}
                  onClick={() => this.handleTagClick(tag.id)}
                  style={{
                    margin: '1rem',
                    backgroundColor: selectedTags.includes(tag.id) ? themePalette.primary_color : "lightgray"
                  }}
                >
                  {t(tag.name)}
                </Button>
              ))}
              </Row>
            </Col>
          </Row>
          <div className="u-text-center">
            <Button
              type="submit"
              primary
              disabled={submitting}
              loading={submitting}
              style={{
                backgroundColor: themePalette.primary_color,
                boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
              }}
            >
              {isEdit ? t('jobs.jobForm.save') : t('jobs.jobForm.createJob')}
            </Button>
          </div>
        </form>

        <ImageCropModal
          isOpen={this.state.isCoverModelOpened}
          source={this.state.cropModalImage}
          onModalCloseRequested={this.closeImageCropModal}
          width={688}
          height={352}
          border={40}
          borderRadius={0}
          onImageReceive={this.handleCroppedImage}
        />

        {
          this.state.addCompanyLocationModal && (
            <AddCompanyLocationModal
              modalOpen={this.state.addCompanyLocationModal}
              closeModal={this.closeAddCompanyLocationModal}
              companyId={currentUser.id}
              language={userLanguage}
            />
          )
        }

        {
          this.state.addTagModal && (
            <AddTagModal
              modalOpen={this.state.addTagModal}
              closeModal={this.closeAddTagModal}
            />
          )
        }
      </div>
    );
  }
}

const Form = reduxForm({
  form: 'jobCreate',
})(JobForm);

const selector = formValueSelector('jobCreate');

// $FlowFixMe
export default connect((state: ReduxState) => ({
  submitErrors: getFormSubmitErrors('jobCreate')(state),
  cover: selector(state, 'cover'),
  latitude: selector(state, 'latitude'),
  longitude: selector(state, 'longitude'),
  centerLatitude: selector(state, 'centerLatitude'),
  centerLongitude: selector(state, 'centerLongitude'),
  zoom: selector(state, 'zoom'),
  contactOption: selector(state, 'contactOption'),
  autoShortlistEnabled: selector(state, 'autoShortlistEnabled'),
}))(withTranslation()(Form));
