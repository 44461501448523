// @flow

import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import NotificationService from './services/notification';

import AnonymousRoute from './components/abstract/AnonymousRoute';
import ProtectedRoute from './components/abstract/ProtectedRoute';
import MessageReceiver from './components/abstract/MessageReceiver';
import NotificationContainer from './components/abstract/notification/Container';

import { UserGrantMap } from './enums/userGrant';

import Profile from './routes/profile';
import Branches from './routes/branches';
import Jobs from './routes/jobs';
import JobCreate from './routes/jobCreate';
import JobDetail from './routes/jobDetail';
import JobEdit from './routes/jobEdit';
import Packages from './routes/packages';
import Register from './routes/register';

import Inbox from './routes/inbox';
import BranchesInbox from './routes/branchesInbox';
import Login from './routes/auth/Login';
import Logout from './routes/auth/Logout';
import Unauthorized from './routes/Unauthorized';
import UnexpectedError from './routes/UnexpectedError';
import NotFound from './routes/NotFound';
import ResetPassword from './routes/resetPassword';
import ForgetPassword from './routes/forgetPassword';
import PaymentResult from './routes/paymentResult';
import Payment from './routes/packages/payment';
import SMSVerify from './routes/smsVerify';
import Basket from './routes/basket';
import Card from './routes/card';
import Iyzico3DSecure from './routes/card/Iyzico3DSecure';
import Applications from './routes/applications';

const App = () => {
  const { t } = useTranslation();
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    const currentAppTheme = { title: t('layout.header.title'), icoName: '/favicon.ico' };
    document.title = currentAppTheme.title;
    link.href = window.location.origin + currentAppTheme.icoName;
  }, []);

  return (
    <div>
      <MessageReceiver />
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          level={UserGrantMap.BRANCH_GRANT}
          component={() => <Redirect to="/jobs" />}
        />

        <ProtectedRoute path="/profile" level={UserGrantMap.BRANCH_GRANT} component={Profile} />
        <ProtectedRoute exact path="/branches" level={UserGrantMap.HR_GRANT} component={Branches} />
        <ProtectedRoute exact path="/jobs" level={UserGrantMap.BRANCH_GRANT} component={Jobs} />
        <ProtectedRoute
          exact
          path="/packages"
          level={UserGrantMap.COMPANY_GRANT}
          component={Packages}
        />

        <ProtectedRoute
          exact
          path="/packages/payment-result"
          level={UserGrantMap.COMPANY_GRANT}
          component={PaymentResult}
        />

        <ProtectedRoute
          exact
          path="/payment/basket"
          level={UserGrantMap.COMPANY_GRANT}
          component={Basket}
        />

        <ProtectedRoute
          exact
          path="/payment/card"
          level={UserGrantMap.COMPANY_GRANT}
          component={Card}
        />

        <ProtectedRoute
          exact
          path="/payment/3ds"
          level={UserGrantMap.COMPANY_GRANT}
          component={Iyzico3DSecure}
        />

        <ProtectedRoute
          exact
          path="/jobs/create"
          level={UserGrantMap.BRANCH_GRANT}
          component={JobCreate}
        />
        <ProtectedRoute
          exact
          path="/jobs/:branchId"
          level={UserGrantMap.BRANCH_GRANT}
          component={Jobs}
        />
        <ProtectedRoute
          exact
          path="/branches-inbox"
          level={UserGrantMap.HR_GRANT}
          component={BranchesInbox}
        />
        <ProtectedRoute path="/inbox" level={UserGrantMap.BRANCH_GRANT} component={Inbox} />

        <ProtectedRoute
          path="/jobs/detail/:jobId"
          level={UserGrantMap.BRANCH_GRANT}
          component={JobDetail}
        />

        <ProtectedRoute
          exact
          path="/jobs/edit/:jobId"
          level={UserGrantMap.BRANCH_GRANT}
          component={JobEdit}
        />

        <ProtectedRoute
          path="/applications"
          level={UserGrantMap.BRANCH_GRANT}
          component={Applications}
        />

        <AnonymousRoute exact path="/login" component={Login} />
        <AnonymousRoute exact path="/register" component={Register} />
        <AnonymousRoute exact path="/reset-password/:resetPasswordCode" component={ResetPassword} />
        <AnonymousRoute exact path="/forget-password" component={ForgetPassword} />
        <ProtectedRoute exact path="/logout" level={UserGrantMap.BRANCH_GRANT} component={Logout} />

        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route exact path="/error" component={UnexpectedError} />
        <Route exact path="/sms-verify" component={SMSVerify} />

        <Route component={NotFound} />
      </Switch>

      <NotificationContainer
        ref={notificationContainer => {
          if (notificationContainer) {
            NotificationService.setContainer(notificationContainer);
          }
        }}
      />
    </div>
  );
};

export default App;
