// @flow

export const AppointmentStatus = {
  WAITING: 'waiting',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  COMPLETED: 'completed',
};

export const AppointmentStatusName = {
  APPOINTMENT_ACCEPTED: 'appointment_accepted',
  APPOINTMENT_CANCELLED: 'appointment_cancelled',
  APPOINTMENT_COMPLETED: 'appointment_completed',
  APPOINTMENT_CREATED: 'appointment_created',
  APPOINTMENT_EXPIRED: 'appointment_expired',
  APPOINTMENT_NEGATIVE: 'appointment_negative',
  APPOINTMENT_NOT_ATTENDED: 'appointment_not_attended',
  APPOINTMENT_POSITIVE: 'appointment_positive',
  APPOINTMENT_POSTPONED: 'appointment_postponed',
  APPOINTMENT_REJECTED: 'appointment_rejected',
  APPOINTMENT_VERIFIED: 'appointment_verified',
  HIRED: 'hired',
  HIRED_CANCELLED: 'hired_cancelled',
  OFFER_ACCEPTED: 'offer_accepted',
  OFFER_REJECTED: 'offer_rejected',
  OFFER_SENT: 'offer_sent',
  RESIGN_IN_WARRANTY: 'resign_in_warranty',
};

export const AppointmentStatusDefault = AppointmentStatus.WAITING;
