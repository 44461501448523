// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, type Match, type RouterHistory, withRouter } from 'react-router-dom';

import { withTranslation, Trans } from 'react-i18next';
import { age as ageUtil } from '../../utils/age';
import { isLoading, isLoaded } from '../../utils/apiState';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import Infobox from '../../components/ui/Infobox';
import Icon from '../../components/elements/Icon';
import Loading from '../../components/elements/Loading';

import Profile from './profile';
import NoData from './NoData';

import { GenderMap } from '../../enums/gender';
import { MilitaryStatusMap } from '../../enums/militaryStatus';
import { EducationMap } from '../../enums/educationStatus';
import { EnglishLevelMap } from '../../enums/englishLevel';
import { ExperienceDurationList } from '../../enums/experienceDuration';

import { ApplicationState, loadApplicationThunk } from '../../redux/modules/applications';
import { type Application } from '../../types/application';
import { type Appointment } from '../../types/appointment';
import List from './list';

const tagValues = {
  gender: value => `Cinsiyet: ${GenderMap[value]}`,
  military: value => `Askerlik: ${MilitaryStatusMap[value]}`,
  age: value => `Yaş: ${value[0]}-${value[1]}`,
  education: value => `Eğitim: ${EducationMap[value]}`,
  englishLevel: value => `Askerlik: ${EnglishLevelMap[value]}`,
  distance: value => `Uzaklık: ${value}KM`,
  experience: value => {
    const exp = ExperienceDurationList.find(obj => obj.key === value);
    return exp ? `Tecürebe: ${exp.value}` : `Tecürebe: ${value}`;
  },
};

type Props = {
  applications: ApplicationState,
  match: Match,
  history: RouterHistory,
  onReachEnd: (page: number) => void,
  loadingMore: boolean,
  handleTabChange: (activeTab: string) => void,
  loadApplicationThunk: (id: number) => void,
  activeTab: 'all' | 'shortListed' | 'appointment',
  initialLoading: boolean,
};

type State = {
  filter: Object,
};

class Detail extends PureComponent<Props, State> {
  props: Props;

  state: State = {
    filter: {
      gender: [],
      military: [],
      age: [],
      englishLevel: [],
      education: [],
      distance: [],
      experience: [],
    },
  };

  openApplicationDetail = (application: Application) => {
    this.props.history.push(`${this.props.match.url}/${application.id}${this.props.history.location.search}`);
  };

  openAppointmentDetail = (appointment: Appointment) => {
    this.props.loadApplicationThunk(appointment.applicationId);
    this.props.history.push(`${this.props.match.url}/${appointment.applicationId}/appointment${this.props.history.location.search}`);
  };

  handleFilterApply = (filter: Object) => {
    this.setState({ filter });
  };

  handleRemove = (field: string, value: any, index: number) => {
    this.setState(state => {
      const obj = {};

      if (value) {
        const values = [...state.filter[field]];
        values.splice(index, 1);
        obj[field] = [...values];
      } else {
        obj[field] = [];
      }

      return { filter: { ...state.filter, ...obj } };
    });
  };

  filterApplication = (application: Application): boolean => {
    const { filter } = this.state;
    let passed = true;

    if (filter.gender && filter.gender.length) {
      passed = passed && filter.gender.includes(application.user.gender);
    }

    if (filter.military && filter.military.length) {
      passed = passed && filter.military.includes(application.user.military);
    }

    if (filter.age && filter.age.length) {
      const birthday = application.user.birthDate;
      const age = birthday ? ageUtil(birthday.toDate()) : 0;
      passed = passed && filter.age[0][0] <= age && age <= filter.age[0][1];
    }

    if (filter.englishLevel && filter.englishLevel.length) {
      passed = passed && filter.englishLevel.includes(application.user.english);
    }

    if (filter.education && filter.education.length) {
      passed = passed && filter.education.includes(application.user.education);
    }

    if (filter.distance && filter.distance.length) {
      passed = passed && application.user.distanceInt <= filter.distance[0];
    }

    if (filter.experience && filter.experience.length) {
      passed = passed && application.user.experienceMonth >= filter.experience[0];
    }

    return !!passed;
  };

  tagValueRender = (field: string, value: any) => tagValues[field](value);

  render() {
    const { applications, onReachEnd, loadingMore, handleTabChange, activeTab, initialLoading } = this.props;

    //TODO: add rejectedApplicationCount
    //const { rejectedApplicationCount } = jobDetail.data;
    const rejectedApplicationCount = 0;

    return (
      <div className="u-pad-ends-small">
        <Container>
          <Row>
            <Col md={12}>
              {!!rejectedApplicationCount && (
                <Infobox className="u-gap-bottom" type="warning">
                  <Icon name="info" className="c-infobox__icon" />
                  <Trans i18nKey="jobDetail.rejectedApplicationCount">
                    <strong>{{ rejectedApplicationCount }}</strong>
                    <strong></strong>
                  </Trans>
                </Infobox>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
            <Route path={`${this.props.match.url}/:applicationId`}>
                {({ match }: { match: Match }) => {
                  return (
                    <List
                      applications={applications}
                      onApplicationClick={this.openApplicationDetail}
                      onAppointmentClick={this.openAppointmentDetail}
                      activeApplicationId={match ? +match.params.applicationId : null}
                      onReachEnd={onReachEnd}
                      loadingMore={isLoading(applications)}
                      match={this.props.match}
                      history={this.props.history}
                      handleTabChange={handleTabChange}
                      activeTab={this.props.activeTab}
                    />
                  );
                }}
              </Route>
            </Col>


            <Col md={6}>
              <Route
                path={`${this.props.match.url}/:applicationId`}
                render={({ match }: { match: Match }) => {
                  return (
                    <Profile
                      match={match}
                      jobMatch={this.props.match}
                      history={this.props.history}
                      activeTab={this.props.activeTab}
                    />
                  );
                }}
              />
            </Col>

          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators(
    {
      loadApplicationThunk,
    },
    dispatch,
  );

// $FlowFixMe
export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(Detail)),
);
