import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm, initialize } from 'redux-form';
import { withTranslation } from 'react-i18next';
import Modal from '../../components/elements/Modal';
import Button from '../../components/ui/Button';
import required from '../../validators/required';
import FormField from '../../components/elements/FormField';
import { themePalette } from '../../theme';
import * as jobAPI from '../../api/job';

type Props = {
    modalOpen: boolean;
    closeModal: () => void;
    t: Function,
};

type State = {
    generalError: string | null;
};

class AddTagModal extends React.PureComponent<Props,State> {
    state = {
        generalError: null,
      };

      handleSubmit = async () => {
        const { closeModal, tagName } = this.props;

        if (!tagName) {
          this.setState({ generalError: 'Please enter tag name' });
          return;
        }

        try {
          await jobAPI.createTag(tagName);
          closeModal();
        } catch (e) {
          this.setState({ generalError: e.message || 'Unknown error' });
        }
      };

  render() {
    const {
        modalOpen,
        closeModal,
        t,
        handleSubmit,
      } = this.props;

      const { generalError } = this.state;

    return (
      <Modal
        contentLabel="addTag"
        isOpen={modalOpen}
        onModalCloseRequested={closeModal}
        ariaHideApp={false}
      >
        <div>
          <form onSubmit={handleSubmit}>
            <div className="u-gap-bottom">
                <h1 className="u-text-center">{t('Add Tag')}</h1>
                {generalError && <h3 style={{ color: "red" }}>{generalError}</h3>}

                <Field
                name="tagName"
                type="text"
                component={FormField}
                label={t('Tag Name')}
                validate={[required(t('Tag name is required'))]}
               />
            </div>

            <div className="u-text-center mt-5">
                <Button
                    primary
                    onClick={this.handleSubmit}
                    style={{
                        backgroundColor: themePalette.primary_color,
                        boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                    }}
                >
                Add
                </Button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

const AddTagForm = reduxForm({
    form: 'addTag',
  })(AddTagModal);

const selector = formValueSelector('addTag');

export default connect((state: ReduxState) => ({
    tagName: selector(state, 'tagName'),
    initialize
}))(withTranslation()(AddTagForm));
