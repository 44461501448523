// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';

import AuthService from '../../services/auth';
import { UserGrantMap } from '../../enums/userGrant';
import { loadThunk, type JobsState } from '../../redux/modules/jobs';
import { loadThunk as loadBranchesThunk, type BranchesState, getCompaniesThunk } from '../../redux/modules/branches';
import { isInit, isLoading, isFailed } from '../../utils/apiState';

import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from '../../redux/modules';
import { type AuthenticatedUser } from '../../types/user';

import Header from '../../components/shared/Header';
import List from './List';
import Navbar from './Navbar';
import Loading from './Loading';

type Props = {
  jobs: JobsState,
  branches: BranchesState,
  branchId: string,
  currentUser: AuthenticatedUser,
  loadThunk: (page: number, branchId?: number, orderBy?: string, orderByDir?: string) => void,
  getCompaniesThunk: () => void,
  loadBranchesThunk: () => void,
  push: Function,
  location: string,
};

type State = {
  page: number,
  nextPage: Boolean,
  loadingMore: boolean,
  sortedState: string,
  sortedStateDirection: string,
};

class Jobs extends PureComponent<Props, State> {
  props: Props;

  handleBranchChange: string => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      page: 1,
      nextPage: false,
      loadingMore: false,
      sortedState: '',
      sortedStateDirection: '',
    };

    this.handleBranchChange = this.handleBranchChange.bind(this);
  }

  componentWillMount() {
    const { branchId } = this.props;

    const { page } = this.state;

    this.getJobList(page, branchId);

    if (isInit(this.props.branches) && AuthService.isGranted(UserGrantMap.HR_GRANT)) {
      this.props.loadBranchesThunk();
      this.props.getCompaniesThunk();
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (isFailed(nextProps.jobs)) {
      this.props.push('/error');
    }

    if (isFailed(nextProps.branches)) {
      this.props.push('/error');
    }

    const { page } = this.state;

    if (nextProps.branchId !== this.props.branchId) {
      this.props.loadThunk(page, parseInt(nextProps.branchId, 10));
    }
  }

  handleBranchChange(branchId: string) {
    this.props.push(`/jobs/${branchId}`);
  }

  getJobList = async (page: number, branchId?: number, orderBy?: string, orderByDir?: string) => {
    const pagination = await this.props.loadThunk(
      page,
      branchId ? parseInt(branchId, 10) : undefined,
      orderBy,
      orderByDir,
    );

    this.setState({
      page,
      nextPage: pagination.nextPage,
    });
  };

  handleOnReachEnd = async () => {
    const { branchId } = this.props;

    const { page, nextPage, loadingMore, sortedState, sortedStateDirection } = this.state;

    if (nextPage && !loadingMore) {
      this.setState({ loadingMore: true });
      await this.getJobList(page + 1, branchId, sortedState, sortedStateDirection);
      this.setState({ loadingMore: false });
    }
  };

  getJobListWithSorting = async (orderBy?: number, orderByDir?: number) => {
    const { branchId } = this.props;

    await this.getJobList(1, branchId, orderBy, orderByDir);
  };

  render() {
    const { jobs, branches, currentUser } = this.props;
    const { sortedState, sortedStateDirection } = this.state;

    return (
      <div>
        <Header currentUser={currentUser} />
        <Navbar
          currentUser={currentUser}
          branchId={jobs.branchId ? jobs.branchId.toString() : ''}
          branches={branches}
          onBranchChange={this.handleBranchChange}
        />

        {isLoading(jobs) ? (
          <Loading />
        ) : (
          <List
            jobs={jobs}
            loadingMore={this.state.loadingMore}
            onReachEnd={this.handleOnReachEnd}
            currentUser={currentUser}
            location={this.props.location}
            getJobListWithSorting={this.getJobListWithSorting}
            sortedState={sortedState}
            setSortedState={input => this.setState({ sortedState: input })}
            sortedStateDirection={sortedStateDirection}
            setSortedStateDirection={input => this.setState({ sortedStateDirection: input })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, { match }): Object => ({
  jobs: state.jobs,
  branchId: match.params.branchId,
  branches: state.branches,
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ loadThunk, loadBranchesThunk, getCompaniesThunk, push }, dispatch);

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
