// @flow

import React from 'react';
import { themePalette } from '../../../theme/index'

type Props = {
  activePage: number,
  totalItemsCount: number,
  pageRangeDisplayed: number,
  limit: number,
  onChange: (page: number) => void,
};

const Pagination = ({ activePage, totalItemsCount, pageRangeDisplayed, limit, onChange }: Props) => {
  const totalPages = Math.ceil(totalItemsCount / limit);
  const startPage = Math.max(1, Math.min(activePage - Math.floor(pageRangeDisplayed / 2), totalPages - pageRangeDisplayed + 1));
  const endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onChange(page);
    }
  };

  return (
    <div className="pagination">
      <button disabled={activePage === 1} onClick={() => handlePageChange(1)}>
        «
      </button>
      <button disabled={activePage === 1} onClick={() => handlePageChange(activePage - 1)}>
        ‹
      </button>
      {pages.map((page) => (
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={activePage === page ? 'active' : ''}
        >
          {page}
        </button>
      ))}
      <button disabled={activePage === totalPages} onClick={() => handlePageChange(activePage + 1)}>
        ›
      </button>
      <button disabled={activePage === totalPages} onClick={() => handlePageChange(totalPages)}>
        »
      </button>
      <style jsx>{`
        .pagination {
          display: flex;
          list-style: none;
          padding: 0;
        }
        .pagination button {
          margin: 0 2px;
          padding: 5px 10px;
          border: 1px solid #ddd;
          background-color: #fff;
          cursor: pointer;
          outline: none;
        }
        .pagination button.active {
          background-color: ${themePalette.primary_color};
          color: white;
        }
        .pagination button:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      `}</style>
    </div>
  );
};

export default Pagination;
