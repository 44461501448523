// @flow
import React from 'react';
import { UserGrantMap } from '../../enums/userGrant';
import { UserTypeMap } from '../../enums/userType';
import AuthService from '../../services/auth';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import LinkButton from '../../components/ui/LinkButton';
import Icon from '../../components/elements/Icon';
import WalletIcon from '../../components/elements/Icon/walletIcon';
import Dropdown from '../../components/elements/Dropdown';

import { type BranchesState } from '../../redux/modules/branches';
import { type AuthenticatedUser } from '../../types/user';
import useTranslator from '../../hooks/useTranslator';
import { themePalette } from '../../theme/index';

type Props = {
  currentUser: AuthenticatedUser,
  branchId: string,
  branches: BranchesState,
  onBranchChange: string => void,
};

const Navbar = ({ currentUser, branchId, branches, onBranchChange }: Props) => {
  const { t } = useTranslator();

  const branchChangeGranted = AuthService.isGranted(UserGrantMap.HR_GRANT);
  let dropdownOptions = [];

  const generateDropdownTitle = option => {
    // Support for activeJobCount to be a maximum of 3 digits
    // The length of the word is adjusted as specified
    // This is support 30 characters
    return option.companyName.length > 21
      ? `${option.companyName.substring(0, 21)}... (${option.activeJobCount})`
      : `${option.companyName} (${option.activeJobCount})`;
  };

  if (branchChangeGranted) {
    dropdownOptions = [
      { value: '', title: t('layout.navbar.title') },
      ...branches.companies.map(branch => ({
        value: branch.userId.toString(),
        title: generateDropdownTitle(branch),
        activeJobCount: branch.activeJobCount,
      })),
    ];
  }

  return (
    <div className="c-navbar c-navbar--white">
      <Container>
        <div className="c-navbar__body">
          <Row className="u-flex-grow-full u-flex-align-middle">
            <Col md={6}>
              {branchChangeGranted ? (
                <Dropdown
                  value={branchId}
                  options={dropdownOptions}
                  onChange={onBranchChange}
                  className="c-dropdown__menu-custom-dropdown"
                />
              ) : (
                <h1>{currentUser.user.companyName}</h1>
              )}
            </Col>
            <Col md={6} className="u-hidden@sm-down u-text-right">
              {currentUser.user.userType === UserTypeMap.COMPANY && (
                <LinkButton
                  to="/packages"
                  className="u-gap-right"
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',
                  }}
                >
                  <WalletIcon />
                  <span className="u-gap-left-small">
                    {currentUser.user.jobCreationBalance || 0}
                  </span>
                </LinkButton>
              )}

              <LinkButton
                primar
                to="/jobs/create"
                style={{
                  backgroundColor: themePalette.primary_color,
                  color: '#fff',
                  boxShadow: 'none',
                }}
              >
                <Icon name="add" className="u-gap-right" />
                <span>{t('layout.navbar.addJob')}</span>
              </LinkButton>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
