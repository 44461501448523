// @flow

import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { type Application } from '../../../types/application';
import { age as ageUtil } from '../../../utils/age';
import { themePalette } from '../../../theme/index';
import noApplication from '../../../img/empty-state/no-application.svg';

import Row from '../../../components/layout/Row';
import Col from '../../../components/layout/Col';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/elements/Icon';
import Modal from '../../../components/elements/Modal';

import ApplicationItem from './applicationItem';
import Filter from './Filter';
import Loading from '../Loading';

type Props = {
  applications: Array<Application>,
  onApplicationClick: (Application) => void,
  activeApplicationId: number | null,
  t: Function,
  loadingMore: boolean,
  activeTab: string,
};

type State = {
  filter: Object,
  nameFilter: string,
  isFilterModalOpen: boolean,
};

class ApplicationList extends PureComponent<Props, State> {
  props: Props;

  state: State = {
    filter: {
      gender: [],
      military: [],
      age: [],
      englishLevel: [],
      education: [],
      distance: [],
      experience: [],
      isApplicantCameToAppointment: [],
      isAppointmentPositive: [],
      isOfferSentToApplicant: [],
      isApplicantAcceptTheOffer: [],
    },
    nameFilter: '',
    isFilterModalOpen: false,
  };

  filterApplication = (application: Application): boolean => {
    const { filter, nameFilter } = this.state;
    let passed = true;

    if (nameFilter) {
      const text = nameFilter.toLocaleLowerCase();
      const name = `${application.user.firstName} ${application.user.lastName}`.toLocaleLowerCase();
      passed = passed && name.indexOf(text) !== -1;
    }

    if (filter.gender && filter.gender.length) {
      passed = passed && filter.gender.includes(application.user.gender);
    }

    if (filter.military && filter.military.length) {
      passed = passed && filter.military.includes(application.user.military);
    }

    if (filter.age && filter.age.length) {
      const birthday = application.user.birthDate;
      const age = birthday ? ageUtil(birthday.toDate()) : 0;
      passed = passed && filter.age[0][0] <= age && age <= filter.age[0][1];
    }

    if (filter.englishLevel && filter.englishLevel.length) {
      passed = passed && filter.englishLevel.includes(application.user.english);
    }

    if (filter.education && filter.education.length) {
      passed = passed && filter.education.includes(application.user.education);
    }

    if (filter.distance && filter.distance.length) {
      passed = passed && application.user.distanceInt <= filter.distance[0];
    }

    if (filter.experience && filter.experience.length) {
      passed = passed && application.user.experienceMonth >= filter.experience[0];
    }

    if (filter.isApplicantCameToAppointment && filter.isApplicantCameToAppointment.length) {
      passed =
        passed &&
        filter.isApplicantCameToAppointment.includes(application.isApplicantCameToAppointment);
    }

    if (filter.isAppointmentPositive && filter.isAppointmentPositive.length) {
      passed = passed && filter.isAppointmentPositive.includes(application.isAppointmentPositive);
    }

    if (filter.isOfferSentToApplicant && filter.isOfferSentToApplicant.length) {
      passed = passed && filter.isOfferSentToApplicant.includes(application.isOfferSentToApplicant);
    }

    if (filter.isApplicantAcceptTheOffer && filter.isApplicantAcceptTheOffer.length) {
      passed =
        passed && filter.isApplicantAcceptTheOffer.includes(application.isApplicantAcceptTheOffer);
    }

    return !!passed;
  };

  handleFilterApply = (filter: Object) => {
    this.setState({ filter, isFilterModalOpen: false });
  };

  handleFilterButton = () => {
    this.setState({ isFilterModalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ isFilterModalOpen: false });
  };

  handleNameInputChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ nameFilter: value });
  };

  render() {
    const {
      applications,
      activeApplicationId,
      onApplicationClick,
      t,
      loadingMore,
      activeTab,
    } = this.props;

    if (applications.length === 0) {
      return (
        <div className="u-pad-ends-large u-text-center">
          <img
            src={noApplication}
            width={270}
            height={156}
            alt={t('jobDetail.profile.noApplicationFound')}
          />
          <h3 className="u-color-gull-gray u-gap-bottom-medium">
            {t(activeTab === 'all' ? 'jobs.detail.noApplicationData' : 'jobDetail.profile.noShortlistedWorker')}
          </h3>
        </div>
      );
    }

    const filteredApplications = applications.filter(this.filterApplication);

    return (
      <div>
        <Row noGutter className="u-border-bottom">
          <Col sm={8} className="u-border-right">
            <input
              type="text"
              placeholder={t('jobDetail.searchPlaceholder')}
              className="c-form-control u-pad-ends-small u-pad-sides-small u-border-bottom-none"
              onChange={this.handleNameInputChange}
            />
          </Col>
          <Col sm={4}>
            <Button
              outline
              block
              borderless
              sharp
              className="u-full-height u-font-weight-regular"
              onClick={this.handleFilterButton}
              style={{
                color: themePalette.color,
                boxShadow: 'none',
              }}
            >
              <Icon name="filter" className="u-gap-right" />
              {t('jobDetail.applyFilter')} ({filteredApplications.length}/{applications.length})
            </Button>
          </Col>
        </Row>
        {filteredApplications.map((application) => (
          <ApplicationItem
            key={application.id}
            onClick={() => {
              onApplicationClick(application);
            }}
            isActive={activeApplicationId === application.id}
            application={application}
          />
        ))}

        <Modal
          contentLabel="filter-modal"
          size="large"
          isOpen={this.state.isFilterModalOpen}
          onModalCloseRequested={this.handleModalClose}
          ariaHideApp={false}
        >
          <Filter filter={this.state.filter} onApply={this.handleFilterApply} />
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(ApplicationList);
