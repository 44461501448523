// @flow
import moment from 'moment-timezone';

import { ApplicationMapper } from './application';
import { type Appointment } from '../types/appointment';
import { AppointmentStatusName } from '../enums/appointmentStatus';
import { AppointmentFeedbackName } from '../enums/appointmentFeedback';

const mapFeedbackToAppointment = (appointment: Appointment, feedback: any): Appointment => {
  if (feedback && feedback.feedback) {
    if (feedback.feedback === AppointmentFeedbackName.WORKER_CAME) {
      return {
        ...appointment,
        isApplicantCameToAppointment: true,
        appointmentComment: feedback.text,
      };
    }

    if (feedback.feedback === AppointmentFeedbackName.WORKER_DID_NOT_COME) {
      return {
        ...appointment,
        isApplicantCameToAppointment: false,
        appointmentComment: feedback.text,
      };
    }
  }

  return appointment;
};

// eslint-disable-next-line import/prefer-default-export
export const AppointmentMapper = {
  fromAPIResponse(response: Object): Appointment {
    return {
      id: response.id,
      location: { latitude: response.latitude, longitude: response.longitude },
      appointmentAt: moment(response.appointment_at),
      createdAt: moment(response.created_at),
      updatedAt: moment(response.updated_at),
      application: response.application && ApplicationMapper.fromAPIResponse(response.application),
      user: response.user,
    };
  },
};

export const AppointmentNewMapper = {
  fromAPIResponse: (response: Object): Appointment => {
    let appointmentObject: Appointment = {
      id: response.id,
      applicationId: response.applicationId,
      location: { latitude: response.latitude, longitude: response.longitude },
      appointmentAt: moment(response.startAt),
      createdAt: moment(response.createdAt),
      updatedAt: moment(response.updatedAt),
      user: {
        ...response.user,
        birthDate: moment(response.user.birthDate),
      },
      status: {
        name: response.status.name,
      },
      feedback: response.feedback,
    };

    const statusLogs = response.statusLogs.sort((a, b) => a.id - b.id);

    statusLogs.forEach(statusLog => {
      if (statusLog.status.name === AppointmentStatusName.OFFER_SENT) {
        appointmentObject = {
          ...appointmentObject,
          isOfferSentToApplicant: true,
        };
      }

      if (statusLog.status.name === AppointmentStatusName.OFFER_ACCEPTED) {
        appointmentObject = {
          ...appointmentObject,
          isApplicantAcceptTheOffer: true,
        };
      }

      if (statusLog.status.name === AppointmentStatusName.OFFER_REJECTED) {
        appointmentObject = {
          ...appointmentObject,
          isApplicantAcceptTheOffer: false,
        };
      }

      if (statusLog.status.name === AppointmentStatusName.APPOINTMENT_NEGATIVE) {
        appointmentObject = {
          ...appointmentObject,
          isAppointmentPositive: false,
        };
      }

      if (statusLog.status.name === AppointmentStatusName.APPOINTMENT_POSITIVE) {
        appointmentObject = {
          ...appointmentObject,
          isAppointmentPositive: true,
        };
      }
    });

    const { feedback } = response;

    return mapFeedbackToAppointment(appointmentObject, feedback);
  },
  fromAPIResponseMultiple: (response: Object[]): Appointment[] =>
    response.map(AppointmentNewMapper.fromAPIResponse),
};
