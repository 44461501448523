// @flow

import { detail as detailRequest } from '../../api/application';
import { Status, type StatusType } from '../../utils/apiState';
import { type Application } from '../../types/application';
import { ApplicationMapper } from '../../mappers/application';

import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from './index';

// Actions
const REQUEST = 'applicationDetail/REQUEST';
const SUCCESS = 'applicationDetail/SUCCESS';
const FAILED = 'applicationDetail/FAILED';
const SEEN = 'applicationDetail/SEEN';

// Action Creator Types
type RequestAction = {
  type: typeof REQUEST,
  applicationId: number,
};

type SuccessAction = {
  type: typeof SUCCESS,
  data: Application,
  applicationId: number,
};

type FailedAction = {
  type: typeof FAILED,
  error: any,
  applicationId: number,
};

type SeenAction = {
  type: typeof SEEN,
  applicationId: number,
};

export type ApplicationDetailActions =
  | RequestAction
  | SuccessAction
  | FailedAction;

// Action Creators
export const request = (applicationId: number): RequestAction => ({
  type: REQUEST,
  applicationId,
});

export const success = (data: Application, applicationId: number): SuccessAction => ({
  type: SUCCESS,
  data,
  applicationId,
});

export const failed = (error: any, applicationId: number): FailedAction => ({
  type: FAILED,
  error,
  applicationId,
});

export const seen = (applicationId: number): SeenAction => ({
  type: SEEN,
  applicationId,
  seen,
});

// Thunks
export const loadThunk = (jobId: number, applicationId: number): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  dispatch(request(applicationId));

  try {
    const data = await detailRequest(jobId, applicationId);

    dispatch(success(data, applicationId));
  } catch (error) {
    dispatch(failed(error, applicationId));
  }
};

// Reducer
export type ApplicationDetailItemState = {
  +status: StatusType,
  +data: Application,
  +error: any,
};
export type ApplicationDetailState = { [number]: ApplicationDetailItemState };

const emptyItem = ApplicationMapper.fromAPIResponse({});
const initialState = {};

export default function reducer(
  state: ApplicationDetailState = initialState,
  action: ApplicationDetailActions,
): ApplicationDetailState {
  switch (action.type) {
    case REQUEST: {
      const current = state[action.applicationId];
      const data = current ? current.data : undefined;
      return {
        ...state,
        [action.applicationId]: {
          status: Status.LOADING,
          data: {
            ...emptyItem,
            ...data,
          },
          error: null,
        },
      };
    }
    case SUCCESS: {
      const current = state[action.applicationId];
      const data = current ? current.data : undefined;
      return {
        ...state,
        [action.applicationId]: {
          status: Status.LOADED,
          data: {
            ...action.data,
            appointment: {
              ...data.appointment,
            },
          },
          error: null,
        },
      };
    }
    case FAILED:
      return {
        ...state,
        [action.applicationId]: {
          status: Status.FAILED,
          data: emptyItem,
          error: action.error,
        },
      };
    case SEEN: {
      const application = state[action.applicationId];
      const { data } = application;
      return {
        ...state,
        [action.applicationId]: {
          status: Status.LOADED,
          data: { ...data, isSeen: true },
          error: null,
        },
      };
    }
    default:
      return state;
  }
}

// Selectors
export const getDetailById = (
  state: ReduxState,
  { applicationId }: { applicationId: number },
): Application => {
  const itemState = state.applicationDetail[applicationId];

  if (!itemState) {
    return {
      status: Status.INIT,
      data: emptyItem,
      error: null,
    };
  }

  return itemState;
};
