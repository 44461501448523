import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm, initialize } from 'redux-form';
import { withTranslation } from 'react-i18next';
import Modal from '../../components/elements/Modal';
import Button from '../../components/ui/Button';
import FormFieldMap from '../../components/elements/FormFieldMap';
import GeoSuggestField from '../../components/elements/GeoSuggestField';
import required from '../../validators/required';
import { Language } from '../../types/language';
import FormField from '../../components/elements/FormField';
import { themePalette } from '../../theme';
import * as jobAPI from '../../api/job';

type Props = {
    modalOpen: boolean;
    closeModal: () => void;
    language: Language | null | undefined;
    t: Function,
    latitude: number,
    longitude: number,
    locationPosition: string,
};

type State = {
    generalError: string | null;
};

class AddCompanyLocationModal extends React.PureComponent<Props,State> {
    state = {
        generalError: null,
      };

      handleLocationSelect = (suggest) => {
        const { change } = this.props;
        if (suggest && suggest.location) {
          change('locationPosition', `${suggest.location.lat}, ${suggest.location.lng}`);
          change('centerLatitude', suggest.location.lat);
          change('centerLongitude', suggest.location.lng);
          change('zoom', 12);
          change('latitude', suggest.location.lat);
          change('longitude', suggest.location.lng);
        }
      };

      handleMapClick = (event) => {
        const { change } = this.props;
        change('locationPosition', `${event.latLng.lat()}, ${event.latLng.lng()}`);
        change('latitude', event.latLng.lat());
        change('longitude', event.latLng.lng());
      };

      handleSubmit = async () => {
        const { closeModal, locationName, locationPosition } = this.props;

        if (!locationName || !locationPosition) {
          this.setState({ generalError: 'Please fill all fields' });
          return;
        }

        try {
          await jobAPI.createCompanyLocation({ name: locationName, location: locationPosition });
          closeModal();
        } catch (e) {
          this.setState({ generalError: e.message || 'Unknown error' });
        }
      };

  render() {
    const {
        modalOpen,
        closeModal,
        language,
        t,
        handleSubmit,
        latitude,
        longitude,
      } = this.props;

      const { generalError } = this.state;

    return (
      <Modal
        contentLabel="addCompanyLocation"
        isOpen={modalOpen}
        onModalCloseRequested={closeModal}
        ariaHideApp={false}
      >
        <div style={{ width: "600px" }}>
          <form onSubmit={handleSubmit}>
            <div className="u-gap-bottom">
                <h1 className="u-text-center">{t('Add Company Location')}</h1>
                {generalError && <h3 style={{ color: "red" }}>{generalError}</h3>}

                <Field
                name="locationName"
                type="text"
                component={FormField}
                label={t('Location Name')}
                validate={[required(t('Location name is required'))]}
               />

                <Field
                name="locationSearch"
                label="Location Search"
                component={GeoSuggestField}
                props={{
                    onSuggestSelect: this.handleLocationSelect,
                    onSuggestNoResults: () => {},
                }}
                />
              <Field
                name="locationPosition"
                label="Location Position"
                component={FormFieldMap}
                props={{
                  centerLatitude: latitude,
                  centerLongitude: longitude,
                  latitude,
                  longitude,
                  zoom: 12,
                  onMapClick: this.handleMapClick,
                  language: language ? language.languageCode : "tr-TR",
                }}
                validate={[required(t('Location position is required'))]}
              />
            </div>

            <div className="u-text-center mt-5">
                <Button
                    primary
                    onClick={this.handleSubmit}
                    style={{
                        backgroundColor: themePalette.primary_color,
                        boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                    }}
                >
                Add
                </Button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

const AddCompanyLocationForm = reduxForm({
    form: 'addCompanyLocation',
  })(AddCompanyLocationModal);

const selector = formValueSelector('addCompanyLocation');

export default connect((state: ReduxState) => ({
    locationName: selector(state, 'locationName'),
    locationPosition: selector(state, 'locationPosition'),
    latitude: selector(state, 'latitude'),
    longitude: selector(state, 'longitude'),
    initialize
}))(withTranslation()(AddCompanyLocationForm));
