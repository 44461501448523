// @flow
import React from 'react';
import InputRange from 'react-input-range';
import { withTranslation } from 'react-i18next';
import Button from '../../../components/ui/Button';
import RadioGroup from '../../../components/ui/RadioGroup';
import Radio from '../../../components/ui/Radio';
import CheckBox from '../../../components/ui/Checkbox';
import Select from '../../../components/ui/Select';
import Option from '../../../components/ui/Option';
import { MilitaryStatusList } from '../../../enums/militaryStatus';
import { GenderList, GenderMap } from '../../../enums/gender';
import { EnglishLevelMap } from '../../../enums/englishLevel';
import { EducationMap } from '../../../enums/educationStatus';
import { ExperienceDurationList } from '../../../enums/experienceDuration';
import { themePalette } from '../../../theme/index';
import formInputList from '../../../api/formInputList';
import Loading from '../Loading';
import Alert from '../../../components/elements/Alert';

type Props = {
  onApply: (filter: Object) => void,
  filter: Object,
  t: Function,
};

type State = {
  filter: Object,
};

const MAX_AGE = 99;
const MIN_AGE = 1;
const MAX_DISTANCE = 50;

class Filter extends React.PureComponent<Props, State> {
  props: Props;

  state: State = {
    filter: this.props.filter,
    formViewData: null,
    alertMessage: "",
    showAlert: false,
    alertData: null,
    loading: true
  };

  constructor(props) {
    super(props);
    this.handleAlertClose = this.handleAlertClose.bind(this);
  }

  componentWillMount() {
    formInputList("WorkerProfileForm")
      .then((res) => {
        this.setState({
          formViewData: res
        });
      })
      .catch((e) => {
        this.setState({
          alertMessage: "common.errors.anErrorOccurred",
          alertData: { errorCode: 1014 },
          showAlert: true
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ filter: nextProps.filter });
  }

  handleAlertClose = () => {
    this.setState({
      showAlert: false
    });
  }

  handleMilitaryFilter = (military: number) => (checked: boolean) => {
    const filter = new Set(this.getFilter('military') || []);

    if (checked) {
      filter.add(military);
    } else {
      filter.delete(military);
    }

    this.filter('military', [...filter]);
  };

  handleGenderFilter = (gender: string) => {
    this.filter('gender', [gender]);

    if (gender === GenderList.FEMALE) {
      this.filter('military', []);
    }
  };

  handleEducationFilter = (education: string) => (checked: boolean) => {
    const filter = new Set(this.getFilter('education') || []);

    if (checked) {
      filter.add(education);
    } else {
      filter.delete(education);
    }

    this.filter('education', [...filter]);
  };

  handleEnglishFilter = (englishLevel: number) => (checked: boolean) => {
    const filter = new Set(this.getFilter('englishLevel') || []);

    if (checked) {
      filter.add(englishLevel);
    } else {
      filter.delete(englishLevel);
    }

    this.filter('englishLevel', [...filter]);
  };

  handleAgeFilter = (value: Object) => {
    this.filter('age', [[value.min, value.max]]);
  };

  handleExperienceFilter = (value: string) => {
    this.filter('experience', [+value]);
  };

  handleDistanceFilter = (value: Object) => {
    this.filter('distance', [value]);
  };

  handleFeedbackFilter = (field: string, value: boolean) => (checked: boolean) => {
    const filter = new Set(this.getFilter(field) || []);

    if (checked) {
      filter.add(value);
    } else {
      filter.delete(value);
    }

    this.filter(field, [...filter]);
  };

  handleApply = () => {
    this.props.onApply(this.state.filter);
  };

  handleReset = () => {
    const newFilter = {};
    Object.keys(this.state.filter).forEach((key) => {
      newFilter[key] = [];
    });
    this.setState({ filter: newFilter });
  };

  filter = (field: string, value: Array<any>) => {
    this.setState((state) => {
      const obj = {};
      obj[field] = value;
      return { filter: { ...state.filter, ...obj } };
    });
  };

  getFilter = (field: string): Array<any> => this.state.filter[field] || [];

  render() {
    const { filter } = this.state;
    const { t } = this.props;

    if (this.state.loading) return <Loading />

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div className='row'>
        <div className='col col--lg-6'>
          <div>
            <h6 className='u-clear-gap-top u-color-primary' style={{ color: themePalette.color }}>
              {t('jobDetail.filter.gender')}
            </h6>
            <RadioGroup selectedValue={filter.gender[0]} onChange={this.handleGenderFilter}>
              <Radio value={GenderList.MALE} containerClassName='u-gap-right'>
                {t('constants.genderMap')[GenderList.MALE]}
              </Radio>
              <Radio value={GenderList.FEMALE}>{t('constants.genderMap')[GenderList.FEMALE]}</Radio>
            </RadioGroup>
            <hr className='u-gap-bottom' />
          </div>
          {filter.gender[0] === GenderList.MALE && (this.state.formViewData  && this.state.formViewData.military_status  && this.state.formViewData.military_status.isActive) && (
            <div className='u-gap-bottom-medium'>
              <h6 className='u-clear-gap-top u-color-primary' style={{ color: themePalette.color }}>
                {t('jobDetail.filter.militaryStatus')}
              </h6>
              <CheckBox
                containerClassName='u-gap-right'
                checked={filter.military.includes(+MilitaryStatusList.WAITING)}
                onChange={this.handleMilitaryFilter(+MilitaryStatusList.WAITING)}
              >
                {t('jobDetail.filter.notDone')}
              </CheckBox>
              <CheckBox
                containerClassName='u-gap-right'
                checked={filter.military.includes(+MilitaryStatusList.DELAYED)}
                onChange={this.handleMilitaryFilter(+MilitaryStatusList.DELAYED)}
              >
                {t('jobDetail.filter.delayed')}
              </CheckBox>
              <CheckBox
                containerClassName='u-gap-right'
                checked={filter.military.includes(+MilitaryStatusList.DONE)}
                onChange={this.handleMilitaryFilter(+MilitaryStatusList.DONE)}
              >
                {t('jobDetail.filter.done')}
              </CheckBox>
              <hr className='u-gap-bottom' />
            </div>
          )}

          <div className='u-gap-bottom-medium'>
            <h6 className='u-clear-gap-top u-color-primary' style={{ color: themePalette.color }}>
              {t('jobDetail.filter.age')}
            </h6>
            <div className='u-pad-ends-small'>
              <InputRange
                value={
                  filter.age[0]
                    ? { min: filter.age[0][0], max: filter.age[0][1] }
                    : { min: MIN_AGE, max: MAX_AGE }
                }
                minValue={MIN_AGE}
                maxValue={MAX_AGE}
                onChange={this.handleAgeFilter}
              />
            </div>
            <hr className='u-gap-bottom' />
          </div>

          <div className='u-gap-bottom-medium'>
            <h6 className='u-clear-gap-top u-color-primary' style={{ color: themePalette.color }}>
              {t('jobDetail.filter.experience')}
            </h6>
            <div>
              <Select value={`${filter.experience}`} onChange={this.handleExperienceFilter}>
                {t('constants.experienceDurationList').map((exp) => (
                  <Option value={`${exp.key}`} key={exp.key}>
                    {exp.value}
                  </Option>
                ))}
              </Select>
            </div>
            <hr className='u-gap-bottom' />
          </div>

          <div className='u-gap-bottom-medium'>
            <h6 className='u-clear-gap-top u-color-primary' style={{ color: themePalette.color }}>
              {t('jobDetail.filter.educationStatus')}
            </h6>
            <div>
              {Object.keys(EducationMap).map((key) => (
                <CheckBox
                  key={`education${key}`}
                  containerClassName='u-gap-right'
                  checked={filter.education.includes(key)}
                  onChange={this.handleEducationFilter(key)}
                >
                  {t('constants.educationMap')[key]}
                </CheckBox>
              ))}
            </div>
            <hr className='u-gap-bottom' />
          </div>

          {(this.state.formViewData && this.state.formViewData.english_level && this.state.formViewData.english_level.isActive) && (<div className='u-gap-bottom-medium'>
            <h6 className='u-clear-gap-top u-color-primary' style={{ color: themePalette.color }}>
              {t('jobDetail.filter.englishLevel')}
            </h6>
            <div>
              {Object.keys(EnglishLevelMap).map((key) => (
                <CheckBox
                  key={`englishLevel_${key}`}
                  containerClassName='u-gap-right'
                  checked={filter.englishLevel.includes(+key)}
                  onChange={this.handleEnglishFilter(+key)}
                >
                  {t('constants.englishLevelMap')[key]}
                </CheckBox>
              ))}
            </div>
            <hr className='u-gap-bottom' />
          </div>
          )}

          <div className='u-gap-bottom-medium'>
            <h6 className='u-clear-gap-top u-color-primary' style={{ color: themePalette.color }}>
              {t('jobDetail.filter.distance')}
            </h6>
            <div>
              <InputRange
                value={filter.distance[0] || MAX_DISTANCE}
                formatLabel={(value) => `${value} KM`}
                minValue={1}
                maxValue={MAX_DISTANCE}
                onChange={this.handleDistanceFilter}
              />
            </div>
          </div>
        </div>
        <div className='col col--lg-6'>
          <div className='u-gap-bottom-medium'>
            <h6 className='u-clear-gap-top u-color-primary' style={{ color: themePalette.color }}>
              {t('jobDetail.filter.byApplicantCameToAppointment')}
            </h6>
            <div>
              <CheckBox
                containerClassName='u-gap-right'
                checked={filter.isApplicantCameToAppointment.includes(true)}
                onChange={this.handleFeedbackFilter('isApplicantCameToAppointment', true)}
              >
                {t('jobDetail.filter.came')}
              </CheckBox>
              <CheckBox
                containerClassName='u-gap-right'
                checked={filter.isApplicantCameToAppointment.includes(false)}
                onChange={this.handleFeedbackFilter('isApplicantCameToAppointment', false)}
              >
                {t('jobDetail.filter.didNotCome')}
              </CheckBox>
            </div>
            <hr className='u-gap-bottom' />
          </div>
          <div className='u-gap-bottom-medium'>
            <h6 className='u-clear-gap-top u-color-primary' style={{ color: themePalette.color }}>
              {t('jobDetail.filter.accordingToInterview')}
            </h6>
            <div>
              <CheckBox
                containerClassName='u-gap-right'
                checked={filter.isAppointmentPositive.includes(true)}
                onChange={this.handleFeedbackFilter('isAppointmentPositive', true)}
              >
                {t('jobDetail.filter.positive')}
              </CheckBox>
              <CheckBox
                containerClassName='u-gap-right'
                checked={filter.isAppointmentPositive.includes(false)}
                onChange={this.handleFeedbackFilter('isAppointmentPositive', false)}
              >
                {t('jobDetail.filter.negative')}
              </CheckBox>
            </div>
            <hr className='u-gap-bottom' />
          </div>
          <div className='u-gap-bottom-medium'>
            <h6 className='u-clear-gap-top u-color-primary' style={{ color: themePalette.color }}>
              {t('jobDetail.filter.accordingToBid')}
            </h6>
            <div>
              <CheckBox
                containerClassName='u-gap-right'
                checked={filter.isOfferSentToApplicant.includes(true)}
                onChange={this.handleFeedbackFilter('isOfferSentToApplicant', true)}
              >
                {t('jobDetail.filter.offerSubmitted')}
              </CheckBox>
              <CheckBox
                containerClassName='u-gap-right'
                checked={filter.isOfferSentToApplicant.includes(false)}
                onChange={this.handleFeedbackFilter('isOfferSentToApplicant', false)}
              >
                {t('jobDetail.filter.noOffer')}
              </CheckBox>
            </div>
            <hr className='u-gap-bottom' />
          </div>
          <div className='u-gap-bottom-medium'>
            <h6 className='u-clear-gap-top u-color-primary' style={{ color: themePalette.color }}>
              {t('jobDetail.filter.accordingToAcceptTheOffer')}
            </h6>
            <div>
              <CheckBox
                containerClassName='u-gap-right'
                checked={filter.isApplicantAcceptTheOffer.includes(true)}
                onChange={this.handleFeedbackFilter('isApplicantAcceptTheOffer', true)}
              >
                {t('jobDetail.filter.accepted')}
              </CheckBox>
              <CheckBox
                containerClassName='u-gap-right'
                checked={filter.isApplicantAcceptTheOffer.includes(false)}
                onChange={this.handleFeedbackFilter('isApplicantAcceptTheOffer', false)}
              >
                {t('jobDetail.filter.didNotAccept')}
              </CheckBox>
            </div>
            <hr className='u-gap-bottom' />
          </div>
        </div>
        <hr className='u-hr-line' />
        <div className='col col--lg-12 u-text-right'>
          <Button link onClick={this.handleReset}>
            {t('jobDetail.filter.clearFilter')}
          </Button>
          <Button
            primary
            onClick={this.handleApply}
            style={{
              backgroundColor: themePalette.primary_color,
              boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
            }}
          >
            {t('jobDetail.filter.applyFilter')}
          </Button>
        </div>
        <Alert
          isVisible={this.state.showAlert}
          onConfirmClick={this.handleAlertClose}
          message={t(this.state.alertMessage, this.state.alertData)}
        />
      </div>
    );
  }
}

export default withTranslation()(Filter);
