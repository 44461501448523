/* eslint-disable import/prefer-default-export */
// @flow
import { type Language } from '../types/language';

const LanguageMapper = {
  fromAPIResponse(response: Object): Language {
    if (!response) {
      return {
        id: 0,
        languageName: null,
        languageCode: null,
        isActive: false,
      };
    }

    return {
      id: response.id,
      languageName: response.language_name,
      languageCode: response.language_code,
      isActive: response.is_active,
    };
  },
  fromNewAPIResponse(response: Object): Language {
    if (!response) {
      return {
        id: 0,
        languageName: null,
        languageCode: null,
        isActive: false,
      };
    }

    return {
      id: response.id,
      languageName: response.languageName,
      languageCode: response.languageCode,
      isActive: response.isActive,
    };
  },
};

export { LanguageMapper };
