import { ContactOptionList } from '../../../../enums/contactOption';
import { ExperienceDurationMap } from '../../../../enums/experienceDuration';
import { JobStatusMap } from '../../../../enums/jobStatus';

// @flow
export default {
  constants: {
    defaultSelect: 'Please Select',
    benefitMap: {
      fare: 'Tip',
      free_meal: 'Food Expense',
      health_insurance: 'Private insurance',
    },
    workTimeMap: {
      full_time: 'Full time',
      part_time: 'Part time',
      seasonal: 'Temporary',
    },
    workTimePreference: {
      fullTime: 'Full time',
      partTime: 'Part time',
      seasonal: 'Temporary',
    },
    militaryStatusMap: {
      '1': 'Not Completed',
      '2': 'Delayed',
      '3': 'Completed',
    },
    englishLevelMap: {
      '1': 'None',
      '2': 'Beginner',
      '3': 'Intermediate',
      '4': 'Fluent',
    },
    gender: [
      {
        id: 'all',
        name: 'All',
      },
      {
        id: 'male',
        name: 'Male',
      },
      {
        id: 'female',
        name: 'Female',
      },
    ],
    genderMap: {
      male: 'Male',
      female: 'Female',
    },
    educationMap: {
      elementary_school: 'Primary',
      middle_school: 'Middle School',
      high_school: 'High School',
      university: 'University and above',
    },
    disabledStatusMap: {
      disabled: 'Disabled',
      not_disabled: 'Not Disabled',
    },
    universityStatusMap: {
      graduated: 'Graduate',
      student: 'Continuing',
    },
    jobStatusList: [
      { key: JobStatusMap.ACTIVE, value: 'Active' },
      { key: JobStatusMap.CLOSED, value: 'Inactive' },
    ],
    benefit: [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ],
    contact: [
      {
        id: ContactOptionList.PHONE,
        name: 'Yes, by phone',
      },
      {
        id: ContactOptionList.TEXT,
        name: 'Yes, by message',
      },
      {
        id: ContactOptionList.NONE,
        name: 'No',
      },
    ],
    autoShortlistEnabled: [
      {
        id: true,
        name: 'Active',
      },
      {
        id: false,
        name: 'Inactive',
      },
    ],
    experienceDurationList: [
      { key: ExperienceDurationMap.ALL, value: 'No experience' },
      { key: ExperienceDurationMap.ONE_MONTH, value: '1 month' },
      { key: ExperienceDurationMap.TWO_MONTHS, value: '2 month' },
      { key: ExperienceDurationMap.THREE_MONTHS, value: '3 month' },
      { key: ExperienceDurationMap.FOUR_MONTHS, value: '4 month' },
      { key: ExperienceDurationMap.FIVE_MONTHS, value: '5 month' },
      { key: ExperienceDurationMap.SIX_MONTHS, value: '6 month' },
      { key: ExperienceDurationMap.SEVEN_MONTHS, value: '7 month' },
      { key: ExperienceDurationMap.EIGHT_MONTHS, value: '8 month' },
      { key: ExperienceDurationMap.NINE_MONTHS, value: '9 month' },
      { key: ExperienceDurationMap.TEN_MONTHS, value: '10 month' },
      { key: ExperienceDurationMap.ELEVEN_MONTHS, value: '11 month' },
      { key: ExperienceDurationMap.ONE_YEAR, value: '1 month' },
      { key: ExperienceDurationMap.TWO_YEARS, value: '2 month' },
      { key: ExperienceDurationMap.THREE_YEARS, value: '3 month' },
      { key: ExperienceDurationMap.FOUR_YEARS, value: '4 month' },
      { key: ExperienceDurationMap.FIVE_YEARS, value: '5 month' },
      { key: ExperienceDurationMap.SIX_YEARS, value: '6 month' },
      { key: ExperienceDurationMap.SEVEN_YEARS, value: '7 month' },
      { key: ExperienceDurationMap.OVER_SEVEN_YEARS, value: 'More than 7 years' },
    ],
    updateFeedback: 'Your notification is updated.',
  },
};
